import React from "react";
import event2 from "../../assets/images/event-2.png";
import event1 from "../../assets/images/event-1.png";
// import "../../components/Navbar/Navbar.css"
import Slider from "react-slick";

const Events = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, 
    
    
  };
  return (
    <>
      <section className="testimonials testimonialss">
        <div className="container" data-aos="zoom-in-right">
          <h3>Events </h3>
          {/* <!-- Swiper --> */}
          <div className="swiper mySwipertt">
            <div className="swiper-wrapper">
              <Slider {...settings}>
                <div className="swiper-slide">
                  <div className="testimonials-box d-flex">
                    <div className="testimonials-box-left">
                      <img src={event2} />
                      <div className="tech-box">
                        <h5>CXO BFSI & Fintech Innovations Summit</h5>
                        <h6>October 12, 2023 | 5:30PM - 9:30PM</h6>
                        <a className="btn-dtl" href="#">
                          Details
                        </a>
                        <a
                          className="btn-dtl mx-3"
                          href="https://techplusmedia.com/CXO-BFSI-and-Fintech-Innovations-Summit-and-Awards-2023/index.html"
                        >
                          Attend
                        </a>
                      </div>
                    </div>
                    <div className="testimonials-box-right">
                      <img src={event1} />
                      <div className="tech-box">
                        <h5>CXO Cloud Summit and Awards 2023</h5>
                        <h6>June 23, 2023 | 5:00PM to 9:00PM</h6>
                        <a className="btn-dtl" href="#">
                          Details
                        </a>
                        <a
                          className="btn-dtl mx-3"
                          href="https://techplusmedia.com/CXO-BFSI-and-Fintech-Innovations-Summit-and-Awards-2023/index.html"
                        >
                          Attend
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonials-box d-flex">
                    <div className="testimonials-box-left">
                      <img src={event2} />
                      <div className="tech-box">
                        <h5>CXO BFSI & Fintech Innovations Summit</h5>
                        <h6>October 12, 2023 | 5:30PM - 9:30PM</h6>
                        <a className="btn-dtl" href="#">
                          Details
                        </a>
                        <a
                          className="btn-dtl mx-3"
                          href="https://techplusmedia.com/CXO-BFSI-and-Fintech-Innovations-Summit-and-Awards-2023/index.html"
                        >
                          Attend
                        </a>
                      </div>
                    </div>
                    <div className="testimonials-box-right">
                      <img src={event1} />
                      <div className="tech-box">
                        <h5>CXO Cloud Summit and Awards 2023</h5>
                        <h6>June 23, 2023 | 5:00PM to 9:00PM</h6>
                        <a className="btn-dtl" href="#">
                          Details
                        </a>
                        <a
                          className="btn-dtl mx-3"
                          href="https://techplusmedia.com/CXO-BFSI-and-Fintech-Innovations-Summit-and-Awards-2023/index.html"
                        >
                          Attend
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonials-box d-flex">
                    <div className="testimonials-box-left">
                      <img src={event2} />
                      <div className="tech-box">
                        <h5>CXO BFSI & Fintech Innovations Summit</h5>
                        <h6>October 12, 2023 | 5:30PM - 9:30PM</h6>
                        <a className="btn-dtl" href="#">
                          Details
                        </a>
                        <a
                          className="btn-dtl mx-3"
                          href="https://techplusmedia.com/CXO-BFSI-and-Fintech-Innovations-Summit-and-Awards-2023/index.html"
                        >
                          Attend
                        </a>
                      </div>
                    </div>
                    <div className="testimonials-box-right">
                      <img src={event1} />
                      <div className="tech-box">
                        <h5>CXO Cloud Summit and Awards 2023</h5>
                        <h6>June 23, 2023 | 5:00PM to 9:00PM</h6>
                        <a className="btn-dtl" href="#">
                          Details
                        </a>
                        <a
                          className="btn-dtl mx-3"
                          href="https://techplusmedia.com/CXO-BFSI-and-Fintech-Innovations-Summit-and-Awards-2023/index.html"
                        >
                          Attend
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonials-box d-flex">
                    <div className="testimonials-box-left">
                      <img src={event2} />
                      <div className="tech-box">
                        <h5>CXO BFSI & Fintech Innovations Summit</h5>
                        <h6>October 12, 2023 | 5:30PM - 9:30PM</h6>
                        <a className="btn-dtl" href="#">
                          Details
                        </a>
                        <a
                          className="btn-dtl mx-3"
                          href="https://techplusmedia.com/CXO-BFSI-and-Fintech-Innovations-Summit-and-Awards-2023/index.html"
                        >
                          Attend
                        </a>
                      </div>
                    </div>
                    <div className="testimonials-box-right">
                      <img src={event1} />
                      <div className="tech-box">
                        <h5>CXO Cloud Summit and Awards 2023</h5>
                        <h6>June 23, 2023 | 5:00PM to 9:00PM</h6>
                        <a className="btn-dtl" href="#">
                          Details
                        </a>
                        <a
                          className="btn-dtl mx-3"
                          href="https://techplusmedia.com/CXO-BFSI-and-Fintech-Innovations-Summit-and-Awards-2023/index.html"
                        >
                          Attend
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Events;
