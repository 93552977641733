import http from "./httpService";

const apiEndpoint =  "/awards/";

function authUrl(id) {
  return `${apiEndpoint}${id}`;
}

// Get the Awards Data

export async function getAwards() {
  return http.get(apiEndpoint);
}

// Get the Award Data

export async function getAward(id) {
  return http.put(authUrl(id));
}

//  Create and Update Award

export function saveAwards(award) {
  return http.post(apiEndpoint, award);
}

// Update the status of the Award Data

export async function statusAward(id, status) {
  return http.post(authUrl(id), { status: status });
}

// Delete the Award Data

export async function deleteAward(id) {
  return http.delete(authUrl(id));
}
