import React from "react";
import { Link } from "react-router-dom";
import contentmbanner from "../../assets/images/content-marketing-banner.jpg";
import contentStrategy from "../../assets/images/content-strategy-img.png";
import circleDisc from "../../assets/images/intenden-icon.png";
import webinarImg from "../../assets/images/webinar.png";
import Testimonials from "../../components/Testimonials/Testimonials";
import "./ContentMarketing.css";

const ContentMarketing = ({ funcNav, funcFooter }) => {
  funcNav(true);
  funcFooter(true);
  return (
    <>
      <section className="maint-abt">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 ps-0 pe-0">
              <div className="maint-abt-inner">
                <img src={contentmbanner} className="img-fluid" alt="" />
                <h3>Content Marketing</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="maine-banner abt-st">
        <div className="container">
          <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-5">
            <img src={contentStrategy} className="img-fluid" alt="" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-7">
            <h3>Content Strategy & Insight Market</h3>
            <ul className="contLS pt-2">
                <li><img src={circleDisc} className="cirDisc" alt="" /> Market Research</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Content Planning Creation (Whitepaper, Thought Leadership, Case study, Blogs)</li>
            </ul>
              <div className="maine-banner-inner">
                <p>
                We build Content Strategy based on Marke research, We fuse startup thinking and agile methods to Tech Marketers to increase their customer base, drive operational effectiveness and thrive in an evolving world.
                </p>
                <p>
                Data: We develop content based on a deep understanding of personas, research interests, and media consumption within your target accounts.
                </p>
                <p>
                Expertise: We produce content in a variety of formats covering a wide range of B2B markets. This includes white papers, e-book, infographics, webinars, and videos.
                </p>
                <p>
Resonance: We take a consultative approach to working with you to develop the right content for your audiences and your media programs.
</p>
<p>
Convenience: We know you’re busy. We’re here to save your time, resources, and money.
                </p>
              </div>
           </div>
          </div>
        </div>
      </section>
      <section className="maine-banner bg-gray">
        <div className="container">
          <div className="row pt-5">
          <div className="col-xs-12 col-sm-12 col-md-7">
            <h3>Webinar</h3>
            <p>
                Webinars are a great platforms for every organisation to showcase its expertise in any field.
Average registrant to attendee conversion Rate %
            </p>
            <div class="main-counter d-flex">
                  <div class="counter">
                    <span class="counter-value">68</span>
                    <small>%</small>
                    <h5>Webinars related to communications got conversions</h5>
                  </div>
                  <div class="counter">
                    <span class="counter-value">45</span>
                    <small>%</small>
                    <h5>Training webinars got conversions</h5>
                  </div>
                  <div class="counter">
                    <span class="counter-value">40</span>
                    <small>%</small>
                    <h5>Marketing webinars achieved</h5>
                  </div>
                  <div class="counter">
                    <span class="counter-value">31</span>
                    <small>%</small>
                    <h5>Education webinars got conversion rate</h5>
                  </div>
            </div>
            <p className="pt-3">
                73% of sales leaders and B2B marketers agreed that webinars are a great way to generate successful leads.
            </p>
            <h3>Our Webinar Service</h3>
            <ul className="contLS pt-2">
                <li><img src={circleDisc} className="cirDisc" alt="" /> Provide Attendee Conversion Rate upto 67% on Training Webinar.</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> We Charge on Attendee Conversion not on Registration.</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> We do “Lead Magnet Process" Converting Attendee into Prospect.</li>
            </ul>
              <div className="maine-banner-inner">
                <p>
                Expertise: We produce content in a variety of formats covering a wide range of B2B markets. This includes white papers, e-book, infographics, webinars, and videos.
                </p>
                <p>
Resonance: We take a consultative approach to working with you to develop the right content for your audiences and your media programs.
</p>
<p>
Convenience: We know you’re busy. We’re here to save your time, resources, and money.
                </p>
              </div>
           </div>
           <div className="col-xs-12 col-sm-12 col-md-5">
            <img src={webinarImg} className="img-fluid" alt="" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-3">
          <Link class="btn-lead mr-4" to="/download-case-study">
                  Download Case Study
          </Link>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-3">
          <Link class="btn-lead" to="/watch-now">
                 Watch Now
          </Link>
          </div>
          </div>
        </div>
      </section>
      <Testimonials />
    </>
  );
};

export default ContentMarketing;