import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

const UpdateListing = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [listingData, setListingData] = useState({
    campaign: '',
    companyName: '',
    customerName: '',
    designation: '',
    contactNumber: '',
    email: '',
    city: '',
    state: '',
    leadCommentsStatus: '',
    leadScoring: ''
  })

  const location = useLocation()
  const navigate = useNavigate()
  const listingId = location.pathname.split('/').pop() // Extract listing ID

  useEffect(() => {
    const fetchListingData = async () => {
      try {
        const response = await axios.get(`/listing/getListingById/${listingId}`)
        setListingData(response.data)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError('Listing not found')
        } else {
          setError(error.message)
        }
      } finally {
        setLoading(false)
      }
    }

    fetchListingData()
  }, [listingId])

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      await axios.put(
        `/listing/campaigns/${listingData.campaign}/listings/${listingId}`,
        listingData
      )
      // Navigate back to the previous page
      navigate(-1) // Use navigate with -1 to go back one step
    } catch (error) {
      console.error('Error updating listing:', error)
      // Handle error appropriately
    }
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setListingData({ ...listingData, [name]: value })
  }

  // Check if loading is in progress
  if (loading) {
    return <div>Loading...</div>
  }

  // Check if there's an error
  if (error) {
    return <div>{error}</div>
  }

  return (
    <div className='card'>
      <form onSubmit={handleSubmit} className='forms-sample'>
        <div className='row'>
          <div className='col-12 grid-margin'>
            <h3>Update Listing</h3>
            <div className='row'>
              <div className='col-md-4 mb-3'>
                <label htmlFor='companyName'>Company Name</label>
                <input
                  type='text'
                  id='companyName'
                  name='companyName'
                  value={listingData.companyName}
                  onChange={handleInputChange}
                  placeholder='Company Name'
                  className='form-control'
                />
              </div>
              <div className='col-md-4 mb-3'>
                <label htmlFor='customerName'>Customer Name</label>
                <input
                  type='text'
                  id='customerName'
                  name='customerName'
                  value={listingData.customerName}
                  onChange={handleInputChange}
                  placeholder='Customer Name'
                  className='form-control'
                />
              </div>
              <div className='col-md-4 mb-3'>
                <label htmlFor='designation'>Designation</label>
                <input
                  type='text'
                  id='designation'
                  name='designation'
                  value={listingData.designation}
                  onChange={handleInputChange}
                  placeholder='Designation'
                  className='form-control'
                />
              </div>
              <div className='col-md-6'>
                <label htmlFor='contactNumber'>Contact Number</label>
                <input
                  type='number'
                  id='contactNumber'
                  name='contactNumber'
                  value={listingData.contactNumber}
                  onChange={handleInputChange}
                  placeholder='Contact Number'
                  className='form-control'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='email'>Email</label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  value={listingData.email}
                  onChange={handleInputChange}
                  placeholder='Email'
                  className='form-control'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='city'>City</label>
                <input
                  type='text'
                  id='city'
                  name='city'
                  value={listingData.city}
                  onChange={handleInputChange}
                  placeholder='City'
                  className='form-control'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='state'>State</label>
                <input
                  type='text'
                  id='state'
                  name='state'
                  value={listingData.state}
                  onChange={handleInputChange}
                  placeholder='State'
                  className='form-control'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='leadScoring'>Lead Scoring</label>
                <select
                  id='leadScoring'
                  name='leadScoring'
                  value={listingData.leadScoring}
                  onChange={handleInputChange}
                  className='form-control'
                >
                  <option value='high'>High</option>
                  <option value='medium'>Medium</option>
                  <option value='low'>Low</option>
                </select>
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='leadCommentsStatus'>Lead Comments Status</label>
                <select
                  id='leadCommentsStatus'
                  name='leadCommentsStatus'
                  value={listingData.leadCommentsStatus}
                  onChange={handleInputChange}
                  className='form-control'
                >
                  <option value='pending'>Pending</option>
                  <option value='complete'>Complete</option>
                </select>
              </div>

              <div className='d-flex justify-content-between'>
                <div style={{ flex: '1' }}></div>
                <button
                  type='submit'
                  name='submit'
                  value='continue'
                  style={{
                    backgroundColor: '#55B56D',
                    borderColor: '#007bff',
                    marginBottom: '10px',
                    padding: '10px 24px',
                    borderRadius: '10px',
                    color: '#fff',
                    fontWeight: '600',
                    border: 'none'
                  }}
                >
                  Update Listing
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UpdateListing
