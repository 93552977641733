import React from "react";
import dataAnalystBanner from "../../assets/images/who-are-we--banner.jpg";
import Testimonials from "../../components/Testimonials/Testimonials";

const WhoAreWe = ({ funcNav, funcFooter }) => {
  funcNav(true);
  funcFooter(true);
  return (
    <>
      <section className="maint-abt">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 ps-0 pe-0">
              <div className="maint-abt-inner">
                <img src={dataAnalystBanner} className="img-fluid" alt="" />
                <h3>Who are we</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="maine-banner abt-st">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12 col-sm-12 col-md-12 pb-3">
              <h3 className="text-center">
                {/* Intent Driven Data/Customer Intelligence */}
              </h3>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="maine-banner-inner">
                <p className="lead-paragraph">
                  <span className="first-letter">L</span>eadXchange is an AI-based demand generation engine which supercharges your B2B outreach efforts. With cutting-edge AI technology, data-driven tactics, and a vast database of B2B decision-makers, we help you reach your target audiences with pinpoint accuracy and scale. Our tailored solutions cover everything from content marketing and ABM to intent-driven marketing, brand amplification, event promotion, and a lead flow dashboard. We strategically implement and fine-tune these solutions to ensure top-notch results across the entire sales funnel, delivering maximum ROI.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
    </>
  );
};

export default WhoAreWe;
