import http from "./httpService";

const apiEndpoint =  "/faqs/";

function authUrl(id) {
  return `${apiEndpoint}${id}`;
}

// Get the Faqs Data

export function getFaqs() {
  return http.get(apiEndpoint);
}

// Get the Faq Data

export async function getFaq(id) {
  return http.put(authUrl(id));
}

//  Create and Update Faq

export function saveFaqs(faq) {
  return http.post(apiEndpoint, faq);
}

// Update the status of the Faq Data

export async function statusFaq(id, status) {
  return http.post(authUrl(id), { status: status });
}

// Delete the Faq Data

export function deleteFaq(id) {
  return http.delete(authUrl(id));
}
