import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { RxCountdownTimer } from "react-icons/rx";
import { signUp, sendOtp } from "../../forms/SignupForm/SendOtp.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { useDispatch } from "react-redux";
function VerifyEmail({ funcNav, funcFooter }) {
  const [otp, setOtp] = useState("");
  const signupData = JSON.parse(sessionStorage.getItem("signupData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  funcNav(true);
  funcFooter(true);

  const handleVerifyAndSignup = async (e) => {
    e.preventDefault();
    const { email, password } = signupData;

    await signUp(email, password, otp, navigate, dispatch); // Pass navigate here
  };

  const handleResendOtp = async () => {
    // Call the server to send OTP first
    console.log("Sending OTP...", signupData.email);
    try {
      // Call sendOtp function
      await sendOtp(signupData.email, navigate);
      console.log("OTP Sent Successfully");
    } catch (otpError) {
      console.error("Error sending OTP:", otpError);
      toast.error("Could Not Send OTP");
      return; // Stop execution if OTP sending fails
    }
  };
  return (
    <div
      style={{
        minHeight: "calc(100vh - 3.5rem)",
        display: "grid",
        placeItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          padding: "2rem",
          color: "black",
          borderRadius: "16px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", 
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "600",
            fontSize: "1.5rem",
            lineHeight: "2.25rem",
          }}
        >
          <MdOutlineMarkEmailUnread size={40} color="#0A58CA" />
          <h3>Verify Email</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ margin: "0",color:"#A9A9A9" }}>
              A verification code has been sent to you. Enter the
            </p>
            <p style={{ margin: "0", marginBottom: "10px",color:"#A9A9A9" }}>code below.</p>
          </div>
        </div>

        <form onSubmit={handleVerifyAndSignup}>

          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => (
              <input
                {...props}
                style={{
                 boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", 
                  width: "48px",
                  height: "48px",
                  border: "0",
                  padding: "20px 10px",
                  borderRadius: "0.5rem",
                  color: "black",
                  textAlign: "center",
                  outline: "none", // Removed focus style
                }}
              />
            )}
            
            containerStyle={{
              justifyContent: "space-between",
              gap: "0 10px",
              padding: "20px",
            }}
          />

          <div style={{display:"flex",justifyContent:"end"}}>
          <button
            style={{
              color: "#0A58CA",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              cursor: "pointer",
              fontWeight: "600",
              background: "#fff",
              border: "none",
            
            }}
            onClick={handleResendOtp}
          >
            <RxCountdownTimer />
            Resend it
          </button>
          </div>
          
          <button
            type="submit"
            style={{
              width: "100%",
              backgroundColor: "#55B56D",
              padding: "0.75rem",
              borderRadius: "8px",
              margin: "1rem 0rem",
              fontWeight: "500",
              border: "none",
              color: "#fff",
            }}
          >
            Verify Email
          </button>
        </form>
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
     
       
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h6 style={{ margin: "30px 10px 10px  10px", fontFamily: "sans-serif" ,marginTop:"10px",font:"10px"}}>
          
            Already have an account ?{" "}
            <Link to="/signin" style={{ fontWeight: "600" }}>
              Sign in
            </Link>{" "}
          </h6>

          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link
              style={{ paddingRight: "10px", color: "black",font:"10px" }}
              to="/termscondition"
            >
              Terms & Condition
            </Link>

            <Link to="/privacy" style={{font:"10px"}}>Privacy Policy</Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
