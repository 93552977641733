import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReactPlayer from 'react-player'
import { ImFolderUpload } from 'react-icons/im'
import { FaDownload, FaTrash } from 'react-icons/fa' // Added trash icon
import '../UserCampign/UserCampign.css'
import '../../../UserDashboard/pages/Mylisting/Mylisting.css'
import { IoIosArrowRoundForward } from 'react-icons/io'
import { IoIosArrowRoundBack } from 'react-icons/io'
import total from '../../../../assets/images/total-campaign.png'
import complete from '../../../../assets/images/completed.png'
import pending from '../../../../assets/images/pending.png'
import { FaPlus } from 'react-icons/fa6'
import { MdDelete, MdErrorOutline } from 'react-icons/md'
import Header from './Header'
import { SlPencil } from 'react-icons/sl'
import { Chart, ArcElement } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useSelector } from 'react-redux'

Chart.register(ArcElement)
let headers = [
  'ID',
  'Company Name',
  'Customer Name',
  'Designation',
  'Contact Number',
  'Email',
  'City',
  'Lead Scoring',
  'Status',
  'Upload recording',
  'D/L',
  'Audio',
  'Play call recording audio',
  'Listing Actions'
]
let headers2 = [
  'ID',
  'Company Name',
  'Customer Name',
  'Designation',
  'Contact Number',
  'Email',
  'City',
  'Lead Scoring',
  'Status',
  'D/L',
  'Audio',
  'Play call recording audio'
]
const Listing = () => {
  const location = useLocation()
  const campaignId = location.pathname.split('/').pop()
  const [listings, setListings] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageNumber, setPageNumber] = useState(0)
  const [showPopup, setShowPopup] = useState(false)
  const listingsPerPage = 10
  const pageCount = Math.ceil(listings.length / listingsPerPage)
  const user = useSelector(state => state.userReducer)

  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await axios.get(
          `/listing/getListingsByCampaignId/${campaignId}`
        )
        setListings(response.data.listings)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setListings([]) // Set empty array to trigger the message
        } else {
          setError(error.message)
        }
      } finally {
        setLoading(false)
      }
    }

    fetchListings()
  }, [campaignId])

  const getBackgroundStatusColorClass = status => {
    switch (status) {
      case 'high':
        return { backgroundColor: '#55b56d' }
      case 'medium':
        return { backgroundColor: '#F7A600' }
      case 'low':
        return { backgroundColor: '#ff4d4d' }
      default:
        return {}
    }
  }

  const handleAudioUpload = async (listingId, file) => {
    try {
      const formData = new FormData()
      formData.append('audio', file)
      formData.append('listingId', listingId)
      await axios.post('/api/audio/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      toast.success('Audio uploaded successfully')
      window.location.reload()
    } catch (error) {
      toast.error('Error uploading audio')
      console.error(error)
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  const handleDownload = async filename => {
    try {
      const response = await axios.get(`/api/audio/${filename}/download`, {
        responseType: 'blob'
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error('Error downloading audio:', error)
    }
  }

  // Function to handle deletion of audio
  const handleDelete = async listingId => {
    try {
      await axios.delete(`/api/audio/delete/${listingId}`)
      toast.success('Audio deleted successfully')
      // Refresh listings after deletion
      const response = await axios.get(
        `/listing/getListingsByCampaignId/${campaignId}`
      )
      setListings(response.data.listings)
    } catch (error) {
      toast.error('Error deleting audio')
      console.error(error)
    }
  }

  const handleDeleteListing = async listingId => {
    // console.log("campaignId", campaignId);
    try {
      await axios.delete(`/listing/deleteListing/${listingId}`)
      // Display a success toast notification
      toast.success('Campaign deleted successfully')
      // Close the pop-up after successful deletion
      setShowPopup(false)
      // Reload the page with the current page information
      window.location.reload()
      // window.location.href = `/current-page?currentPage=${currentPage}`;
    } catch (error) {
      console.error('Error deleting campaign:', error)
      // Handle error appropriately
    }
  }

  // Calculate pending, completed, and total listings
  const pendingListings = listings.filter(
    listing => listing.leadCommentsStatus === 'pending'
  )
  const completedListings = listings.filter(
    listing => listing.leadCommentsStatus === 'complete'
  )
  const totalListings = listings.length // Dummy data for the donut chart
  const data = {
    datasets: [
      {
        data: [totalListings, pendingListings.length, completedListings.length],
        backgroundColor: ['#F7A600', '#55B56D', '#0A58CA']
      }
    ]
  }

  const containerStyle = {
    display: 'grid',

    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '16px',
    width: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    padding: '16px',
    paddingTop: '1rem',
    borderRadius: '20px'
  }

  const itemStyle = {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px'
  }

  return (
    <>
      {listings.length === 0 ? (
        <>
          <>
            <Header />
            <h4>No listings found for this campaign</h4>
            <div className='row pt-3'>
              <div className='col-md-12'>
                <div className='source-box'>
                  {user.department === 'Superadmin' && (
                    <div className='source-top d-flex'>
                      <h3>Add Listing</h3>
                      <Link
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '10px',
                          borderRadius: '10px',
                          background: '#0A58CA',
                          color: '#fff'
                        }}
                        to={'new'}
                      >
                        <FaPlus size={20} />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        </>
      ) : (
        <>
          <div className='table-container'>
            <h3>Listings Details</h3>

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: '16px',
                height: '270px'
              }}
            >
              <div style={containerStyle}>
                <div style={itemStyle}>
                  <Doughnut data={data} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px'
                    }}
                  >
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#F7A600',
                        marginRight: '10px',
                        borderRadius: '4px'
                      }}
                    ></div>
                    Total
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px'
                    }}
                  >
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#55B56D',
                        marginRight: '10px',
                        borderRadius: '4px'
                      }}
                    ></div>
                    Pending
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px'
                    }}
                  >
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#0A58CA',
                        marginRight: '10px',
                        borderRadius: '4px'
                      }}
                    ></div>
                    Completed
                  </div>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: '#F7A600',
                  borderRadius: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  padding: '20px',
                  color: 'white'
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <h3> {totalListings === 0 ? '00' : totalListings}</h3>
                  <div>
                    <img src={total} alt='' style={{ width: '40px' }} />
                  </div>
                </div>
                <div>
                  <h6>Total Listing </h6>
                </div>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateRows: '1fr 1fr',
                  gap: '16px'
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    backgroundColor: '#0A58CA',

                    borderRadius: '20px',
                    padding: '20px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      color: 'white'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <h3>
                        {completedListings.length === 0
                          ? '00'
                          : completedListings.length}
                      </h3>
                      <div>
                        <img src={complete} alt='' style={{ width: '40px' }} />
                      </div>
                    </div>
                    <h6>Completed</h6>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: '#55B56D',

                    borderRadius: '20px',
                    padding: '20px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      color: 'white'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <h3>
                        {' '}
                        {pendingListings.length === 0
                          ? '00'
                          : pendingListings.length}
                      </h3>
                      <div>
                        <img src={pending} alt='' style={{ width: '40px' }} />
                      </div>
                    </div>
                    <h6>Pending</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='table-container'
            style={{
              borderRadius: '20px',
              padding: '20px',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
              marginBottom: '40px',
              marginTop: '30px'
            }}
          >
            <h4 style={{ paddingBottom: '10px' }}>Listings</h4>
            <div className='row pt-3'>
              <div className='col-md-12'>
                <div className='source-box'>
                  {user.department === 'Superadmin' && (
                    <div className='source-top d-flex'>
                      <h3>Add Listing</h3>
                      <Link
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '10px',
                          borderRadius: '10px',
                          background: '#0A58CA',
                          color: '#fff'
                        }}
                        to={'new'}
                      >
                        <FaPlus size={20} />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <table
              style={{
                borderCollapse: 'collapse',
                borderRadius: '10px',
                overflow: 'hidden',
                width: '100%'
              }}
            >
              <thead>
                <tr>
                  {(user.department === 'Superadmin' ? headers : headers2).map(
                    header => (
                      <th
                        key={header}
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        {header}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {listings
                  .slice(
                    pageNumber * listingsPerPage,
                    (pageNumber + 1) * listingsPerPage
                  )
                  .map((listing, index) => (
                    <tr key={index}>
                      <td>{pageNumber * listingsPerPage + index + 1}</td>
                      <td>{listing.companyName}</td>
                      <td>{listing.customerName}</td>
                      <td>{listing.designation}</td>
                      <td>{listing.contactNumber}</td>
                      <td>{listing.email}</td>
                      <td>{listing.city}</td>
                      <td>
                        <span
                          style={{
                            ...getBackgroundStatusColorClass(
                              listing.leadScoring
                            ),
                            display: 'flex',
                            color: '#FFFFFF',
                            width: '75px',
                            // background: '#F6F6F6',
                            padding: '8px 8px',
                            borderRadius: '8px',
                            fontSize: '12px',
                            textTransform: 'capitalize',
                            textAlign: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {listing?.leadScoring}
                        </span>
                      </td>
                      <td>{listing.leadCommentsStatus}</td>
                      <td style={{ textAlign: 'center' }}>
                        <label
                          htmlFor={`audio-upload-${listing._id}`}
                          className='upload-btn'
                        >
                          {user.department === 'Superadmin' && (
                            <ImFolderUpload
                              size={20}
                              style={{ color: '#007bff', margin: '20px' }}
                            />
                          )}
                        </label>
                        <input
                          type='file'
                          id={`audio-upload-${listing._id}`}
                          onChange={e =>
                            handleAudioUpload(listing._id, e.target.files[0])
                          }
                          style={{ display: 'none' }}
                          accept='audio/*'
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {listing.audio ? (
                          <button
                            style={{ border: 'none' }}
                            onClick={() =>
                              handleDownload(listing.audio.audio.filename)
                            }
                          >
                            <FaDownload
                              size={15}
                              style={{
                                color: '#28a745',
                                margin: '8px',
                                border: 'none'
                              }}
                            />
                          </button>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              fontSize: '12px'
                            }}
                          >
                            <MdErrorOutline
                              size={20}
                              style={{ color: 'red' }}
                            />
                            Audio
                          </div>
                        )}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {listing.audio ? (
                          <button
                            style={{ border: 'none' }}
                            onClick={() => handleDelete(listing.audio._id)}
                          >
                            <FaTrash
                              size={15}
                              style={{
                                color: 'red',
                                margin: '8px',
                                border: 'none'
                              }}
                            />
                          </button>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              fontSize: '12px'
                            }}
                          >
                            <MdErrorOutline
                              size={20}
                              style={{ color: 'red' }}
                            />
                            Audio
                          </div>
                        )}
                      </td>
                      <td>
                        {listing.audio ? (
                          <li key={listing._id}>
                            <ReactPlayer
                              url={`/api/audio/${listing.audio.audio.filename}.gz`}
                              controls
                              width='11rem'
                              height='50px'
                              config={{
                                file: {
                                  attributes: { controlsList: 'nodownload' }
                                }
                              }}
                            />
                          </li>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              fontSize: '12px'
                            }}
                          >
                            <MdErrorOutline
                              size={20}
                              style={{ color: 'red' }}
                            />
                            Audio
                          </div>
                        )}
                      </td>

                      {user.department === 'Superadmin' && (
                        <td className='button-container'>
                          <div>
                            <Link to={listing._id}>
                              <button
                                style={{ border: 'none', background: '#fff' }}
                              >
                                {' '}
                                <SlPencil
                                  style={{ color: '#0059E3' }}
                                  size={20}
                                />
                              </button>
                            </Link>
                          </div>
                          <div className='button-space'></div>
                          <div>
                            <button
                              style={{ border: 'none', background: '#fff' }}
                              onClick={() => setShowPopup(listing._id)}
                            >
                              <MdDelete
                                style={{ color: '#EE0007', cursor: 'pointer' }}
                                size={25}
                              />
                            </button>
                          </div>
                          {showPopup === listing._id && (
                            <div className='custom-popup'>
                              <h2>Confirm Delete</h2>
                              <p>
                                Are you sure you want to delete this listing?
                              </p>
                              <div className='custom-popup-buttons'>
                                <button
                                  onClick={() => setShowPopup(false)}
                                  className='cancel-btn'
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={() =>
                                    handleDeleteListing(listing._id)
                                  }
                                  className='delete-btn'
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              <div>
                <button
                  onClick={() => changePage({ selected: pageNumber - 1 })}
                  disabled={pageNumber === 0}
                  style={{
                    color: 'black',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    border: 'none',
                    marginRight: '10px'
                  }}
                >
                  <span>
                    <IoIosArrowRoundBack size={25} />
                  </span>
                  Previous
                </button>
                <button
                  onClick={() => changePage({ selected: pageNumber + 1 })}
                  disabled={pageNumber === pageCount - 1}
                  style={{
                    color: 'black',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    border: 'none'
                  }}
                >
                  Country
                  <span>
                    <IoIosArrowRoundForward size={25} />
                  </span>
                </button>
              </div>
              <div>
                <span style={{ color: '#0A58CA' }}>
                  Page {pageNumber + 1} of {pageCount}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Listing
