import http from "./httpService";

const apiEndpoint =  "/cms/";

function authUrl(id) {
  return `${apiEndpoint}${id}`;
}

// Get the Cmss Data

export async function getCmss() {
  return await http.get(apiEndpoint);
}

export async function getCms(id) {
  return http.put(authUrl(id));
}

//  Create and Update Cms

export function saveCmss(cms) {
  return http.post(apiEndpoint, cms);
}

// // Update the status of the Cms Data

// export async function statusCms(id, status) {
//   try {
//     return await http.post(apiUrl + "/change_Cmss_status", {
//       id: id,
//       status: status,
//     });
//   } catch (error) {
//     // Handle error
//     console.error("Error retrieving cms data:", error);
//     // throw error;
//   }
// }

// // Delete the Cms Data

// export async function deleteCms(id) {
//   try {
//     return await http.post(apiUrl + "/delete_Cmss", { id: id });
//   } catch (error) {
//     // Handle error
//     console.error("Error retrieving cms data:", error);
//     // throw error;
//   }
// }
