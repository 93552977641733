import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUser } from "../services/usersService";

export const useUpdateUser = () => {
  const user = useSelector((state) => state.userReducer);

  const handleUserUpdate = async () => {
    try {
      if (!user) {
        // Handle the case when `user` is not available
        throw new Error("User data is missing.");
      }

      const { data: response } = await getUser(user._id);
      sessionStorage.setItem("user", JSON.stringify(response)); // Store updated user data in sessionStorage
    } catch (error) {
      // Handle errors that occur during the user update process
      console.error("Error during user update:", error);

      if (error.response && error.response.status >= 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred during user update.");
      }
    }
  };

  return handleUserUpdate;
};

