import React from "react";
import { Link } from "react-router-dom";
import Table from "../../common/table";
import Delete from "../../common/delete";
import Status from "./../../common/status";
import NoImageAvailable from "../../assets/images/NoImageAvailable.jpg";
import RejectLeed from "./RejectLeed";
const ActiveCXOLeedsTable = ({ items, onDelete, onStatus }) => {
  const columns = [
    {
      path: "img",
      label: "",
      content: (item) => (
        <>
          {item.img === null ? (
            <img
              src={NoImageAvailable}
              style={{ height: "100px", width: "100px" }}
              alt="bukmuk"
            />
          ) : (
            <img
              src={item.img}
              style={{ height: "100px", width: "100px" }}
              alt={item.ttile}
            />
          )}
        </>
      ),
    },
    {
      path: "user_details",
      label: "User Details",
      content: (item) => (
        <>
          <p style={{ whiteSpace: "break-spaces", width: "300px" }}>
            Name : {item.name} <br />
            Email : {item.email} <br />
            Mobile : {item.mobile} <br />
          </p>
        </>
      ),
    },
    {
      path: "status",
      label: "Status",
    },
    {
      path: "action",
      label: "Action",
      content: (item) => (
        <>
          <p style={{ display: "flex", width: "100px" }}>
            <RejectLeed _id={item._id} onStatus={onStatus} />
            <Link
              to="#"
              className="btn btn-sm btn-primary"
              onClick={() => onStatus({ _id: item._id, status: "Accepted" })}
            >
              Accept
            </Link>
          </p>
        </>
      ),
    },
    {
      key: "view",
      label: "View",
      content: (item) => (
        <>
          <Link className="btn-veiw" to={`${item._id}`}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </Link>
          {/* <Delete onClick={() => onDelete(item)} /> */}
        </>
      ),
    },
  ];

  return <Table data={items} columns={columns} />;
};

export default ActiveCXOLeedsTable;
