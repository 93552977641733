import React from 'react'

const TableHeader = props => {
  return (
    <>
      <thead>
        <tr>
          {props.columns.map(column => (
            <th
              className='clickable'
              key={column.path || column.key}
              style={{
                fontSize: '13px',
                padding: '20px 10px',
                background: '#F5F7FB',
                color: '#989898',
                textAlign: 'left'
              }}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
    </>
  )
}

export default TableHeader
