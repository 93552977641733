import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "./../../common/form";
import { Duration } from "../../data/duration";
import { getCms, saveCmss } from "./../../services/cmsService";

const CmsForm = () => {
  const initialData = {
    _id: "",
    handler: "",
    title: "",
    img1: "",
    description: "",
    status: "",
    created_date: "",
    updated_date: "",
    __v: "",
  };
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [base64Image, setBase64Image] = useState("");

  const PopulateData = async (id) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let emptyData = Object.keys(data).reduce((obj, key) => {
          obj[key] = "";
          return obj;
        }, {});
        // Populate User For Admin and Employee
        setData(emptyData);
      } else {
        const response = await getCms(id);
        setData(response.data);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (params._id) {
      PopulateData(params._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params._id]);

  const handlePrev = () => {
    // Programmatically navigate to a different route
    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const newPathname = location.pathname.substring(0, lastSlashIndex + 1);

    let redirectUrl = process.env.REACT_APP_liveURL + newPathname;
    if (process.env.NODE_ENV === "development") {
      redirectUrl = process.env.REACT_APP_localURL + newPathname;
    }
    window.location.href = redirectUrl; // No need for type assertion
  };

  const doSubmit = async () => {
    // Call the server
    // console.log("Submitted", data);
    try {
      const response = await saveCmss(data);
      toast.success(response.data.message);
      handlePrev();
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    _id: Joi.optional().allow(""),
    handler: Joi.optional().allow(""),
    title: Joi.optional().allow(""),
    img1: Joi.optional().allow(""),
    description: Joi.optional().allow(""),
    status: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
    __v: Joi.optional().allow(""),
  };

  const {
    renderInput,
    renderButton,
    renderFile,
    renderInputArea,
    renderSelect,
    handleSubmit,
  } = Form({
    data,
    setData,
    base64Image,
    setBase64Image,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit,
  });

  useEffect(() => {
    const buttonElement = document.querySelector(".forms-sample .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-gradient-primary");
    }
  }, []);

  return (
    <>
      <div className="card">
        <form onSubmit={handleSubmit} className="forms-sample">
          <div className="row ">
            <div className="col-12 grid-margin ">
              <p className="card-description"> Add/Edit Informational Pages</p>

              {/* Form Started */}
              <div className="row">
                <div className="col-md-6">{renderInput("title", "Title")}</div>
                <div className="col-md-6">
                  {renderFile("img1", "Upload Images", "file")}
                </div>
                <div className="col-md-12">
                  {renderInput("description", "Description")}
                            
                </div>
                <div className="row">
                  <div className="col-md-6">{renderButton("Save")}</div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CmsForm;
