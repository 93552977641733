import React from "react";
import Input from "./input";
import { toast } from "react-toastify";

const PageSize = ({ pageSize, setPageSize }) => {
  const handleFieldChange = (value) => {
    if (value === "0") {
      toast.error("Value cannot be zero");
      return;
    }
    setPageSize(parseInt(value, 10));
  };
  return (
    <>
      <Input
        label=""
        name="pageSize"
        type="number"
        onChange={(event) => handleFieldChange(event.target.value)}
        value={pageSize.toString()}
      />
    </>
  );
};

export default PageSize;
