import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
 import Techlogo from "../../assets/images/techplus-media-logo.png";

function Footer() {
  return (
    <>
      <section className="footer-top">
        <div className="container" data-aos="fade-down">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xll-4">
              <div className="footer-top-1">
                <Link to="/">
                  <img src={logo} style={{ width: '320px' }}/>
                </Link>
                <p>Subscribe to keep up with the latest news</p>
                <div className="input-group mb-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email address"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <span className="input-group-text" id="basic-addon2">
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xll-3">
              <div className="footer-top-2">
                <h4>Learn</h4>
                <ul>
                  <li>
                    <Link to="/aboutus">About us</Link>
                  </li>
                  <li>
                    <Link to="/contactus">Contact us</Link>
                  </li>
                  <li>
                    <Link to="https://apac.cxotv.news/">Blog</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xll-2">
              <div className="footer-top-2">
                <h4>Company</h4>
                <ul>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/termscondition">Terms Conditions</Link>
                  </li>
                  <li>
                    <Link to="/leadpolicy">Lead Policy</Link>
                  </li>
                  <li>
                    <Link to="/leadrejection">Lead Rejection Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xll-3 subrcb ">
              <div className="footer-top-2">
                <h4>Follow Us</h4>
                <ul className="d-flex socila-icon">
                <li style={{ paddingTop: '0' }}>
                    <Link to="">
                    
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                      
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </Link>
                  </li>
                </ul>
                
              </div>
              <div className="mt-5">
            <a href="https://www.techplusmedia.com/">
                  <img src={Techlogo} style={{ width: '200px' }}/>
                </a>
            </div>
            </div>
            <div className="copy-box d-flex">
            
              <div className="copy-content invisible">
                <p>@2024 Lead Exchange. All rights reserved.</p>
              </div>
              <div className="copy-content">
                <p style={{ textAlign: "end" }}>
                  @2024 Techplus Media PTE Ltd. All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
