import React from 'react'
// import CC from 'currency-converter-lt'
import {
  Active,
  // Events,
  Faqs,
  Hero,
  Partners,
  Testimonials
} from '../../components'
import ActiveLeads from '../../components/ActiveLeads/ActiveLeads'
import Services from '../../components/Services/Services'
import './Home.css'

// import { getExchangeRate } from '@tamtamchik/exchanger'
// import currencyExchangeRate from 'currency-exchange-rate'

const Home = ({ funcNav, funcFooter }) => {
  funcNav(true)
  funcFooter(true)
  // const freecurrencyapi = new Freecurrencyapi()
  // // 'fca_live_Zvlfl1XUTh1Kb66dCQD0V6T5DJT76YINHNmoRRyQ'
  // freecurrencyapi
  //   .latest({
  //     base_currency: 'USD',
  //     currencies: 'INR'
  //   })
  //   .then(response => {
  //     console.log(response)
  //   })
  // currencyExchangeRate
  //   .getCurrencyExchangeRate({ fromCurrency: 'USD', toCurrency: 'INR' })
  //   .then(function (exchangeRateValue) {
  //     console.log(exchangeRateValue)
  //   })
  //   .catch(error => {
  //     console.log(error)
  //   })

  // //Using a callback function.
  // currencyExchangeRate.getCurrencyExchangeRate(
  //   { fromCurrency: 'USD', toCurrency: 'INR' },
  //   function (exchangeRateValue, error) {
  //     if (error) {
  //       console.error(error)
  //     } else {
  //       console.log(exchangeRateValue)
  //     }
  //   }
  // )
  // // let from = 'USD'
  // let to = 'PKR'
  // let amt = 1
  // const currencyCode = 'USD'
  // console.log('Name:', getCurrencyName(currencyCode))
  // console.log('Symbol:', getCurrencySymbol(currencyCode))
  // console.log('Country:', getCurrencyCountry(currencyCode))
  // console.log('Locale Code:', getCurrencyLocaleCode('en-US'))
  // console.log('Symbol by Locale:', getCurrencySymbolByLocale('en-US'))
  // console.log('Symbol by Country:', getCurrencySymbolByCountry('United States'))
  // console.log('All Codes:', getAllCodes())
  // console.log('All Names:', getAllNames())
  // console.log('All Symbols:', getAllSymbols())
  // console.log('All Locales:', getAllLocales())
  // console.log('Currency Object:', getCurrencyObject(currencyCode))
  return (
    <>
      <Hero />
      <ActiveLeads />
      <Active />
      <Services />
      <Faqs />
      <Partners />
      {/* <Insights /> */}
      {/* <Events /> */}
      <Testimonials />
    </>
  )
}

export default Home
