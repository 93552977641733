import React from "react";
import sami from "../../assets/images/sami-img.png";
import Slider from "react-slick";

const Testimonials = () => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, 
  };

  return (
    <>
      <section className="testimonials">
        <div className="container" data-aos="zoom-in-right">
          <h3>Testimonials</h3>
          <p>
            Sign up for Benchmark today to stay focused on the reason you're
            using email marketing in the first place: bringing your vision to
            life.
          </p>
          {/* <!-- Swiper --> */}
          <div className="swiper mySwipert">
            <div className="swiper-wrapper">
              <Slider {...settings}>
                <div className="swiper-slide">
                  <div className="testimonials-box d-flex">
                    {/* <div className="testimonials-box-left">
                      <h4>“Customers and interested parties engaged.”</h4>
                    </div> */}
                    <div className="testimonials-box-right">
                      <p>
                        LeadXchange performance on CWS (Citrix Workstation
                        Station) was excellent, I Suggest LX should be a part of
                        your Lead Gen Annual Calendar Citrix
                      </p>
                      <div className="king-box">
                        <img src={sami} />
                        <h5>Citrix</h5>
                        {/* <h6>— Gavin Wieske, Marketing Manager</h6> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonials-box d-flex">
                    {/* <div className="testimonials-box-left">
                      <h4>“Customers and interested parties engaged.”</h4>
                    </div> */}
                    <div className="testimonials-box-right">
                      <p>
                      The Open Rate and response generation from itVARnews for developer Community within the given timeline was Very Good
                      </p>
                      <div className="king-box">
                        <img src={sami} />
                        <h5>Head of Marketing</h5>
                        <h6>Google</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonials-box d-flex">
                    {/* <div className="testimonials-box-left">
                      <h4>“Customers and interested parties engaged.”</h4>
                    </div> */}
                    <div className="testimonials-box-right">
                      <p>
                      Techplus has become an important information source, focusing on various aspects of the IT channel community, with extensive coverage on products, technology, channel concerns and vendor policies. 
                      </p>{" "}
                      <div className="king-box">
                        <img src={sami} />
                        <h5>Country Manager</h5>
                        <h6>Seagate</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonials-box d-flex">
                    {/* <div className="testimonials-box-left">
                      <h4>“Customers and interested parties engaged.”</h4>
                    </div> */}
                    <div className="testimonials-box-right">
                      <p>
                      Thanks Techplus for successful execution of “Mobile Edge Computing in the Real-World” webinar for Telecom Enterprise industry.
                      </p>
                      <div className="king-box">
                        <img src={sami} />
                        <h5>Intel</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
