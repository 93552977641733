import React from "react";
import { Link } from "react-router-dom";

const Card = ({ item }) => {
  return (
    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xll-3">
      <div className="lead-box">
        <img src={item.image} className="img-fluid" alt="images/member-1.png" />
        <h5>{item.name}</h5>
        <p>{item.position}</p>
        <ul>
          <li>
            <Link to="#">
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="fa fa-twitter" aria-hidden="true"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="fa fa-linkedin" aria-hidden="true"></i>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Card;
