import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ReactPlayer from 'react-player'
import { useLocation, useNavigate } from 'react-router-dom'
import '../../../AdminDashboard/pages/Campaigns/Campaigns.css'
import { FaDownload } from 'react-icons/fa'
import './Mylisting.css'
import { IoIosArrowRoundForward } from 'react-icons/io'
import { IoIosArrowRoundBack } from 'react-icons/io'
import total from '../../../../assets/images/total-campaign.png'
import complete from '../../../../assets/images/completed.png'
import pending from '../../../../assets/images/pending.png'
import { MdErrorOutline } from 'react-icons/md'
import { Chart, ArcElement } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

Chart.register(ArcElement)
// const getStatusColorClass = status => {
//   switch (status) {
//     case 'high':
//       return { color: '#55b56d' }
//     case 'medium':
//       return { color: '#0A58CA' }
//     case 'low':
//       return { color: '#F7A600' }
//     default:
//       return {}
//   }
// }
const getBackgroundStatusColorClass = status => {
  switch (status) {
    case 'high':
      return { backgroundColor: '#55b56d' }
    case 'medium':
      return { backgroundColor: '#F7A600' }
    case 'low':
      return { backgroundColor: '#ff4d4d' }
    default:
      return {}
  }
}
const MyListing = () => {
  const user = useSelector(state => state.userReducer)
  const location = useLocation()
  const campaignId = location.pathname.split('/').pop()
  const navigate = useNavigate()

  const [listings, setListings] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageNumber, setPageNumber] = useState(0)

  const listingsPerPage = 10
  const pageCount = Math.ceil(listings.length / listingsPerPage)

  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await axios.get(
          `/listing/getListingsByCampaignId/${campaignId}`
        )
        setListings(response.data.listings)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setListings([]) // Set empty array to trigger the message
        } else {
          setError(error.message)
        }
      } finally {
        setLoading(false)
      }
    }

    fetchListings()
  }, [campaignId])

  const handleDownload = async filename => {
    try {
      const response = await axios.get(`/api/audio/${filename}/download`, {
        responseType: 'blob'
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error('Error downloading audio:', error)
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  // Calculate pending, completed, and total listings
  const pendingListings = listings.filter(
    listing => listing.leadCommentsStatus === 'pending'
  )
  const completedListings = listings.filter(
    listing => listing.leadCommentsStatus === 'complete'
  )
  const totalListings = listings.length

  const options = {
    maintainAspectRatio: false
  }

  // Dummy data for the donut chart
  const data = {
    datasets: [
      {
        data: [totalListings, pendingListings.length, completedListings.length],
        backgroundColor: ['#F7A600', '#55B56D', '#0A58CA']
      }
    ]
  }

  const containerStyle = {
    display: 'grid',

    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '16px',
    width: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    padding: '16px',
    paddingTop: '1rem',
    borderRadius: '20px'
  }

  const itemStyle = {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px'
  }

  return (
    <>
      <h3>Listings Details</h3>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '16px',
          height: '270px'
        }}
      >
        <div style={containerStyle}>
          <div style={itemStyle}>
            <Doughnut data={data} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px'
              }}
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: '#F7A600',
                  marginRight: '10px',
                  borderRadius: '4px'
                }}
              ></div>
              Total
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px'
              }}
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: '#55B56D',
                  marginRight: '10px',
                  borderRadius: '4px'
                }}
              ></div>
              Pending
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px'
              }}
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: '#0A58CA',
                  marginRight: '10px',
                  borderRadius: '4px'
                }}
              ></div>
              Completed
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: '#F7A600',
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '20px',
            color: 'white'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3> {totalListings === 0 ? '00' : totalListings}</h3>
            <div>
              <img src={total} alt='' style={{ width: '40px' }} />
            </div>
          </div>
          <div>
            <h6>Total Listing </h6>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
            gap: '16px'
          }}
        >
          <div
            style={{
              alignItems: 'center',
              backgroundColor: '#0A58CA',

              borderRadius: '20px',
              padding: '20px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                color: 'white'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>
                  {completedListings.length === 0
                    ? '00'
                    : completedListings.length}
                </h3>
                <div>
                  <img src={complete} alt='' style={{ width: '40px' }} />
                </div>
              </div>
              <h6>Completed</h6>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#55B56D',

              borderRadius: '20px',
              padding: '20px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                color: 'white'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>
                  {' '}
                  {pendingListings.length === 0 ? '00' : pendingListings.length}
                </h3>
                <div>
                  <img src={pending} alt='' style={{ width: '40px' }} />
                </div>
              </div>
              <h6>Pending</h6>
            </div>
          </div>
        </div>
      </div>

      {listings.length === 0 ? (
        <p>No listings found for this campaign</p>
      ) : (
        <>
          <div
            className='table-container'
            style={{
              borderRadius: '20px',
              padding: '20px',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
              marginBottom: '40px',
              marginTop: '30px'
            }}
          >
            <h4 style={{ paddingBottom: '10px' }}>Listings</h4>
            <table
              style={{
                borderCollapse: 'collapse',
                borderRadius: '10px',
                overflow: 'hidden',
                width: '100%'
              }}
            >
              <thead>
                <tr>
                  {[
                    'ID',
                    'Company Name',
                    'Customer Name',
                    'Designation',
                    'Contact Number',
                    'Email',
                    'City',
                    'Lead Scoring',
                    'Status',
                    'Audio',
                    'D/L'
                  ].map(header => (
                    <th
                      key={header}
                      style={{
                        fontSize: '13px',
                        padding: '20px 10px',
                        background: '#F5F7FB',
                        color: '#989898'
                      }}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {listings
                  .slice(
                    pageNumber * listingsPerPage,
                    (pageNumber + 1) * listingsPerPage
                  )
                  .map((listing, index) => (
                    <tr>
                      <td>{pageNumber * listingsPerPage + index + 1}</td>
                      <td>{listing.companyName}</td>
                      <td>{listing.customerName}</td>
                      <td>{listing.designation}</td>
                      <td>{listing.contactNumber}</td>
                      <td>{listing.email}</td>
                      <td>{listing.city}</td>
                      <td
                      // style={getStatusColorClass(listing.leadScoring)}
                      >
                        <span
                          style={{
                            ...getBackgroundStatusColorClass(
                              listing.leadScoring
                            ),
                            display: 'flex',
                            color: '#FFFFFF',
                            width: '75px',
                            // background: '#F6F6F6',
                            padding: '8px 8px',
                            borderRadius: '8px',
                            fontSize: '12px',
                            textTransform: 'capitalize',
                            textAlign: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {listing?.leadScoring}
                        </span>
                      </td>
                      <td>{listing.leadCommentsStatus}</td>
                      <td>
                        {listing.audio ? (
                          <li key={listing._id}>
                            <ReactPlayer
                              url={`/api/audio/${listing.audio.audio.filename}.gz`}
                              controls
                              width='11rem'
                              height='50px'
                              config={{
                                file: {
                                  attributes: { controlsList: 'nodownload' }
                                }
                              }}
                            />
                          </li>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              fontSize: '12px'
                            }}
                          >
                            <MdErrorOutline
                              size={20}
                              style={{ color: 'red' }}
                            />
                            Audio
                          </div>
                        )}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {listing.audio ? (
                          <button
                            style={{ border: 'none' }}
                            onClick={() =>
                              handleDownload(listing.audio.audio.filename)
                            }
                          >
                            <FaDownload
                              size={20}
                              style={{
                                color: '#28a745',
                                margin: '8px',
                                border: 'none'
                              }}
                            />
                          </button>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              fontSize: '12px'
                            }}
                          >
                            <MdErrorOutline
                              size={20}
                              style={{ color: 'red' }}
                            />
                            Audio
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              <div>
                <button
                  onClick={() => changePage({ selected: pageNumber - 1 })}
                  disabled={pageNumber === 0}
                  style={{
                    color: 'black',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    border: 'none',
                    marginRight: '10px'
                  }}
                >
                  <span>
                    <IoIosArrowRoundBack size={25} />
                  </span>
                  Previous
                </button>
                <button
                  onClick={() => changePage({ selected: pageNumber + 1 })}
                  disabled={pageNumber === pageCount - 1}
                  style={{
                    color: 'black',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    border: 'none'
                  }}
                >
                  Next
                  <span>
                    <IoIosArrowRoundForward size={25} />
                  </span>
                </button>
              </div>
              <div>
                <span style={{ color: '#0A58CA' }}>
                  Page {pageNumber + 1} of {pageCount}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default MyListing
