import http from "./httpService";

const apiEndpoint = "/userroles/";

function userRoleUrl(id) {
    return `${apiEndpoint}${id}`;
}

// Get all user roles
export async function getUserRoles() {
    return await http.get(apiEndpoint);
}

// Get a specific user role by ID
export async function getUserRole(id) {
    return http.get(userRoleUrl(id)); // Use http.get instead of http.put
}

// Create or update a user role
export function saveUserRole(userRole) {
    if (userRole._id) {
        // If the user role has an ID, update the existing record
        return http.put(userRoleUrl(userRole._id), userRole);
    }

    // Otherwise, create a new user role
    return http.post(apiEndpoint, userRole);
}

// Update the status of a user role
export async function statusUserRole(id, status) {
    return http.post(userRoleUrl(id), { status });
}

// Delete a user role
export async function deleteUserRole(id) {
    return http.delete(userRoleUrl(id));
}
