import React from 'react'
import { Link } from 'react-router-dom';
import arrowwhite from "../assets/images/arrow-white.png";

const ServicesButton = ({  text }) => {
  return (
    <div className="campaignLink">
    <Link
    to={"user_dashboard/myCampaigns"}
      className="nav-link"
      style={{
        backgroundColor: "green",
        padding: "12px",
        marginTop:"50px",
        width: "70%",
        borderRadius: "10px",
        fontWeight: 'bold',
      }}
    >
      {text}
      <img src={arrowwhite} alt="Arrow" className="arrWImg" />
    </Link>
  </div>
  )
}

export default ServicesButton
