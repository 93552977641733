import React from "react";
import Header from "./Header";
import { TestimonialList } from "../../../../tables";

const Testimonial = () => {
  return (
    <>
      <Header />
      <TestimonialList />
    </>
  );
};

export default Testimonial;
