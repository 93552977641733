import { Link } from "react-router-dom";
import salesEnable from "../../assets/images/sales-enablement-banner.jpg";
import salesTearget from "../../assets/images/sales-target.png";
import intentBasedL from "../../assets/images/intent-based-lead-img.jpg";
import activatedDormantC from "../../assets/images/activate-dormant-customer-img.jpg";
import Testimonials from "../../components/Testimonials/Testimonials";
import "./SalesEnablement.css";

const SalesEnablement = ({ funcNav, funcFooter }) => {
  funcNav(true);
  funcFooter(true);
  return (
    <>
      <section className="maint-abt">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 ps-0 pe-0">
              <div className="maint-abt-inner">
                <img src={salesEnable} className="img-fluid" alt="" />
                <h3>Sales Enablement</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="maine-banner abt-st">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 pt-3">
            <div class="main-counter d-flex">
                  <div class="counter ctmABMWth">
                    <span class="counter-value">55</span>
                    <small>%</small>
                    <h5>C-Suite executives say that sales enablement solutions are the top technology investment necessary to boost sales productivity.</h5>
                  </div>
                  <div class="counter ctmABMWth">
                    <span class="counter-value">49</span>
                    <small>%</small>
                    <h5>Win rate is achieved by organizations with sales enablement on forecasted deals</h5>
                  </div>
                  <div class="counter ctmABMWth">
                    <span class="counter-value">76</span>
                    <small>%</small>
                    <h5>Organizations see an increase in sales between 6% to 20%</h5>
                  </div>
            </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-5">
             <img src={salesTearget} alt="" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-7">
              <div className="maine-banner-inner">
                <h3>Prospecting</h3>
                <p>
                If you work in sales, then you know that prospecting isn’t easy. In fact, almost half of sales reps admit that they find prospecting the most difficult part of the sales process.In order to keep a sales pipeline full of leads, you have to prospect. When done correctly, prospecting is the fastest way to increase both your conversion rates and closing rates.
                </p>
                <p>
                Prospecting kick-starts the entire sale and determines, to a great extent, whether or not the deal is going to be sealed. The math is simple: the more prospects you generate, the more chances to close a sale you have, as every prospect equals an opportunity.
                </p>
                <p>
                Techplus “LeadXchange” arm Marketing & Sale team with insightful account intelligence, revealing your best opportunities and tips on how to target them. We can help you discover specific technologies employed, competitor installations, budgeting, and additional complex risk and objections. Uncover hidden sales opportunities.
                </p>
                <p>
                Lead Xchange Intend Engine automatically search and prioritizes the accounts and prospects that are actually perfect match for your solutions. The Engine list them for you in order of their recent Purchase behaviour
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="maine-banner bg-gray">
        <div className="container">
          <div className="row pt-5">
          <div className="col-xs-12 col-sm-12 col-md-7">
            <h3>Intent Based Lead</h3>
            <p>
                With the ever-rising expectations across the buyer’s journey, understanding your audience is critical. It’s also key to serving them the right content at the right time. The intent-based approach involves marketing a product or a service based on the prospect’s intent to make a purchase decision, demonstrated by their actions and behaviour. By analyzing the client’s past behaviour, you can find out what motivates their decisions. This way, you can determine which intent signals belong to quality leads.
            </p>
            <p className="pt-3">
                73% of sales leaders and B2B marketers agreed that webinars are a great way to generate successful leads.
            </p>
            <p className="pt-3">
            Let’s say your existing customers tend to engage with your educational content early on in the journey. If so, consider creating nurturing campaigns for prospects that download your eBooks and white papers.
            </p>
            <p className="pt-3">
            LX(LeadXchange) can help you streamline your marketing efforts so you can focus your resources on the right accounts. We can help you create demand and fill your sales funnel with quality leads.
            </p>
            <p className="pt-3">
            Call LX (Lead Xchange) today and let us can create the perfect marketing mix for your business.
            </p>
            <div className="col-xs-12 col-sm-12 col-md-6">
                <Link class="btn-lead mr-4" to="/download-case-study">
                        Download Case Study
                </Link>
            </div>
           </div>
           <div className="col-xs-12 col-sm-12 col-md-5">
           <img src={intentBasedL} alt="" />
          </div>
          </div>
        </div>
      </section>
      <section className="maine-banner">
        <div className="container">
          <div className="row pt-5">
            <div className="col-xs-12 col-sm-12 col-md-5">
            <img src={activatedDormantC} alt="" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-7">
              
                <h3>Active Dormant Customers</h3>
                <p>
                While it is always disappointing when a customer becomes dormant, it is crucial that you not give up on such customers-no matter how much time has passed. If you are not spending at least some time and money on reactivating these customers, you are likely missing out on a potential income stream. However, even the best-laid retention strategies aren’t 100% fool-proof.
                </p>
                <p>
                Sometimes customers slip away and stop responding to prompts from you. When this happens, it’s important to be proactive in your attempt to win them back. Since their dormancy could be for any number of reasons, it’s essential to figure out why they left and what they need from your brand.
                </p>
                <p>
                LeadXchange (LX) helps you to identify the needs of Inactive customer, We start targeted marketing campaigns to turn each of those dormant customers active again
                </p>
                <div className="col-xs-12 col-sm-12 col-md-6">
                <Link class="btn-lead mr-4" to="/download-case-study">
                        Download Case Study Lenovo
                </Link>
            </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
    </>
  );
};

export default SalesEnablement;