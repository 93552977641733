import React, { useEffect } from "react";
import auth from "../services/authServices";
import { toast } from "react-toastify";

function Logout() {
  useEffect(() => {
    const doLogout = async () => {
      try {
        await auth.logout();
        let redirectUrl = process.env.REACT_APP_localURL;
        if (process.env.NODE_ENV === "development") {
          redirectUrl = process.env.REACT_APP_liveURL;
        }
        window.location.href = redirectUrl; // No need for type assertion
      } catch (ex) {
        if (ex.response && ex.response.status >= 400) {
          toast.error(ex.response.data.message);
        }
      }
    };

    doLogout();
  }, []);

  return null;
}

export default Logout;
