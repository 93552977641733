import React from "react";
import { Link } from "react-router-dom";
import Card from "./Card";
import MemberData from "./MemberData";

const Members = () => {
  return (
    <>
      <section className="lead-market">
        <div className="container">
          <h3>Connect with our Member </h3>
          <div className="row" data-aos="zoom-in">
            {MemberData.map((item, index) => (
              <>
                <Card key={item} item={item} />
              </>
            ))}
            <Link to="#" className="bgn-now">
              View More Members
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Members;
