import http from "./httpService";

const apiEndpoint =  "/testimonials/";

function authUrl(id) {
  return `${apiEndpoint}${id}`;
}

// Get the Testimonials Data

export async function getTestimonials() {
  return await http.get(apiEndpoint);
}

export async function getTestimonial(id) {
  return http.put(authUrl(id));
}

//  Create and Update Testimoni

export function saveTestimonials(testimonial) {
  return http.post(apiEndpoint, testimonial);
}

// Update the status of the Testimonial Data

export async function statusTestimonial(id, status) {
  return http.post(authUrl(id), { status: status });
}

// Delete the Testimonial Data

export async function deleteTestimonial(id) {
  return http.delete(authUrl(id));
}
