// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-gray{background: #f7f7f7;}
ul.contLS{
    margin: 0px; padding: 0px 0px 0px 10px;
}
ul.contLS li{line-height: 30px;}
.cirDisc{
    width: 20px;
    margin-right: 10px;;
}`, "",{"version":3,"sources":["webpack://./src/pages/ContentMarketing/ContentMarketing.css"],"names":[],"mappings":"AAAA,SAAS,mBAAmB,CAAC;AAC7B;IACI,WAAW,EAAE,yBAAyB;AAC1C;AACA,aAAa,iBAAiB,CAAC;AAC/B;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".bg-gray{background: #f7f7f7;}\r\nul.contLS{\r\n    margin: 0px; padding: 0px 0px 0px 10px;\r\n}\r\nul.contLS li{line-height: 30px;}\r\n.cirDisc{\r\n    width: 20px;\r\n    margin-right: 10px;;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
