import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "./../../common/form";
import { useSelector } from "react-redux";
import { getMIntent, saveMIntents } from "../../services/mintentService";

const MIntentForm = () => {
  const user = useSelector((state) => state.userReducer);
  const initialData = {
    _id: "",
    user_id: user._id,
    name: "",
    email: "",
    mobile: "",
    company_name: "",
    designation: "",
    address: "",
    city: "",
    state: "",
    country: "",
    post_code: "",
    comp_size: "",
    revenue: "",
    industry: "",
    sub_industry: "",
    specialization: "",
    inception: "",
    website: "",
    vendor: "",
    interest: "",
    topic: "",
    business_size: "",
    respective_files: "",
    source: "",
    linkedin: "",
    twitter: "",
    facebook: "",
    recognition: "",
    recognition_date: "",
    status: "",
    created_date: "",
    updated_date: "",
    __v: "",
  };
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [base64Image, setBase64Image] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const PopulateData = async (id) => {
    try {
      const dataId = id;
      if (dataId !== "new") {
        const response = await getMIntent(id);
        setData(response.data);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (params._id) {
      PopulateData(params._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params._id]);

  const handlePrev = () => {
    // Programmatically navigate to a different route
    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const newPathname = location.pathname.substring(0, lastSlashIndex + 1);

    let redirectUrl = process.env.REACT_APP_liveURL + newPathname;
    if (process.env.NODE_ENV === "development") {
      redirectUrl = process.env.REACT_APP_localURL + newPathname;
    }
    window.location.href = redirectUrl; // No need for type assertion
  };

  const doSubmit = async () => {
    // Call the server
    console.log("Submitted", data);
    try {
      const response = await saveMIntents(data);
      toast.success(response.data.message);
      handlePrev();
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    _id: Joi.optional().allow(""),
    user_id: Joi.optional().allow(""),
    name: Joi.optional().allow(""),
    email: Joi.optional().allow(""),
    mobile: Joi.optional().allow(""),
    company_name: Joi.optional().allow(""),
    designation: Joi.optional().allow(""),
    address: Joi.optional().allow(""),
    city: Joi.optional().allow(""),
    state: Joi.optional().allow(""),
    country: Joi.optional().allow(""),
    post_code: Joi.optional().allow(""),
    comp_size: Joi.optional().allow(""),
    revenue: Joi.optional().allow(""),
    industry: Joi.optional().allow(""),
    sub_industry: Joi.optional().allow(""),
    specialization: Joi.optional().allow(""),
    inception: Joi.optional().allow(""),
    website: Joi.optional().allow(""),
    vendor: Joi.optional().allow(""),
    interest: Joi.optional().allow(""),
    topic: Joi.optional().allow(""),
    business_size: Joi.optional().allow(""),
    respective_files: Joi.optional().allow(""),
    source: Joi.optional().allow(""),
    linkedin: Joi.optional().allow(""),
    twitter: Joi.optional().allow(""),
    facebook: Joi.optional().allow(""),
    recognition: Joi.optional().allow(""),
    recognition_date: Joi.optional().allow(""),
    status: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
    __v: Joi.optional().allow(""),
  };

  const {
    renderInputArea,
    renderFile,
    renderButton,
    renderInput,
    handleSubmit,
  } = Form({
    data,
    setData,
    errors,
    base64Image,
    setBase64Image,
    setErrors,
    schema,
    onSubmit: doSubmit,
  });

  useEffect(() => {
    const buttonElement = document.querySelector(".forms-sample .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-gradient-primary");
    }
  }, []);

  return (
    <>
      <div className="card">
        <form onSubmit={handleSubmit} className="forms-sample">
          <div className="row ">
            <div className="col-12 grid-margin ">
              <p className="card-description"> Add/Edit Intent Data</p>

              {/* Form Started */}
              <div className="row">
                <div className="col-md-4">
                  {renderInput("name", "Full Name")}
                </div>
                <div className="col-md-4">
                  {renderInput("email", "Email", "email")}
                </div>
                <div className="col-md-4">
                  {renderInput("mobile", "Mobile", "tel")}
                </div>
                <div className="col-md-6">
                  {renderInput("company_name", "Company Name")}
                </div>
                <div className="col-md-6">
                  {renderInput("designation", "Designation")}
                </div>
                <div className="col-md-12">
                  {renderInputArea("address", "Address")}
                </div>
                <div className="col-md-3">{renderInput("city", "City")}</div>
                <div className="col-md-3">{renderInput("state", "State")}</div>
                <div className="col-md-3">
                  {renderInput("country", "Country")}
                </div>
                <div className="col-md-3">
                  {renderInput("post_code", "Post Code")}
                </div>
                <div className="col-md-3">
                  {renderInput("comp_size", "Company Size", "number")}
                </div>
                <div className="col-md-3">
                  {renderInput("revenue", "Revenue")}
                </div>
                <div className="col-md-3">
                  {renderInput("industry", "Industry")}
                </div>
                <div className="col-md-3">
                  {renderInput("sub_industry", "Ssub Industry")}
                </div>
                <div className="col-md-12">
                  {renderInputArea("specialization", "Specialization")}
                </div>
                <div className="col-md-6">
                  {renderInput("inception", "Inception")}
                </div>
                <div className="col-md-6">
                  {renderInput("website", "Website")}
                </div>
                <div className="col-md-6">
                  {renderInput("vendor", "Vendor")}
                </div>
                <div className="col-md-6">
                  {renderInput("interest", "Interest")}
                </div>
                <div className="col-md-12">{renderInput("topic", "Topic")}</div>
                <div className="col-md-6">
                  {renderInput("business_size", "Business Size")}
                </div>
                <div className="col-md-6">
                  {renderFile("respective_files", "Respective File", "file")}
                </div>
                <div className="col-md-12">
                  {renderInput("source", "Source")}
                </div>
                <div className="col-md-4">
                  {renderInput("linkedin", "Linked In")}
                </div>
                <div className="col-md-4">
                  {renderInput("twitter", "Twitter")}
                </div>
                <div className="col-md-4">
                  {renderInput("facebook", "Facebook")}
                </div>
                <div className="col-md-6">
                  {renderInput("recognition", "Recognition")}
                </div>
                <div className="col-md-6">
                  {renderInput("recognition_date", "Recognition Date", "date")}
                </div>

                <div className="row">
                  <div className="col-md-6">{renderButton("Save")}</div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default MIntentForm;
