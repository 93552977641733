import React from 'react'
import './ContactUs.css'
const ContactUs = ({ funcNav, funcFooter }) => {
  funcNav(true)
  funcFooter(true)
  return (
    <div>
      <section className='maint-abt'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12 ps-0 pe-0'>
              <div className='maint-abt-inner'>
                <img
                  src='https://www.leadxchange.ai/images/contactus-banner.jpg'
                  className='img-fluid'
                  alt=''
                />
                <h3>Contact Us</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='quick'>
        <div class='container'>
          <div class='row'>
            <div class='col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xll-8'>
              <div class='quick-inner '>
                <form
                  class='d-flex quick-form'
                  action=''
                  method='post'
                  name='form1'
                >
                  <div class='mb-4 form-grp'>
                    <label for='pwd' class='form-label'>
                      Name
                    </label>
                    <input
                      type='text'
                      class='form-control'
                      id='pwd'
                      placeholder='First Name'
                      name='pswd'
                    />
                  </div>
                  <div class='mb-4 form-grp'>
                    <label for='pwd' class='form-label'>
                      Email{' '}
                    </label>
                    <input
                      type='email'
                      class='form-control'
                      id='pwd'
                      placeholder='Email Address'
                      name='pswd'
                    />
                  </div>
                  <div class='mb-4 form-grp'>
                    <label for='pwd' class='form-label'>
                      Mobile Number{' '}
                    </label>
                    <input
                      type='text'
                      class='form-control'
                      id='pwd'
                      placeholder='Mobile Number'
                      name='pswd'
                    />
                  </div>

                  <div class='mb-4 form-grp'></div>
                  <div class='mb-4 form-grp'>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='checkbox'
                        id='check1'
                        name='option1'
                        value='something'
                        checked=''
                      />
                      <label class='form-check-label'>Active Leads</label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='checkbox'
                        id='check1'
                        name='option1'
                        value='something'
                        checked=''
                      />
                      <label class='form-check-label'>Intent Data</label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='checkbox'
                        id='check1'
                        name='option1'
                        value='something'
                        checked=''
                      />
                      <label class='form-check-label'>Request a Mentor </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='checkbox'
                        id='check1'
                        name='option1'
                        value='something'
                        checked=''
                      />
                      <label class='form-check-label'>Post a Job </label>
                    </div>
                  </div>
                  <div class='mb-4 form-grp'>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='checkbox'
                        id='check1'
                        name='option1'
                        value='something'
                        checked=''
                      />
                      <label class='form-check-label'>
                        Become a Mentor CXO{' '}
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='checkbox'
                        id='check1'
                        name='option1'
                        value='something'
                        checked=''
                      />
                      <label class='form-check-label'>
                        Apply for Investment
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='checkbox'
                        id='check1'
                        name='option1'
                        value='something'
                        checked=''
                      />
                      <label class='form-check-label'>Become an Investor</label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='checkbox'
                        id='check1'
                        name='option1'
                        value='something'
                        checked=''
                      />
                      <label class='form-check-label'>Apply for Job</label>
                    </div>
                  </div>
                  <div class='mb-4 form-grp' style={{ width: '98%' }}>
                    <label for='pwd' class='form-label'>
                      Message{' '}
                    </label>
                    <textarea
                      class='form-control'
                      rows='5'
                      id='description'
                      name='description'
                      placeholder='Message'
                    ></textarea>
                  </div>
                </form>
                <button type='submit' name='submit' class='btn btn-quick'>
                  Submit
                </button>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='mapse-left'>
                <div class='austrial'>
                  <h3>Singapore</h3>
                  <ul>
                    <li>
                      <a href='#'>
                        <i class='fa fa-map-marker' aria-hidden='true'></i>
                        TECHPLUS MEDIA PTE LTD. 68 CIRCULAR ROAD 02-01 SINGAPORE
                        – 049422
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <i class='fa fa-phone' aria-hidden='true'></i>+65
                        94278359
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <i class='fa fa-envelope' aria-hidden='true'></i>
                        CONTACT@LEADXCHANGE.AI
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{' '}
      <section className='mapse'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12 ps-0 pe-0'>
              <div className='mapse-right'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.43929758221!2d103.84711172472012!3d1.2841686836025528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19b9c0f447cd%3A0x48b67b81e7088d3c!2s68%20Circular%20Road%2C%20Singapore%20049422!5e0!3m2!1sen!2ssg!4v1692620503424!5m2!1sen!2ssg'
                  width='100%'
                  height='300'
                  style={{ border: '0' }}
                  allowfullscreen=''
                  loading='lazy'
                  referrerpolicy='no-referrer-when-downgrade'
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUs
