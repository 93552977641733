import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import './UserRoles.css' // Import CSS file for UserRoles
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from 'react-icons/io'
import Header from './Header'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FaPlus } from 'react-icons/fa6'
import { SlPencil } from 'react-icons/sl'
import { MdDelete } from 'react-icons/md'
import { IoEyeSharp } from 'react-icons/io5'
import {
  deleteUserRole,
  getUserRoles,
  saveRoles
} from '../../../../services/userRoleService'
import { toast } from 'react-toastify'

const UserRoles = () => {
  const [data, setData] = useState([])
  const [showPopup, setShowPopup] = useState(false)

  const [pageNumber, setPageNumber] = useState(0)
  const rolesPerPage = 10
  const navigate = useNavigate()
  const user = useSelector(state => state.userReducer)

  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        const response = await getUserRoles()
        setData(response.data)
      } catch (error) {
        console.error('Error fetching user roles:', error)
      }
    }

    fetchUserRoles()
  }, [])

  const pageCount = Math.ceil(data.length / rolesPerPage)

  const handleDelete = async roleId => {
    console.log('roleId', roleId)
    try {
      await deleteUserRole(roleId)
      // Display a success toast notification
      toast.success('User Role deleted successfully')
      // Close the pop-up after successful deletion
      setShowPopup(false)
      // Reload the page with the current page information
      window.location.reload()
      // window.location.href = `/current-page?currentPage=${currentPage}`;
    } catch (error) {
      console.error('Error deleting campaign:', error)
      // Handle error appropriately
    }
  }

  const changePage = increment => {
    setPageNumber(prevPageNumber => {
      const newPageNumber = prevPageNumber + increment
      return newPageNumber >= 0 && newPageNumber < pageCount
        ? newPageNumber
        : prevPageNumber
    })
  }

  return (
    <div className='card'>
      <div
        className='table-container'
        style={{
          borderRadius: '20px',
          padding: '20px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
          marginBottom: '40px',
          marginTop: '30px'
        }}
      >
        <Header />
        <div className='row pt-3'>
          <div className='col-md-12'>
            <div className='source-box'>
              <div className='source-top d-flex'>
                <h3>User Roles List</h3>
                <Link
                  to={'new'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px',
                    borderRadius: '10px',
                    background: '#0A58CA',
                    color: '#fff'
                  }}
                >
                  <FaPlus size={20} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {data.length === 0 ? (
          <p>No user roles found.</p>
        ) : (
          <>
            <table
              style={{
                borderCollapse: 'collapse',
                borderRadius: '10px',
                overflow: 'hidden',
                width: '100%'
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: 'center',
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    No
                  </th>
                  <th
                    style={{
                      textAlign: 'center',
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Username
                  </th>
                  <th
                    style={{
                      textAlign: 'center',
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Email
                  </th>
                  <th
                    style={{
                      textAlign: 'center',
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Department
                  </th>
                  <th
                    style={{
                      textAlign: 'center',
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {data
                  .slice(
                    pageNumber * rolesPerPage,
                    (pageNumber + 1) * rolesPerPage
                  )
                  .map((role, index) => (
                    <tr key={role._id}>
                      <td>{pageNumber * rolesPerPage + index + 1}</td>
                      <td>{role.username}</td>
                      <td>{role.email}</td>
                      <td>{role.department}</td>
                      <td className='button-container'>
                        <div>
                          <Link to={`${role._id}`}>
                            <SlPencil style={{ color: '#0059E3' }} size={20} />
                          </Link>
                        </div>
                        <div className='button-space'></div>
                        <div>
                          <button
                            style={{ background: 'none', border: 'none' }}
                            onClick={() => setShowPopup(role._id)}
                          >
                            <MdDelete
                              style={{ color: '#EE0007', cursor: 'pointer' }}
                              size={25}
                            />
                          </button>
                          {showPopup === role._id && (
                            <div className='custom-popup'>
                              <h2>Confirm Delete</h2>
                              <p>
                                Are you sure you want to delete this User Role?
                              </p>
                              <div className='custom-popup-buttons'>
                                <button
                                  onClick={() => setShowPopup(false)}
                                  className='cancel-btn'
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={() => handleDelete(role._id)}
                                  className='delete-btn'
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              <div>
                <button
                  onClick={() => changePage(-1)}
                  disabled={pageNumber === 0}
                  style={{
                    color: 'black',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    border: 'none',
                    marginRight: '10px'
                  }}
                >
                  <span>
                    <IoIosArrowRoundBack size={25} />
                  </span>
                  Previous
                </button>
                <button
                  onClick={() => changePage(1)}
                  disabled={pageNumber === pageCount - 1}
                  style={{
                    color: 'black',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    border: 'none'
                  }}
                >
                  Next
                  <span>
                    <IoIosArrowRoundForward size={25} />
                  </span>
                </button>
              </div>
              <div>
                <span style={{ color: '#0A58CA' }}>
                  Page {pageNumber + 1} of {pageCount}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default UserRoles
