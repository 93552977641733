import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText
} from '@mui/material'
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from 'react-simple-maps'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { MdErrorOutline } from 'react-icons/md'
import upload from '../../assets/images/upload-icon.jpg'
import { Doughnut } from 'react-chartjs-2'
import './CampaignSetupForm.css'
import { loadStripe } from '@stripe/stripe-js'

ChartJS.register(ArcElement, Tooltip, Legend)

const CampaignSetupForm = () => {
  const steps = ['Step 1', 'Step 2', 'Step 3']

  const subFunctionsITDM = [
    'CIO',
    'CTO',
    'CISO',
    'CDO',
    'CFO',
    'IT Director',
    'VP – IT',
    'GM IT',
    'Network Head',
    'Data Architects',
    'IT Pro',
    'System Admin',
    'IT Manager',
    'AI Developers',
    'Big Data Developers',
    'Network Manager'
  ]

  const subFunctionsBDM = [
    'CEO',
    'Founder',
    'CMO',
    'CFO',
    'CBO',
    'CHRO',
    'CKO',
    'CLO',
    'CRDO',
    'CSO & Other C’Level',
    'LOB Director',
    'Sales Head'
  ]
  const industryVerticals = [
    'Technology',
    'Healthcare & Life Sciences',
    'Finance & Banking',
    'Manufacturing',
    'Retail & eCommerce',
    'Energy & Utilities',
    'Logistics & Transportation',
    'Media & Entertainment',
    'Real Estate & Construction',
    'Agriculture',
    'Education',
    'Hospitality & Tourism',
    'Government & Public Sector',
    'Professional Services',
    'Environmental Services',
    'Automobile',
    'Legal',
    'Aerospace & Aviation'
  ]
  const pricing = {
    MainlandChina: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    HongKong: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Macau: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Tibet: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Xinjiang: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    UK: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Germany: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    France: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Italy: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Spain: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Russia: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    SaudiArabia: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    UAE: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Israel: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Turkey: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Qatar: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    SouthAfrica: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Nigeria: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Egypt: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Kenya: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Morocco: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Argentina: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Bolivia: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Brazil: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Chile: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    China: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Colombia: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Ecuador: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Guyana: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Paraguay: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Peru: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Suriname: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Uruguay: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Venezuela: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    USA: {
      FormFills: 70,
      BANT: 350,
      AppointmentSetting: 600,
      TriangularCall: 750
    },
    Canada: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Mexico: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    PuertoRico: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Bahamas: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Cuba: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Jamaica: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    DominicanRepublic: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Belize: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    CostaRica: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Panama: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Japan: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    SouthKorea: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Taiwan: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Mongolia: {
      FormFills: 70,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Singapore: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Malaysia: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Indonesia: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Philippines: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Thailand: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Vietnam: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Cambodia: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Myanmar: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Laos: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Brunei: {
      FormFills: 50,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    India: {
      FormFills: 47.68,
      BANT: 101.32,
      AppointmentSetting: 298.01,
      TriangularCall: 359.61
    },
    Pakistan: {
      FormFills: 47.68,
      BANT: 101.32,
      AppointmentSetting: 298.01,
      TriangularCall: 357.61
    },
    Bangladesh: {
      FormFills: 47.68,
      BANT: 101.32,
      AppointmentSetting: 298.01,
      TriangularCall: 357.61
    },
    SriLanka: {
      FormFills: 47.68,
      BANT: 101.32,
      AppointmentSetting: 298.01,
      TriangularCall: 357.61
    },
    Nepal: {
      FormFills: 47.68,
      BANT: 101.32,
      AppointmentSetting: 298.01,
      TriangularCall: 357.61
    },
    Bhutan: {
      FormFills: 47.68,
      BANT: 101.32,
      AppointmentSetting: 298.01,
      TriangularCall: 357.61
    },
    Maldives: {
      FormFills: 47.68,
      BANT: 101.32,
      AppointmentSetting: 298.01,
      TriangularCall: 357.61
    },
    Australia: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    NewZealand: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    Fiji: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    },
    PapuaNewGuinea: {
      FormFills: 60,
      BANT: 250,
      AppointmentSetting: 350,
      TriangularCall: 450
    }
  }

  const markers = {
    // Existing markers
    USA: { coordinates: [-99.1332, 39.8283], radius: 30 },
    Canada: { coordinates: [-106.3468, 56.1304], radius: 30 },
    Mexico: { coordinates: [-102.5528, 23.6345], radius: 30 },
    PuertoRico: { coordinates: [-66.5901, 18.2208], radius: 20 },
    Bahamas: { coordinates: [-77.3963, 25.0343], radius: 20 },
    Cuba: { coordinates: [-77.7812, 21.5218], radius: 20 },
    Jamaica: { coordinates: [-77.309, 18.1096], radius: 20 },
    DominicanRepublic: { coordinates: [-70.1627, 18.7357], radius: 20 },
    Belize: { coordinates: [-88.4976, 17.1899], radius: 20 },
    CostaRica: { coordinates: [-84.2075, 9.7489], radius: 20 },
    Panama: { coordinates: [-80.7821, 8.9824], radius: 20 },

    // New markers for China regions
    MainlandChina: { coordinates: [104.1954, 35.8617], radius: 40 },
    HongKong: { coordinates: [114.1694, 22.3193], radius: 30 },
    Macau: { coordinates: [113.543, 22.1988], radius: 30 },
    Tibet: { coordinates: [91.1717, 30.7379], radius: 30 },
    Xinjiang: { coordinates: [87.6177, 43.7928], radius: 30 },

    // New markers for EMEA regions
    UK: { coordinates: [-3.435973, 55.378051], radius: 30 },
    Germany: { coordinates: [10.451526, 51.165691], radius: 30 },
    France: { coordinates: [2.213749, 46.603354], radius: 30 },
    Italy: { coordinates: [12.56738, 41.87194], radius: 30 },
    Spain: { coordinates: [-3.70379, 40.416775], radius: 30 },
    Russia: { coordinates: [37.6173, 55.755825], radius: 30 },
    SaudiArabia: { coordinates: [45.079162, 23.885942], radius: 30 },
    UAE: { coordinates: [53.847818, 23.424076], radius: 30 },
    Israel: { coordinates: [34.851612, 31.046051], radius: 30 },
    Turkey: { coordinates: [35.243322, 38.963745], radius: 30 },
    Qatar: { coordinates: [51.183884, 25.354826], radius: 30 },
    SouthAfrica: { coordinates: [22.937506, -30.559482], radius: 30 },
    Nigeria: { coordinates: [8.675277, 9.081999], radius: 30 },
    Egypt: { coordinates: [30.802498, 26.820553], radius: 30 },
    Kenya: { coordinates: [37.906193, -1.292066], radius: 30 },
    Morocco: { coordinates: [-7.09262, 31.791702], radius: 30 },

    // New markers for South American countries
    Argentina: { coordinates: [-63.6167, -38.4161], radius: 15 },
    Bolivia: { coordinates: [-64.099, -16.5], radius: 15 },
    Brazil: { coordinates: [-51.9253, -14.235], radius: 15 },
    Chile: { coordinates: [-71.5376, -35.6751], radius: 15 },
    Colombia: { coordinates: [-74.2973, 4.5709], radius: 15 },
    Ecuador: { coordinates: [-78.1834, -1.8312], radius: 15 },
    Guyana: { coordinates: [-58.9302, 4.8604], radius: 15 },
    Paraguay: { coordinates: [-58.4438, -23.442], radius: 15 },
    Peru: { coordinates: [-75.0152, -9.19], radius: 15 },
    Suriname: { coordinates: [-56.0278, 3.9193], radius: 15 },
    Uruguay: { coordinates: [-55.7658, -32.5228], radius: 15 },
    Venezuela: { coordinates: [-66.5897, 10.488], radius: 15 },

    // New markers for APAC regions
    China: { coordinates: [104.1954, 35.8617], radius: 15 },
    Japan: { coordinates: [138.2529, 36.2048], radius: 15 },
    SouthKorea: { coordinates: [127.7669, 35.9078], radius: 15 },
    Taiwan: { coordinates: [121.1739, 23.6978], radius: 15 },
    Mongolia: { coordinates: [105.551, 46.8251], radius: 15 },
    Singapore: { coordinates: [103.8198, 1.3521], radius: 20 },
    Malaysia: { coordinates: [101.9758, 4.2105], radius: 20 },
    Indonesia: { coordinates: [113.9213, -0.7893], radius: 20 },
    Philippines: { coordinates: [121.774, 12.8797], radius: 20 },
    Thailand: { coordinates: [100.9925, 15.87], radius: 20 },
    Vietnam: { coordinates: [108.2772, 14.0583], radius: 20 },
    Cambodia: { coordinates: [104.9909, 12.5657], radius: 20 },
    Myanmar: { coordinates: [96.9036, 21.9162], radius: 20 },
    Laos: { coordinates: [102.4955, 19.8563], radius: 20 },
    Brunei: { coordinates: [114.9398, 4.5353], radius: 20 },
    India: { coordinates: [78.9629, 20.5937], radius: 15 },
    Pakistan: { coordinates: [69.3451, 30.3753], radius: 15 },
    Bangladesh: { coordinates: [90.3563, 23.685], radius: 15 },
    SriLanka: { coordinates: [80.7718, 7.8731], radius: 15 },
    Nepal: { coordinates: [80.2707, 28.3949], radius: 15 },
    Bhutan: { coordinates: [90.4336, 27.5142], radius: 15 },
    Maldives: { coordinates: [73.2207, 3.2028], radius: 15 },
    Australia: { coordinates: [133.7751, -25.2744], radius: 15 },
    NewZealand: { coordinates: [174.885, -40.9006], radius: 15 },
    PacificIslands: { coordinates: [158.2673, -21.2286], radius: 15 } // Central coordinates for the Pacific Islands
  }
  // Define the regions and countries
  const regionOptions = [
    { value: '', label: 'Select Region' },
    { value: 'China', label: 'China' },
    { value: 'EMEA', label: 'EMEA' },
    { value: 'SouthAmerica', label: 'South America' },
    { value: 'NorthAmerica', label: 'North America' },
    { value: 'APAC', label: 'Asia-Pacific' }
  ]

  const countryOptions = {
    China: [
      { value: 'MainlandChina', label: 'Mainland China' },
      { value: 'HongKong', label: 'Hong Kong' },
      { value: 'Macau', label: 'Macau' },
      { value: 'Tibet', label: 'Tibet' },
      { value: 'Xinjiang', label: 'Xinjiang' }
    ],
    EMEA: [
      { value: 'UK', label: 'UK' },
      { value: 'Germany', label: 'Germany' },
      { value: 'France', label: 'France' },
      { value: 'Italy', label: 'Italy' },
      { value: 'Spain', label: 'Spain' },
      { value: 'Russia', label: 'Russia' },
      { value: 'SaudiArabia', label: 'Saudi Arabia' },
      { value: 'UAE', label: 'UAE' },
      { value: 'Israel', label: 'Israel' },
      { value: 'Turkey', label: 'Turkey' },
      { value: 'Qatar', label: 'Qatar' },
      { value: 'SouthAfrica', label: 'South Africa' },
      { value: 'Nigeria', label: 'Nigeria' },
      { value: 'Egypt', label: 'Egypt' },
      { value: 'Kenya', label: 'Kenya' },
      { value: 'Morocco', label: 'Morocco' }
    ],
    SouthAmerica: [
      { value: 'Argentina', label: 'Argentina' },
      { value: 'Bolivia', label: 'Bolivia' },
      { value: 'Brazil', label: 'Brazil' },
      { value: 'Chile', label: 'Chile' },
      { value: 'Colombia', label: 'Colombia' },
      { value: 'Ecuador', label: 'Ecuador' },
      { value: 'Guyana', label: 'Guyana' },
      { value: 'Paraguay', label: 'Paraguay' },
      { value: 'Peru', label: 'Peru' },
      { value: 'Suriname', label: 'Suriname' },
      { value: 'Uruguay', label: 'Uruguay' },
      { value: 'Venezuela', label: 'Venezuela' }
    ],
    NorthAmerica: [
      { value: 'USA', label: 'USA' },
      { value: 'Canada', label: 'Canada' },
      { value: 'Mexico', label: 'Mexico' },
      { value: 'PuertoRico', label: 'Puerto Rico' },
      { value: 'Bahamas', label: 'Bahamas' },
      { value: 'Cuba', label: 'Cuba' },
      { value: 'Jamaica', label: 'Jamaica' },
      { value: 'DominicanRepublic', label: 'Dominican Republic' },
      { value: 'Belize', label: 'Belize' },
      { value: 'CostaRica', label: 'Costa Rica' },
      { value: 'Panama', label: 'Panama' }
    ],
    APAC: [
      // { value: 'China', label: 'China' },
      { value: 'Japan', label: 'Japan' },
      { value: 'SouthKorea', label: 'South Korea' },
      { value: 'Taiwan', label: 'Taiwan' },
      { value: 'Mongolia', label: 'Mongolia' },
      { value: 'Singapore', label: 'Singapore' },
      { value: 'Malaysia', label: 'Malaysia' },
      { value: 'Indonesia', label: 'Indonesia' },
      { value: 'Philippines', label: 'Philippines' },
      { value: 'Thailand', label: 'Thailand' },
      { value: 'Vietnam', label: 'Vietnam' },
      { value: 'Cambodia', label: 'Cambodia' },
      { value: 'Myanmar', label: 'Myanmar' },
      { value: 'Laos', label: 'Laos' },
      { value: 'Brunei', label: 'Brunei' },
      { value: 'India', label: 'India' },
      { value: 'Pakistan', label: 'Pakistan' },
      { value: 'Bangladesh', label: 'Bangladesh' },
      { value: 'SriLanka', label: 'Sri Lanka' },
      { value: 'Nepal', label: 'Nepal' },
      { value: 'Bhutan', label: 'Bhutan' },
      { value: 'Maldives', label: 'Maldives' },
      { value: 'Australia', label: 'Australia' },
      { value: 'NewZealand', label: 'New Zealand' },
      { value: 'Fiji', label: 'Fiji' },
      { value: 'PapuaNewGuinea', label: 'Papua New Guinea' }
    ]
  }
  const [activeStep, setActiveStep] = useState(0)
  const [campaignData, setCampaignData] = useState({
    funnel: [],
    objective: '',
    abm: true,
    jobTitle: {
      title: '',
      otherTitle: []
    },
    tal: true,
    location: '',
    industries: [], // Change industries to an array for multiple selections
    organizationSize: '',
    tactics: {
      type: 'Auto',
      suggest: true
    },
    assetsFile: null,
    talListFile: null,
    additionalFilterEnabled: false,
    filters: [],
    filterimage: null,
    purchaseOrderFile: null,
    additionalFilters: '',
    campaignTimeline: '',
    reportFrequency: '',
    region: '',
    country: '',
    quantity: '',
    leadCategory: '',
    // tal2: '',
    total: 0,
    subTotal: 0,
    conversionRate: 1,
    companyRevenue: '',
    industryVertical: []
    // jobLevel: ''
  })

  const handleNext = () => setActiveStep(prevStep => prevStep + 1)
  const handleBack = () => setActiveStep(prevStep => prevStep - 1)

  const stripePromise = loadStripe(process.env.REACT_APP_PULISHABLE_KEY)
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [enumTactics, setEnumTactics] = useState([])
  const [activeMarkers, setActiveMarkers] = useState([])
  const [total, setTotal] = useState(0)
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  })
  const handleRegionChange = event => {
    const selectedRegion = event.target.value

    setCampaignData(prevData => ({
      ...prevData,
      region: selectedRegion,
      country: '' // Reset country when region changes
    }))
  }

  const handleCountryChange = event => {
    const selectedCountry = event.target.value

    setCampaignData(prevData => ({
      ...prevData,
      country: selectedCountry
    }))
  }

  const enumValues = {
    reportFrequency: [
      'Twice a week',
      'Weekly',
      'Fortnightly',
      'Monthly',
      'Others'
    ],
    industries: [
      'Cross Industry',
      'BFSI',
      'Manufacturing',
      'Retail',
      'Entertainment',
      'Health & Pharma',
      'ITES',
      'Education',
      'Media and news',
      'Construction',
      'Hospitality',
      'Other'
    ],
    funnel: ['Tofu', 'Mofu', 'Bofu', 'All'],
    objectives: [
      'Brand Awareness',
      'Generate Sales',
      'Lead Capture and Nurturing',
      'Increase Website Traffic'
    ],
    tactics: {
      default: [
        'Content Syndication',
        'Email marketing',
        'Newsletter',
        'Webinar',
        '1-on-1',
        'Digital Chat series',
        'Digital Board room',
        'Inner Board room',
        'Social Media'
      ],
      auto: ['Auto']
    }
  }

  useEffect(() => {
    // Check if user data exists in sessionStorage
    const userDataString = sessionStorage.getItem('user')

    if (!userDataString) {
      // Navigate user to sign-in page if no user data is found
      navigate('/signin')
      return // Exit early if no user data is found
    }
  }, [navigate])

  const handleCheckboxChange = event => {
    const { name, checked } = event.target
    setCampaignData(prevState => ({
      ...prevState,
      [name]: checked,
      total: calculateTotalAmount(prevState, checked)
    }))
  }

  const calculateTotalAmount = (data, isExtra) => {
    let subTotal = data.subTotal // Use `let` to allow reassignment
    // console.log('Subtotal:', subTotal)

    if (isExtra) {
      subTotal *= 1.25 // Adds 25% extra
      console.log('Subtotal after adding 25% extra:', subTotal)
    }

    const total = subTotal * data.quantity
    // console.log('Total after multiplying by quantity:', total)

    const finalTotal = Number(total.toFixed(2))
    // console.log('Final Total (rounded to 2 decimal places):', finalTotal)

    return finalTotal
  }

  const handleChange = e => {
    const { name, value, type, checked, files, options } = e.target

    if (type === 'checkbox') {
      if (name === 'funnel' || name === 'objective' || name === 'industries') {
        const updatedValues = checked
          ? [...campaignData[name], value]
          : campaignData[name].filter(item => item !== value)
        setCampaignData({ ...campaignData, [name]: updatedValues })
      } else {
        setCampaignData({ ...campaignData, [name]: checked })
      }
    } else if (name.startsWith('jobTitle')) {
      if (e.target.multiple) {
        // Handle multiple selections for sub-functions
        const selectedValues = Array.from(options)
          .filter(option => option.selected)
          .map(option => option.value)

        const updatedJobTitle = {
          ...campaignData.jobTitle,
          [name.split('.')[1]]: selectedValues // Update otherTitle as an array for multiple selections
        }
        setCampaignData({ ...campaignData, jobTitle: updatedJobTitle })
      } else {
        // Handle single value (e.g., job title)
        const updatedJobTitle = {
          ...campaignData.jobTitle,
          [name.split('.')[1]]: value
        }
        setCampaignData({ ...campaignData, jobTitle: updatedJobTitle })
      }
    } else if (name === 'industryVertical') {
      if (e.target.multiple) {
        // Handle multiple selections for industryVertical
        const selectedValues = Array.from(options)
          .filter(option => option.selected)
          .map(option => option.value)

        setCampaignData({ ...campaignData, industryVertical: selectedValues })
      } else {
        setCampaignData({ ...campaignData, industryVertical: value })
      }
    } else if (name.startsWith('tactics')) {
      const updatedTactics = {
        ...campaignData.tactics,
        [name.split('.')[1]]: value
      }
      setCampaignData({ ...campaignData, tactics: updatedTactics })

      // Update tactics enum based on selection of "Auto"
      if (value === 'Auto') {
        setEnumTactics(enumValues.tactics.auto)
      } else {
        setEnumTactics(enumValues.tactics.default)
      }
    } else if (type === 'file') {
      console.log('Files:', files)
      setCampaignData({ ...campaignData, [name]: files[0] })
    } else {
      setCampaignData({ ...campaignData, [name]: value })
    }
  }

  const handleIndustriesChange = e => {
    const { value, checked } = e.target
    const updatedIndustries = [...campaignData.industries]

    if (checked && !updatedIndustries.includes(value)) {
      updatedIndustries.push(value) // Add the selected industry
    } else if (!checked && updatedIndustries.includes(value)) {
      const index = updatedIndustries.indexOf(value)
      updatedIndustries.splice(index, 1) // Remove the deselected industry
    }

    setCampaignData({ ...campaignData, industries: updatedIndustries })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const requiredFields = [
        'funnel',
        'objective',
        'location',
        'organizationSize',
        'leadCategory',
        'quantity',
        'region'
      ]

      // Validate required fields
      const isValid = requiredFields.every(field => {
        const value = campaignData[field]
        if (field === 'funnel' || field === 'industries') {
          return Array.isArray(value) && value.length > 0
        } else {
          return typeof value === 'string' && value.trim() !== ''
        }
      })

      if (!isValid) {
        throw new Error('Please fill out all required fields.')
      }

      // Validate reportFrequency
      if (!enumValues.reportFrequency.includes(campaignData.reportFrequency)) {
        throw new Error('Invalid report frequency selected.')
      }

      // Validate industries
      if (
        !campaignData.industries.every(industry =>
          enumValues.industries.includes(industry)
        )
      ) {
        throw new Error('Invalid industry selected.')
      }

      const userDataString = sessionStorage.getItem('user')
      if (!userDataString) {
        throw new Error('User data not found in Local Storage')
      }
      const userData = JSON.parse(userDataString)
      const userId = userData._id // Get the user ID
      const stripe = await stripePromise

      const formData = new FormData()
      formData.append('user', userId) // Append User ID

      // Append each key-value pair from campaignData to formData
      Object.entries(campaignData).forEach(([key, value]) => {
        if (key === 'jobTitle' && value) {
          formData.append('jobTitle[title]', value.title || '')
          formData.append('jobTitle[otherTitle]', value.otherTitle || '')
        } else if (key === 'tactics' && value) {
          formData.append('tactics[type]', value.type || '')
          formData.append(
            'tactics[suggest]',
            value.suggest ? value.suggest.toString() : ''
          )
        } else if (value !== null && value !== 'null' && value !== '') {
          if (Array.isArray(value)) {
            value.forEach(item => formData.append(key, item))
          } else {
            formData.append(key, value)
          }
        }
      })

      // Debug: Log the FormData contents
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`)
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/campaignSetup/createCampaignSetup`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      setErrorMessage('')
      // Proceed with Stripe payment after successful data submission
      const paymentResponse = await axios.post(
        '/payment',
        { campaignData, userId },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SECRET_KEY}`
          }
        }
      )

      const sessionId = paymentResponse.data.id
      const result = await stripe.redirectToCheckout({ sessionId })
    } catch (error) {
      console.error('Failed to create campaign:', error.message)
      setErrorMessage(error.message)
    }
  }

  const renderFileInput = (name, label) => (
    <div className='form-group'>
      {campaignData[name] && (
        <img
          src={URL.createObjectURL(campaignData[name])}
          alt='Preview'
          className='mt-2 mb-2'
          style={{
            width: '400px', // Set the desired width
            height: '200px', // Set the desired height
            objectFit: 'fit',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }}
          onClick={e => {
            e.preventDefault() // Prevent default action when clicking on the image
            // Add any specific handling code here if needed
          }}
        />
      )}
    </div>
  )

  // Function to handle changes in additional filter enable/disable
  const handleAdditionalFilterChange = e => {
    const { name, value } = e.target
    setCampaignData({ ...campaignData, [name]: value === 'true' })
  }

  // Function to handle changes in filters array
  const handleFiltersChange = (index, value) => {
    const updatedFilters = [...campaignData.filters]
    updatedFilters[index] = value
    setCampaignData({ ...campaignData, filters: updatedFilters })
  }

  // Function to add a new filter input
  const addFilterInput = () => {
    setCampaignData({
      ...campaignData,
      filters: [...campaignData.filters, '']
    })
  }

  // Function to remove a filter input
  const removeFilterInput = index => {
    const updatedFilters = [...campaignData.filters]
    updatedFilters.splice(index, 1)
    setCampaignData({ ...campaignData, filters: updatedFilters })
  }

  const renderError = field => {
    if (errorMessage && errorMessage.includes(field)) {
      return (
        <div className='alert alert-danger mt-2' role='alert'>
          {errorMessage}
        </div>
      )
    }
  }
  const getCurrencySymbol = () => {
    const asianRegions = [
      'Malaysia',
      'Indonesia',
      'Philippines',
      'Vietnam',
      'Thailand',
      'Singapore'
    ]

    // Check if the campaignData.region is in the list of Asian regions
    return asianRegions.includes(campaignData.country) ? 'SGD ' : '$ '
  }

  useEffect(() => {
    const calculateTotals = () => {
      // console.log('Region:', campaignData.region)
      // console.log('Lead Category:', campaignData.leadCategory)

      const unitPrice =
        Number(pricing[campaignData.country]?.[campaignData.leadCategory]) || 0

      // console.log('Unit Price:', unitPrice)

      let subTotal =
        unitPrice *
        Number(campaignData.quantity) *
        Number(campaignData.conversionRate)
      subTotal = Number(Math.round(subTotal))

      let total = subTotal
      subTotal = unitPrice
      total = Number(Math.round(total))

      setCampaignData(prevState => ({
        ...prevState,
        subTotal,
        total
      }))
    }

    calculateTotals()
  }, [
    campaignData.quantity,
    campaignData.region,
    campaignData.leadCategory,
    campaignData.country
  ])

  // useEffect(() => {
  //   // Fetch data from the backend using Axios
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_URL}/chart?country=${campaignData.region}&jobLevel=${campaignData.jobLevel}&companySize=${campaignData.organizationSize}`
  //     )
  //     .then(response => {
  //       const data = response.data.data

  //       // Process data for the chart
  //       const labels = [
  //         ...new Set(data.map(item => item.industry || 'Unknown'))
  //       ] // Unique industries
  //       const counts = labels.map(label => {
  //         // Count occurrences for each industry
  //         return data.filter(item => item.industry === label).length
  //       })

  //       console.log(counts, 'counts', labels, 'labels')

  //       // Define colors for the chart
  //       const colors = [
  //         '#FF0000', // Red
  //         '#55B56D', // Green
  //         '#0000FF', // Blue
  //         '#FFFF00', // Yellow
  //         '#FF00FF', // Magenta
  //         '#FFA500', // Orange
  //         '#800080', // Purple
  //         '#00FFFF', // Cyan
  //         '#808080', // Gray
  //         '#008000', // Dark Green
  //         '#FFC0CB', // Pink
  //         '#000000', // Black
  //         '#FF6347', // Tomato
  //         '#40E0D0', // Turquoise
  //         '#FFD700', // Gold
  //         '#ADFF2F', // Green Yellow
  //         '#FF1493', // Deep Pink
  //         '#00BFFF', // Deep Sky Blue
  //         '#1E90FF', // Dodger Blue
  //         '#FF4500', // Orange Red
  //         '#DAA520', // Golden Rod
  //         '#D3D3D3', // Light Gray
  //         '#F08080', // Light Coral
  //         '#D3C0CD', // Plum
  //         '#FF8C00', // Dark Orange
  //         '#F5DEB3', // Wheat
  //         '#00FA9A' // Medium Spring Green
  //       ]

  //       // Set the data for the chart
  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Count by Industry', // Update label as needed
  //             data: counts,
  //             backgroundColor: labels.map(
  //               (_, index) => colors[index % colors.length]
  //             ),
  //             borderColor: labels.map(
  //               (_, index) => colors[index % colors.length]
  //             ),
  //             borderWidth: 1
  //           }
  //         ]
  //       })
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data from backend:', error)
  //     })
  // }, [
  //   campaignData.region,
  //   campaignData.jobLevel,
  //   campaignData.organizationSize
  // ])

  useEffect(() => {
    const data = {
      Technology: {
        'North America': 60000, // 6,500,000
        'South America': 500000, // 500,000
        EMEA: 100000, // 100,000
        'Asia Pacific': 1500000, // 1,500,000
        China: 500000 // 500,000
      },
      'Healthcare & Life Sciences': {
        'North America': 300000, // 3,000,000
        'South America': 200000, // 200,000
        EMEA: 2500000, // 2,500,000
        'Asia Pacific': 100000, // 100,000
        China: 150000 // 150,000
      },
      'Finance & Banking': {
        'North America': 50000, // 5,500,000
        'South America': 120000, // 120,000
        EMEA: 80000, // 80,000
        'Asia Pacific': 100000, // 100,000
        China: 1500000 // 1,500,000
      },
      Manufacturing: {
        'North America': 20000, // 2,500,000
        'South America': 300000, // 300,000
        EMEA: 150000, // 150,000
        'Asia Pacific': 200000, // 200,000
        China: 100000 // 100,000
      },
      'Retail & eCommerce': {
        'North America': 200000, // 20,000,000
        'South America': 25000000, // 2,500,000
        EMEA: 35000000, // 3,500,000
        'Asia Pacific': 10000000, // 1,000,000
        China: 10000000 // 1,000,000
      },
      'Energy & Utilities': {
        'North America': 5000000, // 50,000,000
        'South America': 5000000, // 500,000
        EMEA: 30000000, // 3,000,000
        'Asia Pacific': 10000000, // 1,000,000
        China: 5000000 // 500,000
      },
      'Logistics & Transportation': {
        'North America': 3000000, // 35,000,000
        'South America': 15000000, // 1,500,000
        EMEA: 30000000, // 3,000,000
        'Asia Pacific': 15000000, // 1,500,000
        China: 5000000 // 500,000
      },
      'Media & Entertainment': {
        'North America': 500000, // 55,000,000
        'South America': 500000, // 500,000
        EMEA: 150000, // 1,500,000
        'Asia Pacific': 20000, // 2,000,000
        China: 500000 // 500,000
      },
      'Real Estate & Construction': {
        'North America': 4000000, // 40,000,000
        'South America': 200000, // 200,000
        EMEA: 200000, // 2,000,000
        'Asia Pacific': 150000, // 1,500,000
        China: 5000000 // 500,000
      },
      Agriculture: {
        'North America': 1000000, // 10,000,000
        'South America': 5000000, // 5,000,000
        EMEA: 200000, // 2,000,000
        'Asia Pacific': 1500000, // 1,500,000
        China: 5000000 // 500,000
      },
      Education: {
        'North America': 200000, // 25,000,000
        'South America': 150000, // 150,000
        EMEA: 2500000, // 2,500,000
        'Asia Pacific': 250000, // 2,500,000
        China: 1000000 // 1,000,000
      },
      'Hospitality & Tourism': {
        'North America': 3000000, // 35,000,000
        'South America': 1500000, // 150,000
        EMEA: 30000000, // 3,000,000
        'Asia Pacific': 10000000, // 1,000,000
        China: 10000000 // 1,000,000
      },
      'Government & Public Sector': {
        'North America': 200000, // 20,000,000
        'South America': 1000000, // 100,000
        EMEA: 40000000, // 4,000,000
        'Asia Pacific': 200000, // 2,000,000
        China: 10000000 // 1,000,000
      },
      'Professional Services': {
        'North America': 4000000, // 45,000,000
        'South America': 1000000, // 100,000
        EMEA: 200000, // 2,000,000
        'Asia Pacific': 150000, // 1,500,000
        China: 10000000 // 1,000,000
      },
      'Environmental Services': {
        'North America': 3000000, // 30,000,000
        'South America': 200000, // 200,000
        EMEA: 200000, // 2,000,000
        'Asia Pacific': 25000000, // 2,500,000
        China: 5000000 // 500,000
      },
      Automobile: {
        'North America': 6000000, // 60,000,000
        'South America': 1000000, // 100,000
        EMEA: 15000000, // 1,500,000
        'Asia Pacific': 10000000, // 1,000,000
        China: 5000000 // 500,000
      },
      Legal: {
        'North America': 5000000, // 50,000,000
        'South America': 800000, // 80,000
        EMEA: 2200000, // 2,200,000
        'Asia Pacific': 15000000, // 1,500,000
        China: 5000000 // 500,000
      },
      'Aerospace & Aviation': {
        'North America': 7000000, // 70,000,000
        'South America': 500000, // 500,000
        EMEA: 10000000, // 1,000,000
        'Asia Pacific': 10000000, // 1,000,000
        China: 5000000 // 500,000
      }
    }

    // Define initial/default data
    const initialData = {
      'North America': 500000,
      'South America': 150000,
      EMEA: 20000,
      'Asia Pacific': 10000,
      China: 5000
    }

    // Determine the current sector's data
    const sectorData = data[campaignData?.industryVertical] || initialData
    const labels = Object.keys(sectorData)
    const values = Object.values(sectorData)

    const colors = [
      '#007BFF', // Light Blue
      '#28A745', // Green
      '#FFC107', // Yellow
      '#DC3545', // Red
      '#6610F2', // Purple
      '#FF5733', // Orange
      '#20C997', // Teal
      '#17A2B8', // Cyan
      '#6C757D', // Gray
      '#FFB533' // Warm Peach
    ]
    // Calculate the total sum of the values
    const total = values.reduce((acc, val) => acc + val, 0)
    // Set chart data
    setChartData({
      labels: labels,
      datasets: [
        {
          label: `${
            campaignData?.industryVertical || 'Default'
          } Sector Distribution`,
          data: values,
          backgroundColor: colors,
          hoverOffset: 4
        }
      ]
    })
    setTotal(total)
  }, [campaignData?.industryVertical])

  useEffect(() => {
    const data = {
      MainlandChina: {
        'North America': 50000,
        'South America': 20000,
        EMEA: 25000,
        'Asia Pacific': 50000,
        China: 13000
      },
      HongKong: {
        'North America': 50000,
        'South America': 30000,
        EMEA: 30000,
        'Asia Pacific': 50000,
        China: 12000
      },
      Macau: {
        'North America': 100000,
        'South America': 50000,
        EMEA: 20000,
        'Asia Pacific': 50000,
        China: 15000
      },
      Tibet: {
        'North America': 150000,
        'South America': 100000,
        EMEA: 25000,
        'Asia Pacific': 40000,
        China: 10000
      },
      Xinjiang: {
        'North America': 50000,
        'South America': 30000,
        EMEA: 30000,
        'Asia Pacific': 50000,
        China: 12000
      },
      UK: {
        'North America': 150000,
        'South America': 50000,
        EMEA: 50000,
        'Asia Pacific': 20000,
        China: 10000
      },
      Germany: {
        'North America': 100000,
        'South America': 50000,
        EMEA: 60000,
        'Asia Pacific': 10000,
        China: 10000
      },
      France: {
        'North America': 150000,
        'South America': 100000,
        EMEA: 50000,
        'Asia Pacific': 10000,
        China: 10000
      },
      Italy: {
        'North America': 100000,
        'South America': 100000,
        EMEA: 60000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Spain: {
        'North America': 100000,
        'South America': 50000,
        EMEA: 55000,
        'Asia Pacific': 20000,
        China: 10000
      },
      Russia: {
        'North America': 100000,
        'South America': 50000,
        EMEA: 60000,
        'Asia Pacific': 10000,
        China: 10000
      },
      SaudiArabia: {
        'North America': 50000,
        'South America': 50000,
        EMEA: 70000,
        'Asia Pacific': 10000,
        China: 5000
      },
      UAE: {
        'North America': 100000,
        'South America': 50000,
        EMEA: 60000,
        'Asia Pacific': 10000,
        China: 10000
      },
      Israel: {
        'North America': 100000,
        'South America': 100000,
        EMEA: 55000,
        'Asia Pacific': 10000,
        China: 10000
      },
      Turkey: {
        'North America': 50000,
        'South America': 50000,
        EMEA: 60000,
        'Asia Pacific': 20000,
        China: 10000
      },
      Qatar: {
        'North America': 50000,
        'South America': 50000,
        EMEA: 70000,
        'Asia Pacific': 10000,
        China: 5000
      },
      SouthAfrica: {
        'North America': 50000,
        'South America': 50000,
        EMEA: 60000,
        'Asia Pacific': 20000,
        China: 10000
      },
      Nigeria: {
        'North America': 100000,
        'South America': 50000,
        EMEA: 55000,
        'Asia Pacific': 20000,
        China: 10000
      },
      Egypt: {
        'North America': 50000,
        'South America': 50000,
        EMEA: 65000,
        'Asia Pacific': 20000,
        China: 5000
      },
      Kenya: {
        'North America': 50000,
        'South America': 50000,
        EMEA: 60000,
        'Asia Pacific': 20000,
        China: 5000
      },
      Morocco: {
        'North America': 50000,
        'South America': 50000,
        EMEA: 65000,
        'Asia Pacific': 20000,
        China: 5000
      },
      Argentina: {
        'North America': 50000,
        'South America': 700000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Bolivia: {
        'North America': 50000,
        'South America': 750000,
        EMEA: 5000,
        'Asia Pacific': 60000,
        China: 5000
      },
      Chile: {
        'North America': 50000,
        'South America': 700000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Ecuador: {
        'North America': 50000,
        'South America': 700000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Guyana: {
        'North America': 50000,
        'South America': 650000,
        EMEA: 15000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Paraguay: {
        'North America': 50000,
        'South America': 750000,
        EMEA: 5000,
        'Asia Pacific': 60000,
        China: 5000
      },
      Peru: {
        'North America': 50000,
        'South America': 700000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Suriname: {
        'North America': 50000,
        'South America': 600000,
        EMEA: 15000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Uruguay: {
        'North America': 50000,
        'South America': 700000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Venezuela: {
        'North America': 50000,
        'South America': 700000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Canada: {
        'North America': 800000,
        'South America': 50000,
        EMEA: 5000,
        'Asia Pacific': 80000,
        China: 5000
      },
      Mexico: {
        'North America': 700000,
        'South America': 100000,
        EMEA: 10000,
        'Asia Pacific': 5000,
        China: 5000
      },
      PuertoRico: {
        'North America': 650000,
        'South America': 100000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Bahamas: {
        'North America': 600000,
        'South America': 150000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Cuba: {
        'North America': 700000,
        'South America': 50000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Jamaica: {
        'North America': 650000,
        'South America': 50000,
        EMEA: 15000,
        'Asia Pacific': 10000,
        China: 5000
      },
      DominicanRepublic: {
        'North America': 700000,
        'South America': 50000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Belize: {
        'North America': 650000,
        'South America': 100000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      CostaRica: {
        'North America': 600000,
        'South America': 150000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      Panama: {
        'North America': 650000,
        'South America': 100000,
        EMEA: 10000,
        'Asia Pacific': 10000,
        China: 5000
      },
      China: {
        'North America': 200000,
        'South America': 150000,
        EMEA: 30000,
        'Asia Pacific': 40000,
        China: 25000
      },
      Japan: {
        'North America': 250000,
        'South America': 100000,
        EMEA: 20000,
        'Asia Pacific': 50000,
        China: 15000
      },
      SouthKorea: {
        'North America': 300000,
        'South America': 50000,
        EMEA: 15000,
        'Asia Pacific': 60000,
        China: 20000
      },
      Taiwan: {
        'North America': 180000,
        'South America': 80000,
        EMEA: 25000,
        'Asia Pacific': 50000,
        China: 12000
      },
      Mongolia: {
        'North America': 100000,
        'South America': 50000,
        EMEA: 30000,
        'Asia Pacific': 40000,
        China: 20000
      },
      Singapore: {
        'North America': 150000,
        'South America': 70000,
        EMEA: 35000,
        'Asia Pacific': 60000,
        China: 18000
      },
      Malaysia: {
        'North America': 120000,
        'South America': 60000,
        EMEA: 28000,
        'Asia Pacific': 50000,
        China: 22000
      },
      Indonesia: {
        'North America': 100000,
        'South America': 50000,
        EMEA: 25000,
        'Asia Pacific': 50000,
        China: 20000
      },
      Philippines: {
        'North America': 80000,
        'South America': 40000,
        EMEA: 22000,
        'Asia Pacific': 40000,
        China: 15000
      },
      Thailand: {
        'North America': 140000,
        'South America': 50000,
        EMEA: 26000,
        'Asia Pacific': 50000,
        China: 20000
      },
      Vietnam: {
        'North America': 90000,
        'South America': 30000,
        EMEA: 20000,
        'Asia Pacific': 40000,
        China: 12000
      },
      Cambodia: {
        'North America': 70000,
        'South America': 30000,
        EMEA: 18000,
        'Asia Pacific': 40000,
        China: 10000
      },
      Myanmar: {
        'North America': 50000,
        'South America': 20000,
        EMEA: 15000,
        'Asia Pacific': 30000,
        China: 8000
      },
      Laos: {
        'North America': 40000,
        'South America': 20000,
        EMEA: 12000,
        'Asia Pacific': 20000,
        China: 6000
      },
      Brunei: {
        'North America': 30000,
        'South America': 10000,
        EMEA: 10000,
        'Asia Pacific': 20000,
        China: 5000
      },
      India: {
        'North America': 280000,
        'South America': 120000,
        EMEA: 30000,
        'Asia Pacific': 50000,
        China: 20000
      },
      Pakistan: {
        'North America': 180000,
        'South America': 70000,
        EMEA: 25000,
        'Asia Pacific': 50000,
        China: 15000
      },
      Bangladesh: {
        'North America': 150000,
        'South America': 60000,
        EMEA: 20000,
        'Asia Pacific': 40000,
        China: 10000
      },
      SriLanka: {
        'North America': 120000,
        'South America': 50000,
        EMEA: 15000,
        'Asia Pacific': 40000,
        China: 8000
      },
      Nepal: {
        'North America': 100000,
        'South America': 40000,
        EMEA: 12000,
        'Asia Pacific': 30000,
        China: 6000
      },
      Bhutan: {
        'North America': 50000,
        'South America': 20000,
        EMEA: 10000,
        'Asia Pacific': 30000,
        China: 5000
      },
      Maldives: {
        'North America': 700000,
        'South America': 30000,
        EMEA: 12000,
        'Asia Pacific': 20000,
        China: 60000
      },
      Australia: {
        'North America': 300000,
        'South America': 80000,
        EMEA: 20000,
        'Asia Pacific': 70000,
        China: 15000
      },
      NewZealand: {
        'North America': 280000,
        'South America': 60000,
        EMEA: 18000,
        'Asia Pacific': 60000,
        China: 12000
      },
      PacificIslands: {
        'North America': 10000,
        'South America': 30000,
        EMEA: 15000,
        'Asia Pacific': 30000,
        China: 5000
      }
    }

    // Define initial/default data
    const initialData = {
      'North America': 50000,
      'South America': 150000,
      EMEA: 20000,
      'Asia Pacific': 10000,
      China: 5000
    }

    // Determine the current sector's data
    const sectorData = data[campaignData?.country] || initialData
    const labels = Object.keys(sectorData)
    const values = Object.values(sectorData)
    // Calculate the total sum of the values
    const total = values.reduce((acc, val) => acc + val, 0)
    const colors = [
      '#007BFF', // Light Blue
      '#28A745', // Green
      '#FFC107', // Yellow
      '#DC3545', // Red
      '#6610F2', // Purple
      '#FF5733', // Orange
      '#20C997', // Teal
      '#17A2B8', // Cyan
      '#6C757D', // Gray
      '#FFB533' // Warm Peach
    ]

    // Set chart data
    setChartData({
      labels: labels,
      datasets: [
        {
          label: `${
            campaignData?.industryVertical || 'Default'
          } Sector Distribution`,
          data: values,
          backgroundColor: colors
        }
      ]
    })
    setTotal(total)
  }, [campaignData?.country])

  useEffect(() => {
    const getMarkers = campaignData => {
      const northAmericaRegions = [
        'USA',
        'Canada',
        'Mexico',
        'PuertoRico',
        'Bahamas',
        'Cuba',
        'Jamaica',
        'DominicanRepublic',
        'Belize',
        'CostaRica',
        'Panama'
      ]

      const chinaRegions = [
        'MainlandChina',
        'HongKong',
        'Macau',
        'Tibet',
        'Xinjiang'
      ]

      const emeaRegions = [
        'UK',
        'Germany',
        'France',
        'Italy',
        'Spain',
        'Russia',
        'SaudiArabia',
        'UAE',
        'Israel',
        'Turkey',
        'Qatar',
        'SouthAfrica',
        'Nigeria',
        'Egypt',
        'Kenya',
        'Morocco'
      ]

      const southAmericaCountries = [
        'Argentina',
        'Bolivia',
        'Brazil',
        'Chile',
        'Colombia',
        'Ecuador',
        'Guyana',
        'Paraguay',
        'Peru',
        'Suriname',
        'Uruguay',
        'Venezuela'
      ]

      const apacRegions = [
        'China',
        'Japan',
        'SouthKorea',
        'Taiwan',
        'Mongolia',
        'Singapore',
        'Malaysia',
        'Indonesia',
        'Philippines',
        'Thailand',
        'Vietnam',
        'Cambodia',
        'Myanmar',
        'Laos',
        'Brunei',
        'India',
        'Pakistan',
        'Bangladesh',
        'SriLanka',
        'Nepal',
        'Bhutan',
        'Maldives',
        'Australia',
        'NewZealand',
        'PacificIslands'
      ]

      // Check if campaignData.country is directly selected
      if (campaignData?.country && markers[campaignData.country]) {
        // Return the marker for the directly selected country
        return {
          [campaignData.country]: markers[campaignData.country]
        }
      }

      // Filter markers based on the selected region
      if (campaignData?.region === 'NorthAmerica') {
        return northAmericaRegions.reduce((acc, region) => {
          acc[region] = markers[region]
          return acc
        }, {})
      } else if (campaignData?.region === 'China') {
        return chinaRegions.reduce((acc, region) => {
          acc[region] = markers[region]
          return acc
        }, {})
      } else if (campaignData?.region === 'EMEA') {
        return emeaRegions.reduce((acc, region) => {
          acc[region] = markers[region]
          return acc
        }, {})
      } else if (campaignData?.region === 'SouthAmerica') {
        return southAmericaCountries.reduce((acc, country) => {
          acc[country] = markers[country]
          return acc
        }, {})
      } else if (campaignData?.region === 'APAC') {
        return apacRegions.reduce((acc, region) => {
          acc[region] = markers[region]
          return acc
        }, {})
      }

      return {}
    }

    // Update activeMarkers based on the filtered markers
    const filteredMarkers = getMarkers(campaignData)
    setActiveMarkers(
      Object.keys(filteredMarkers).map(region => ({
        name: region,
        ...filteredMarkers[region]
      }))
    )
  }, [campaignData.region, campaignData.country]) // Re-run useEffect when campaignData changes

  const doughnutLabel = {
    id: 'doughnutLabel',
    afterDatasetsDraw (chart) {
      const { ctx, data } = chart

      // Get the center coordinates of the doughnut
      const centerX = chart?._metasets[0]?.data[0]?.x - 60 // X coordinate of the center
      const centerY = chart?._metasets[0]?.data[0]?.y // Y coordinate of the center
      const total = chart?._sortedMetasets[0]?.total // Total value for the chart

      // Only draw the label if total is defined
      if (total != null) {
        // Format the total with locale string
        const formattedTotal = (total / 1000000).toFixed(3)

        ctx.save()
        ctx.font = 'bold 20px sans-serif' // Set font style
        ctx.fillStyle = '#007BFF' // Set font color

        // Measure the text width for centering
        const textWidth = ctx.measureText(formattedTotal).width
        const textX = centerX - textWidth / 2 // Center the text

        ctx.fillText(`Total: ${formattedTotal} Million`, textX, centerY) // Draw the total at the center
        ctx.restore() // Restore the context to its original state
      }
    }
  }

  const renderStepContent = step => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            {/* Doughnut Chart (Left) */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Box sx={{ width: '60%' }}>
                <Doughnut data={chartData} plugins={[doughnutLabel]} />
              </Box>

              {/* <Typography
                variant='h6'
                align='center'
                style={{ marginTop: '20px' }}
              >
                Total: {total.toLocaleString()}
              </Typography> */}
            </Grid>

            {/* Form (Right) */}
            <Grid item xs={12} md={6}>
              <Typography variant='h5'>Campaign Setup Form</Typography>
              <Typography variant='body1' color='error' gutterBottom>
                Please fill out all required fields.
              </Typography>
              <form>
                <div className='row'>
                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>Region</label>
                    <select
                      className='form-control custom-select'
                      name='region'
                      value={campaignData.region}
                      onChange={handleRegionChange}
                    >
                      {regionOptions.map(region => (
                        <option key={region.value} value={region.value}>
                          {region.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className='col-md-6 mb-3' style={{ paddingLeft: 13 }}>
                    <label style={{ paddingBottom: '5px' }}>Country</label>
                    <select
                      className='form-control custom-select'
                      name='country'
                      value={campaignData.country}
                      onChange={handleCountryChange}
                      disabled={!campaignData.region} // Disable country dropdown if no region is selected
                    >
                      <option value=''>Select Country</option>
                      {countryOptions[campaignData.region]?.map(country => (
                        <option key={country.value} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>
                      Job Function and Sub-Functions:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <select
                      className='form-control custom-select'
                      name='jobTitle.title'
                      value={campaignData.jobTitle.title}
                      onChange={handleChange}
                    >
                      <option value=''>
                        Select Job Function and Sub-Functions
                      </option>
                      {['ITDM', 'BDM', 'Both', 'Other'].map(title => (
                        <option key={title} value={title}>
                          {title}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Other Job Function */}
                  {campaignData.jobTitle.title === 'Other' && (
                    <div className='col-md-6 mb-3'>
                      <label style={{ paddingBottom: '5px' }}>
                        Other Job Function and Sub-Functions:
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        name='jobTitle.otherTitle'
                        value={campaignData.jobTitle.otherTitle}
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {/* ITDM Sub-Functions with Material-UI multiple select */}
                  {campaignData.jobTitle.title === 'ITDM' && (
                    <div className='col-md-6 mb-3'>
                      <label style={{ paddingBottom: '5px' }}>
                        Sub-Functions (for ITDM)
                      </label>
                      <FormControl fullWidth>
                        <Select
                          multiple
                          displayEmpty
                          sx={{
                            height: '40px', // Reduced height
                            '& .MuiSelect-select': {
                              height: '40px', // Reduced height for select box
                              display: 'flex',
                              alignItems: 'center'
                            },
                            '& .MuiMenuItem-root.Mui-selected': {
                              backgroundColor: '#d1e7fd' // Highlighted background for selected items
                            },
                            '& .MuiMenuItem-root:hover': {
                              backgroundColor: '#b6e0fe' // Highlight background on hover
                            }
                          }}
                          value={campaignData.jobTitle.otherTitle}
                          onChange={handleChange}
                          name='jobTitle.otherTitle'
                          renderValue={selected =>
                            selected.length === 0 ? (
                              <em>Select IT job titles</em>
                            ) : (
                              selected.join(', ')
                            )
                          }
                        >
                          {subFunctionsITDM.map(job => (
                            <MenuItem key={job} value={job}>
                              <Checkbox
                                checked={
                                  campaignData.jobTitle.otherTitle.indexOf(
                                    job
                                  ) > -1
                                }
                              />
                              <ListItemText primary={job} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}

                  {/* BDM Sub-Functions with HTML multiple select */}
                  {campaignData.jobTitle.title === 'BDM' && (
                    <div className='col-md-6 mb-3'>
                      <label style={{ paddingBottom: '5px' }}>
                        Sub-Functions (for BDM):
                      </label>
                      <FormControl fullWidth>
                        <Select
                          multiple
                          displayEmpty
                          sx={{
                            height: '40px', // Reduced height
                            '& .MuiSelect-select': {
                              height: '40px', // Reduced height for select box
                              display: 'flex',
                              alignItems: 'center'
                            },
                            '& .MuiMenuItem-root.Mui-selected': {
                              backgroundColor: '#d1e7fd' // Highlighted background for selected items
                            },
                            '& .MuiMenuItem-root:hover': {
                              backgroundColor: '#b6e0fe' // Highlight background on hover
                            }
                          }}
                          value={campaignData.jobTitle.otherTitle}
                          onChange={handleChange}
                          name='jobTitle.otherTitle'
                          renderValue={selected =>
                            selected.length === 0 ? (
                              <em>Select job titles</em>
                            ) : (
                              selected.join(', ')
                            )
                          }
                        >
                          {subFunctionsBDM.map(job => (
                            <MenuItem key={job} value={job}>
                              <Checkbox
                                checked={
                                  campaignData.jobTitle.otherTitle.indexOf(
                                    job
                                  ) > -1
                                }
                              />
                              <ListItemText primary={job} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>
                      Industry Vertical
                    </label>
                    <FormControl fullWidth>
                      <Select
                        multiple
                        displayEmpty
                        sx={{
                          height: '40px', // Reduced height
                          '& .MuiSelect-select': {
                            height: '40px', // Reduced height for select box
                            display: 'flex',
                            alignItems: 'center'
                          },
                          '& .MuiMenuItem-root.Mui-selected': {
                            backgroundColor: '#d1e7fd' // Highlighted background for selected items
                          },
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor: '#b6e0fe' // Highlight background on hover
                          }
                        }}
                        value={campaignData.industryVertical}
                        onChange={handleChange}
                        name='industryVertical'
                        renderValue={selected =>
                          selected.length === 0 ? (
                            <em>Select industry vertical</em>
                          ) : (
                            selected.join(', ')
                          )
                        }
                      >
                        {industryVerticals.map(industryVertical => (
                          <MenuItem
                            key={industryVertical}
                            value={industryVertical}
                          >
                            <Checkbox
                              checked={
                                campaignData.industryVertical.indexOf(
                                  industryVertical
                                ) > -1
                              }
                            />
                            <ListItemText primary={industryVertical} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  {/* <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>Job Level</label>
                    <select
                      className='form-control custom-select'
                      name='jobLevel'
                      value={campaignData.jobLevel}
                      onChange={handleChange}
                    >
                      <option value=''>Select Job Level</option>
                      {[
                        'Chief Information Officer',
                        'Chief Technology Officer',
                        'Chief Information Security Officer',
                        'Chief Digital Officer',
                        'Head IT',
                        'Director IT',
                        'Vice President IT',
                        'Chief Marketing Officer',
                        'Marketing Head',
                        'Sales Head',
                        'Sales Manager',
                        'Marketing Manager',
                        'IT Manager',
                        'Field Marketing Manager',
                        'Demand Generation Manager',
                        'Business Head',
                        'Business Manager',
                        'Chief Growth Officer',
                        'Chief Strategy Officer',
                        'Director Marketing',
                        'Vice President Marketing',
                        'Vice President',
                        'Director',
                        'CEO',
                        'Founder',
                        'Owner',
                        'Chief Financial Officer',
                        'HR Head',
                        'CHRO',
                        'Manager',
                        'Chief Revenue Officer',
                        'AVP',
                        'Other'
                      ].map(level => (
                        <option key={level} value={level}>
                          {level}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>
                      Company Revenue
                    </label>
                    <select
                      className='form-control custom-select'
                      name='companyRevenue'
                      value={campaignData.companyRevenue}
                      onChange={handleChange}
                    >
                      <option value=''>Select Company Revenue</option>
                      {[
                        'Below $50 Million',
                        '$50 Million - $100 Million',
                        '$101 Million - $150 Million'
                      ].map(revenue => (
                        <option key={revenue} value={revenue}>
                          {revenue}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </form>
            </Grid>
          </Grid>
        )
      case 1:
        return (
          <Grid container spacing={2}>
            {/* Left Side (Map) */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start'
              }}
            >
              <ComposableMap
                projectionConfig={{ scale: 200 }} // Adjust the scale as needed
                width={1000}
                height={500}
                style={{
                  width: '70%',
                  height: 'auto'
                }}
              >
                <Geographies geography='/world-map.json'>
                  {({ geographies }) =>
                    geographies.map(geo => (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        style={{
                          default: {
                            fill: '#D6D6DA',
                            stroke: '#FFFFFF',
                            strokeWidth: 0.75
                          },
                          hover: {
                            fill: 'rgba(30, 144, 255,0.3)',
                            stroke: '#FFFFFF',
                            strokeWidth: 1
                          },
                          pressed: {
                            fill: 'rgba(30, 144, 255,0.8)',
                            stroke: '#FFFFFF',
                            strokeWidth: 1
                          }
                        }}
                      />
                    ))
                  }
                </Geographies>

                {/* Render markers dynamically with different radii */}
                {activeMarkers.map(({ name, coordinates, radius }) => (
                  <Marker key={name} coordinates={coordinates}>
                    <circle r={radius} fill='rgba(30, 144, 255,0.3)' />
                    <text
                      textAnchor='middle'
                      y={-10}
                      style={{ fontSize: '10px', fill: '#5D5A6D' }}
                    >
                      {name}
                    </text>
                  </Marker>
                ))}
              </ComposableMap>
            </Grid>

            {/* Right Side (Form Content) */}
            <Grid item xs={12} md={6}>
              <Typography variant='h5'>Campaign Setup Form</Typography>
              <form>
                <div className='row'>
                  {/* Organization Size */}
                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>
                      Company Size:<span style={{ color: 'red' }}>*</span>
                    </label>
                    <select
                      className='form-control custom-select'
                      name='organizationSize'
                      value={campaignData.organizationSize}
                      onChange={handleChange}
                    >
                      <option value=''>Select Company Size</option>
                      {[
                        '2-10',
                        '11-50',
                        '201-500',
                        '501-1000',
                        '1001-5000',
                        '5001-10,000',
                        '10,001 & Above'
                      ].map(size => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                    {renderError('organizationSize')}
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '2px', display: 'block' }}>
                      Funnel: <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      {enumValues.funnel.map(funnel => (
                        <div
                          key={funnel}
                          className='form-check'
                          style={{
                            display: 'inline-flex', // Use inline-flex for aligning items in a row
                            alignItems: 'center', // Align items vertically in center
                            marginRight: '10px',
                            marginBottom: '10px',
                            padding: '10px 30px',
                            borderRadius: '10px',
                            border: `2px solid ${
                              campaignData.funnel.includes(funnel)
                                ? '#55B56D'
                                : '#E4E4E4'
                            }`
                          }}
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='funnel'
                            value={funnel}
                            checked={campaignData.funnel.includes(funnel)}
                            onChange={handleChange}
                            style={{
                              marginRight: '5px',
                              borderRadius: '50%',
                              backgroundColor: campaignData.funnel.includes(
                                funnel
                              )
                                ? '#55B56D'
                                : '',
                              borderColor: campaignData.funnel.includes(funnel)
                                ? '#55B56D'
                                : '',
                              cursor: 'pointer'
                            }}
                          />
                          <label
                            className='form-check-label'
                            style={{ margin: '0' }}
                          >
                            {funnel}
                          </label>
                        </div>
                      ))}
                    </div>
                    {renderError('funnel')}
                  </div>

                  {/* Objective */}
                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>
                      Objective:<span style={{ color: 'red' }}>*</span>
                    </label>
                    <select
                      className='form-control custom-select'
                      name='objective'
                      value={campaignData.objective}
                      onChange={handleChange}
                    >
                      <option value=''>Select Objective</option>
                      {enumValues.objectives.map(objective => (
                        <option key={objective} value={objective}>
                          {objective}
                        </option>
                      ))}
                    </select>
                    {renderError('objective')}
                  </div>

                  {/* Report Frequency */}
                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>
                      Report Frequency:
                    </label>
                    <select
                      className='form-control custom-select'
                      name='reportFrequency'
                      value={campaignData.reportFrequency}
                      onChange={handleChange}
                    >
                      <option value=''>Select Report Frequency</option>
                      {enumValues.reportFrequency.map(frequency => (
                        <option key={frequency} value={frequency}>
                          {frequency}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* TAL */}
                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>TAL :</label>
                    <select
                      className='form-control custom-select'
                      name='tal'
                      value={campaignData.tal}
                      onChange={handleChange}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>

                  {/* Additional Rows */}
                  {/* Location */}
                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>
                      Campaign Location:
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='location'
                      value={campaignData.location}
                      onChange={handleChange}
                    />
                    {renderError('location')}
                  </div>

                  {/* ABM */}
                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>ABM:</label>
                    <select
                      className='form-control custom-select'
                      name='abm'
                      value={campaignData.abm}
                      onChange={handleChange}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                  {/* Campaign Timeline */}
                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>
                      Campaign Timeline:
                    </label>
                    <select
                      className='form-control custom-select'
                      name='campaignTimeline'
                      value={campaignData.campaignTimeline}
                      onChange={handleChange}
                    >
                      <option value={''}>Select a Timeline</option>
                      <option value={20}>20 days</option>
                      <option value={45}>45 days</option>
                      <option value={50}>50 days</option>
                      <option value={90}>1 Quarter</option>
                    </select>
                  </div>

                  {/* Suggest Tactics */}
                  <div className='col-md-6 mb-3'>
                    <label style={{ paddingBottom: '5px' }}>
                      Suggest Tactics:
                    </label>
                    <select
                      className='form-control custom-select'
                      name='tactics.suggest'
                      value={campaignData.tactics.suggest.toString()}
                      onChange={handleChange}
                    >
                      <option value={true}>Auto</option>
                      <option value={false}>I would like to Suggest</option>
                    </select>
                  </div>
                  {campaignData.tactics.suggest === 'false' && (
                    <div className='col-md-6 mb-3'>
                      <label style={{ paddingBottom: '5px' }}>
                        Tactics Type:
                      </label>
                      <select
                        className='form-control'
                        name='tactics.type'
                        value={campaignData.tactics.type}
                        onChange={handleChange}
                      >
                        <option value=''>Select Tactics Type</option>
                        {enumValues.tactics.default.map(tactic => (
                          <option key={tactic} value={tactic}>
                            {tactic}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className='col-md-6'>
                    <label style={{ paddingBottom: '5px' }}>
                      Campaign Additional filter
                    </label>
                    <select
                      className='form-control custom-select'
                      name='additionalFilterEnabled'
                      value={campaignData.additionalFilterEnabled}
                      onChange={handleAdditionalFilterChange}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {campaignData.additionalFilterEnabled && (
                      <div className='mb-3'>
                        <label style={{ paddingBottom: '5px' }}>Filters:</label>
                        {campaignData.filters.map((filter, index) => (
                          <div key={index} className='input-group mb-3'>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Enter filter'
                              value={filter}
                              onChange={e =>
                                handleFiltersChange(index, e.target.value)
                              }
                            />
                            <div className='input-group-append'>
                              <button
                                className='btn btn-outline-danger'
                                type='button'
                                onClick={() => removeFilterInput(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                        <button
                          className='btn btn-primary'
                          type='button'
                          onClick={addFilterInput}
                        >
                          Add Filter
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </Grid>
          </Grid>
        )
      case 2:
        return (
          <Grid container spacing={2}>
            {/* Doughnut Chart (Left) */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Box sx={{ width: '60%' }}>
                <Doughnut data={chartData} plugins={[doughnutLabel]} />
              </Box>

              {/* <Typography
                variant='h6'
                align='center'
                style={{ marginTop: '20px' }}
              >
                Total: {total.toLocaleString()}
              </Typography> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <div className='row'>
                <div className='col-md-6 mb-3'>
                  <label style={{ paddingBottom: '5px' }}>Lead Category</label>
                  <select
                    className='form-control custom-select'
                    name='leadCategory'
                    value={campaignData.leadCategory}
                    onChange={handleChange}
                  >
                    <option value=''>Select Lead Category</option>
                    <option value='FormFills'>Form Fills</option>
                    <option value='BANT'>BANT</option>
                    <option value='AppointmentSetting'>
                      Appointment Setting
                    </option>
                    <option value='TriangularCall'>Triangular call</option>
                  </select>
                </div>

                <div className='col-md-6 mb-3'>
                  <label style={{ paddingBottom: '5px' }}>Quantity</label>
                  <select
                    className='form-control custom-select'
                    name='quantity'
                    value={campaignData.quantity} // Replace with the appropriate state variable
                    onChange={handleChange} // Ensure this function handles the change event
                  >
                    <option value=''>Select Quantity</option>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                    <option value='6'>6</option>
                    <option value='7'>7</option>
                    <option value='8'>8</option>
                    <option value='9'>9</option>
                    <option value='10'>10</option>
                  </select>
                </div>
              </div>
              {/* 
              <div className='col-md-6 mb-3'>
                <label style={{ paddingBottom: '5px' }}>TAL:</label>
                <select
                  className='form-control custom-select'
                  name='tal2'
                  value={campaignData.tal2}
                  onChange={handleChange}
                >
                  <option value=''>Select an option</option>
                  <option value='yes'>Yes</option>
                  <option value='no'>No</option>
                </select>
              </div> */}

              <div className='form-group'>
                <input
                  type='checkbox'
                  name='talCheck'
                  checked={campaignData.talCheck}
                  onChange={handleCheckboxChange}
                />
                <label>
                  In case of TAL is Yes the system should calculate 25% extra
                </label>
              </div>

              <div className='col-md-12 p-3 d-flex justify-content-end'>
                <div
                  className='p-3'
                  style={{
                    backgroundColor: '#F8F8F8',
                    width: '25%',
                    borderRadius: 15
                  }}
                >
                  <div className='d-flex justify-content-between mb-3'>
                    <span className='font-weight-bold text-secondary'>
                      Subtotal:
                    </span>
                    <span>
                      {getCurrencySymbol()}
                      {campaignData.subTotal}
                    </span>
                  </div>
                  {campaignData.quantity &&
                    campaignData.leadCategory != '' &&
                    campaignData.region != '' > 1 && (
                      <div className='d-flex justify-content-between mb-3'>
                        <span className='font-weight-bold text-secondary'>
                          Quantity:
                        </span>
                        <span>
                          {getCurrencySymbol()}
                          {campaignData.subTotal} x {campaignData.quantity}
                        </span>
                      </div>
                    )}
                  {/* <div className='d-flex justify-content-between mb-3'>
                  <span className='font-weight-bold text-secondary'>Tax:</span>
                  <span>
                    {getCurrencySymbol()}
                    {campaignData.tax}
                  </span>
                </div> */}
                  <div className='d-flex justify-content-between'>
                    <span className='font-weight-bold text-secondary'>
                      Total Amount:
                    </span>
                    <span className='font-weight-bold text-primary'>
                      {getCurrencySymbol()}
                      {campaignData.total}
                    </span>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div style={{ flex: '1' }}></div>
                <button
                  type='submit'
                  style={{
                    backgroundColor: '#55B56D',
                    borderColor: '#007bff',
                    marginBottom: '10px',
                    marginRight: '17px',
                    padding: '10px 24px',
                    borderRadius: '10px',
                    color: '#fff',
                    fontWeight: '600',
                    border: 'none'
                  }}
                  onClick={handleSubmit}
                >
                  Save & Continue
                </button>
              </div>
            </Grid>
          </Grid>
        )
      default:
        return 'Unknown Step'
    }
  }

  return (
    <Box sx={{ width: '100%', overflowX: 'hidden' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              sx={{
                fontSize: '1.5rem', // Increase label text size
                color: activeStep === index ? 'green' : 'inherit', // Green for active step, inherit for others
                '& .MuiStepIcon-root': {
                  fontSize: '2.5rem', // Increase icon size
                  color: activeStep === index ? 'green' : 'gray' // Green for active step icon, gray for others
                }
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ marginTop: '20px' }}>
        {errorMessage && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
              className='alert alert-danger'
              role='alert'
              style={{ width: '50%' }}
            >
              {errorMessage}
            </div>
          </div>
        )}
        {renderStepContent(activeStep)}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 5,
            justifyContent: 'space-between'
          }}
        >
          <Box width={'50%'}></Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '50%'
            }}
          >
            <Button
              variant='outlined'
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{
                mr: 1,
                backgroundColor: '#55B56D',
                borderColor: '#007bff',
                marginBottom: '10px',
                padding: '10px 24px',
                borderRadius: '5px',
                color: '#fff', // Default text color
                fontWeight: '600',
                border: 'none',
                '&:hover': {
                  color: '#000' // Change text color to black on hover
                }
              }}
            >
              Back
            </Button>

            <Button
              onClick={handleNext}
              variant='outlined'
              sx={{
                backgroundColor: '#55B56D',
                borderColor: '#007bff',
                marginBottom: '10px',
                padding: '10px 24px',
                borderRadius: '5px',
                color: '#fff', // Default text color
                fontWeight: '600',
                border: 'none',
                display: activeStep === steps.length - 1 ? 'none' : 'block',
                '&:hover': {
                  color: '#000' // Change text color to black on hover
                }
              }}
            >
              {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CampaignSetupForm
