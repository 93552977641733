import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import upload from "../../assets/images/upload-icon.jpg"
const UpdateCampaign = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newdandListItem, setNewdandListItem] = useState("");
  const [newIndustriesItem, setNewIndustriesItem] = useState("");
  const [newTalWishListItem, setNewTalWishListItem] = useState("");
  const [newCustomQuestionItem, setNewCustomQuestionItem] = useState("");
  const [campaignData, setCampaignData] = useState({
    campaignName: "",
    marketer: "",
    mediaPartner: "",
    payout: "",
    startDate: "",
    endDate: "",
    status: "",
    accepted: "",
    rejected: "",
    submitted: "",
    webinarTotalRegistration: "",
    webinarAttendee: "",
    TopOfFunnel: "",
    MiddleOfFunnel: "",
    BottomOfFunnel: "",
    audienceTargeted: "",
    EngagedAudience: "",
    bannerImpression: "",
    bannerClicks: "",
    edmCtr: "",
    assets: "",
    region: "",
    jobTitle: "",
    installationBase: "",
    industries: [],
    talWishList: [],
    dandList: [],
    companySize: "",
    customQuestion: [],
    productSolution: "",
    contactPerson: "",
    POC: "",
    manager: "",
    campaignLearningAndRecommendation: "",
    manageBy: "",
    image: null,
    campaignLogo: null,
    bannerImage: null,
    edmTargetedEmailsImage: null,
    newsletterImage: null,
    micrositeImage: null,
    socialMediaPromotionImage: null,
    oneToOneChatSeriesImage: null,
    appointmentSettingImage: null,
    webinarImage: null,
    roundTableImage: null,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const campaignId = location.pathname.split("/").pop(); // Extract campaign ID

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const response = await axios.get(
          `/compaign/getCampaignById/${campaignId}`
        );

        const campaignDataFromApi = response.data;

        // Update campaignData state to include API image URLs
        const updatedCampaignData = { ...campaignDataFromApi };
        for (const key in updatedCampaignData) {
          if (
            typeof updatedCampaignData[key] === "string" &&
            key.endsWith("Image")
          ) {
            updatedCampaignData[key] = updatedCampaignData[key].replace(
              /\\/g,
              "/"
            );
          }
        }

        // Extract and format date fields
        const formattedStartDate = new Date(campaignDataFromApi.startDate)
          .toISOString()
          .split("T")[0]; // Format 'yyyy-MM-dd'
        const formattedEndDate = new Date(campaignDataFromApi.endDate)
          .toISOString()
          .split("T")[0]; // Format 'yyyy-MM-dd'

        // Update campaignData state with API data and formatted dates
        setCampaignData({
          ...updatedCampaignData,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });

        // Split arrays only if they exist and have items
        if (
          campaignDataFromApi.dandList &&
          campaignDataFromApi.dandList.length > 0
        ) {
          const dandListArray = campaignDataFromApi.dandList[0].split(",");
          setCampaignData((prevCampaignData) => ({
            ...prevCampaignData,
            dandList: dandListArray,
          }));
        }

        if (
          campaignDataFromApi.talWishList &&
          campaignDataFromApi.talWishList.length > 0
        ) {
          const talWishListArray =
            campaignDataFromApi.talWishList[0].split(",");
          setCampaignData((prevCampaignData) => ({
            ...prevCampaignData,
            talWishList: talWishListArray,
          }));
        }

        if (
          campaignDataFromApi.customQuestion &&
          campaignDataFromApi.customQuestion.length > 0
        ) {
          const customQuestionArray =
            campaignDataFromApi.customQuestion[0].split(",");
          setCampaignData((prevCampaignData) => ({
            ...prevCampaignData,
            customQuestion: customQuestionArray,
          }));
        }

        if (
          campaignDataFromApi.industries &&
          campaignDataFromApi.industries.length > 0
        ) {
          const industriesArray = campaignDataFromApi.industries[0].split(",");
          setCampaignData((prevCampaignData) => ({
            ...prevCampaignData,
            industries: industriesArray,
          }));
        }

        // Handle other fields similarly...
      } catch (error) {
        console.error("Error fetching campaign data:", error.message);
        if (error.response && error.response.status === 404) {
          setError("Campaign not found");
        } else {
          setError(error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchCampaignData();
  }, [campaignId]);

  const handleAddItem = (fieldName) => {
    let newItem = "";
    switch (fieldName) {
      case "dandList":
        newItem = newdandListItem.trim();
        setNewdandListItem("");
        break;
      case "industries":
        newItem = newIndustriesItem.trim();
        setNewIndustriesItem("");
        break;
      case "talWishList":
        newItem = newTalWishListItem.trim();
        setNewTalWishListItem("");
        break;
      case "customQuestion":
        newItem = newCustomQuestionItem.trim();
        setNewCustomQuestionItem("");
        break;
      default:
        break;
    }

    if (newItem !== "") {
      setCampaignData((prevCampaignData) => ({
        ...prevCampaignData,
        [fieldName]: [...prevCampaignData[fieldName], newItem],
      }));
    }
  };

  const handleRemoveItem = (fieldName, index) => {
    setCampaignData((prevCampaignData) => ({
      ...prevCampaignData,
      [fieldName]: prevCampaignData[fieldName].filter(
        (_, idx) => idx !== index
      ),
    }));
  };

  const handleFileChange = (event, fieldName) => {
    const file = event.target.files[0];

    // Update only the specific image field in campaignData
    setCampaignData((prevData) => ({
      ...prevData,
      [fieldName]: file,
      [`${fieldName}Preview`]: URL.createObjectURL(file),
    }));
  };

  const renderFileInput = (name, label) => (
    <div className="form-group">
      <label>{label}</label>
      <div
        className="file-input-wrapper"
        style={{
          border: "2px dashed #0A58CA",
          padding: "10px",
          borderRadius: "12px",
          display: "flex",
          alignItems: "center",
          marginBottom: "10px", // Adjust margin as needed
        }}
      >
        <div>
          <img src={upload} alt="Upload" style={{ width: "25px", height: "25px" }} />
        </div>
        <input
          type="file"
          name={name}
          onChange={(event) => handleFileChange(event, name)}
          className="custom-file-input"
          id={name}
          accept="image/*"
          style={{ display: "none" }} // Hide the actual file input
        />
        <button
          className="btn btn-success ml-auto"
          style={{ marginLeft: "auto" }}
          onClick={(e) => {
            e.preventDefault();
            document.getElementById(name).click();
          }}
        >
          Browse
        </button>
      </div>
      {/* Display image preview */}
      {campaignData[`${name}Preview`] && (
        <img
          src={campaignData[`${name}Preview`]}
          alt="Preview"
          className="mt-2 mb-2"
          style={{ maxWidth: "60%", height: "auto" }}
        />
      )}
    </div>
  );
  

  const renderImage = (imageUrl, altText) => {
    // Define your base URL here
    const baseURL = `${process.env.REACT_APP_API_URL}/`;

    // Conditionally render nothing if imageUrl is not provided or is not a string
    if (!imageUrl || typeof imageUrl !== "string") {
      return null;
    }

    // Check if imageUrl contains the unwanted substring
    if (imageUrl.includes && imageUrl.includes("[object File]")) {
      return null;
    }

    // Construct the full image URL by concatenating the base URL with the provided imageUrl
    const fullUrl = `${baseURL}${imageUrl}`;

    return (
      <div
        className="image-container"
        style={{ width: "300px", height: "200px" }}
      >
        <img
          src={fullUrl}
          alt={altText}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </div>
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();

      // Append all fields from campaignData to formData
      for (const key in campaignData) {
        if (campaignData[key] !== null) {
          formData.append(key, campaignData[key]);
        }
      }

      await axios.put(
        `/compaign/updateCampaignForUser/${campaignData.user}/campaigns/${campaignId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      navigate(-1); // Navigate back to the previous page
    } catch (error) {
      console.error("Error updating campaign:", error);
      // Handle error appropriately
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCampaignData({ ...campaignData, [name]: value });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="card">
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="forms-sample"
      >
        <div className="card-body">
          <h4 className="card-title">Update Campaign</h4>

          <div className="row">
            {/* Campaign Name */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="campaignName">Campaign Name</label>
              <input
                type="text"
                id="campaignName"
                name="campaignName"
                value={campaignData.campaignName}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Marketer */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="marketer">Marketer</label>
              <input
                type="text"
                id="marketer"
                name="marketer"
                value={campaignData.marketer}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Media Partner */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="mediaPartner">Media Partner</label>
              <input
                type="text"
                id="mediaPartner"
                name="mediaPartner"
                value={campaignData.mediaPartner}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Start Date */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={campaignData.startDate}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* End Date */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="endDate">End Date</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={campaignData.endDate}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Status */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="status">Status</label>
              <select
                id="status"
                name="status"
                value={campaignData.status}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="live">Live</option>
                <option value="pending">Pending</option>
                <option value="complete">Complete</option>
              </select>
            </div>
            {/* Accepted */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="accepted">Accepted</label>
              <input
                type="text"
                id="accepted"
                name="accepted"
                value={campaignData.accepted}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Rejected */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="rejected">Rejected</label>
              <input
                type="text"
                id="rejected"
                name="rejected"
                value={campaignData.rejected}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Submitted */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="submitted">Target</label>
              <input
                type="text"
                id="submitted"
                name="submitted"
                value={campaignData.submitted}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>{" "}
            {/* Webinar Total Registration */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="webinarTotalRegistration">
                Webinar Total Registration
              </label>
              <input
                type="number"
                id="webinarTotalRegistration"
                name="webinarTotalRegistration"
                value={campaignData.webinarTotalRegistration}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Webinar Attendee */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="webinarAttendee">Webinar Attendee</label>
              <input
                type="number"
                id="webinarAttendee"
                name="webinarAttendee"
                value={campaignData.webinarAttendee}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="  col-md-4 mb-3">
              <label htmlFor="leadGeneration">leadGeneration</label>
              <select
                id="leadGeneration"
                name="leadGeneration"
                value={campaignData.leadGeneration}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="NQL">NQL</option>
                <option value="BANT">BANT</option>
                <option value="AppointmentSetting">AppointmentSetting</option>
              </select>
            </div>
            {/* Top of Funnel */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="TopOfFunnel">Top of Funnel</label>
              <input
                type="number"
                id="TopOfFunnel"
                name="TopOfFunnel"
                value={campaignData.TopOfFunnel}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Middle of Funnel */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="MiddleOfFunnel">Middle of Funnel</label>
              <input
                type="number"
                id="MiddleOfFunnel"
                name="MiddleOfFunnel"
                value={campaignData.MiddleOfFunnel}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Bottom of Funnel */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="BottomOfFunnel">Bottom of Funnel</label>
              <input
                type="number"
                id="BottomOfFunnel"
                name="BottomOfFunnel"
                value={campaignData.BottomOfFunnel}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Audience Targeted */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="audienceTargeted">Audience Targeted</label>
              <input
                type="number"
                id="audienceTargeted"
                name="audienceTargeted"
                value={campaignData.audienceTargeted}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Engaged Audience */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="EngagedAudience">Engaged Audience</label>
              <input
                type="number"
                id="EngagedAudience"
                name="EngagedAudience"
                value={campaignData.EngagedAudience}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Banner Impression */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="bannerImpression">Banner Impression</label>
              <input
                type="number"
                id="bannerImpression"
                name="bannerImpression"
                value={campaignData.bannerImpression}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Banner Clicks */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="bannerClicks">Banner Clicks</label>
              <input
                type="number"
                id="bannerClicks"
                name="bannerClicks"
                value={campaignData.bannerClicks}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* EDM CTR */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="edmCtr">EDM CTR</label>
              <input
                type="number"
                id="edmCtr"
                name="edmCtr"
                value={campaignData.edmCtr}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Assets */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="assets">Assets</label>
              <input
                type="number"
                id="assets"
                name="assets"
                value={campaignData.assets}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Region */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="region">Region</label>
              <input
                type="text"
                id="region"
                name="region"
                value={campaignData.region}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Job Title */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="jobTitle">Job Title</label>
              <input
                type="text"
                id="jobTitle"
                name="jobTitle"
                value={campaignData.jobTitle}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Installation Base */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="installationBase">Installation Base</label>
              <input
                type="text"
                id="installationBase"
                name="installationBase"
                value={campaignData.installationBase}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Industries:</label>
              <ul className="list-group">
                {campaignData.industries.map((item, index) => (
                  <li key={index} className="list-group-item">
                    {item}
                    <button
                      type="button"
                      style={{ marginLeft: "10px" }} //
                      className="btn btn-sm btn-danger float-right mr-2"
                      onClick={() => handleRemoveItem("industries", index)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
                <li className="list-group-item">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={newIndustriesItem}
                      onChange={(e) => setNewIndustriesItem(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleAddItem("industries")}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* Render TAL Wish List */}
            <div className="form-group">
              <label>TAL Wish List:</label>
              <ul className="list-group">
                {campaignData.talWishList.map((item, index) => (
                  <li key={index} className="list-group-item ">
                    {item}
                    <button
                      type="button"
                      style={{ marginLeft: "10px" }} //
                      className="btn btn-sm btn-danger float-right mr-2"
                      onClick={() => handleRemoveItem("talWishList", index)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
                <li className="list-group-item ">
                  <div className="input-group ">
                    <input
                      type="text"
                      className="form-control"
                      value={newTalWishListItem}
                      onChange={(e) => setNewTalWishListItem(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        style={{ marginLeft: "10px" }} //
                        className="btn btn-primary"
                        onClick={() => handleAddItem("talWishList")}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="form-group">
              <label>Decision & Decision List:</label>
              <ul className="list-group">
                {campaignData.dandList.map((item, index) => (
                  <li key={index} className="list-group-item">
                    {item}
                    <button
                      type="button"
                      style={{ marginLeft: "10px" }} //
                      className="btn btn-sm btn-danger float-right mr-2"
                      onClick={() => handleRemoveItem("dandList", index)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
                <li className="list-group-item">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={newdandListItem}
                      onChange={(e) => setNewdandListItem(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleAddItem("dandList")}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* Company Size */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="companySize">Company Size</label>
              <input
                type="text"
                id="companySize"
                name="companySize"
                value={campaignData.companySize}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Render Custom Question */}
            <div className="form-group">
              <label>Custom Question:</label>
              <ul className="list-group">
                {campaignData.customQuestion.map((item, index) => (
                  <li key={index} className="list-group-item ">
                    {item}
                    <button
                      type="button"
                      style={{ marginLeft: "10px" }} //
                      className="btn btn-sm btn-danger float-right mr-2"
                      onClick={() => handleRemoveItem("customQuestion", index)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
                <li className="list-group-item">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={newCustomQuestionItem}
                      onChange={(e) => setNewCustomQuestionItem(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleAddItem("customQuestion")}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* Product Solution */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="productSolution">Product Solution</label>
              <input
                type="text"
                id="productSolution"
                name="productSolution"
                value={campaignData.productSolution}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Contact Person */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="contactPerson">Contact Person</label>
              <input
                type="text"
                id="contactPerson"
                name="contactPerson"
                value={campaignData.contactPerson}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Point of Contact (POC) */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="POC">POC</label>
              <input
                type="text"
                id="POC"
                name="POC"
                value={campaignData.POC}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Manager */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="manager">Manager</label>
              <input
                type="text"
                id="manager"
                name="manager"
                value={campaignData.manager}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Campaign Learning & Recommendation */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="campaignLearningAndRecommendation">
                Campaign Learning & Recommendation
              </label>
              <input
                type="text"
                id="campaignLearningAndRecommendation"
                name="campaignLearningAndRecommendation"
                value={campaignData.campaignLearningAndRecommendation}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* Managed By */}
            <div className="  col-md-4 mb-3">
              <label htmlFor="manageBy">Managed By</label>
              <input
                type="text"
                id="manageBy"
                name="manageBy"
                value={campaignData.manageBy}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="col-md-4 mb-3">
              {renderFileInput("image", "Image")}
              {renderImage(campaignData.image, "image")}
            </div>
            <div className="col-md-4 mb-3">
              {renderFileInput("campaignLogo", "Campaign Logo")}
              {renderImage(campaignData.campaignLogo, "campaignLogo")}
            </div>
            <div className="col-md-4 mb-3">
              {renderFileInput("bannerImage", "Banner Image")}
              {renderImage(campaignData.bannerImage, "Banner Image")}
            </div>
            <div className="col-md-4 mb-3">
              {renderFileInput(
                "edmTargetedEmailsImage",
                "EDM Targeted Emails Image"
              )}
              {renderImage(
                campaignData.edmTargetedEmailsImage,
                "EDM Targeted Emails Image"
              )}
            </div>
            <div className="col-md-4 mb-3">
              {renderFileInput("newsletterImage", "Newsletter Image")}
              {renderImage(campaignData.newsletterImage, "Newsletter Image")}
            </div>
            <div className="col-md-4 mb-3">
              {renderFileInput("micrositeImage", "Microsite Image")}
              {renderImage(campaignData.micrositeImage, "Microsite Image")}
            </div>
            <div className="col-md-4 mb-3">
              {renderFileInput(
                "socialMediaPromotionImage",
                "Social Media Promotion Image"
              )}
              {renderImage(
                campaignData.socialMediaPromotionImage,
                "Social Media Promotion Image"
              )}
            </div>
            <div className="col-md-4 mb-3">
              {renderFileInput(
                "oneToOneChatSeriesImage",
                "One to One Chat Series Image"
              )}
              {renderImage(
                campaignData.oneToOneChatSeriesImage,
                "One-to-One Chat Series Image"
              )}
            </div>
            <div className="col-md-4 mb-3">
              {renderFileInput(
                "appointmentSettingImage",
                "Appointment Setting Image"
              )}
              {renderImage(
                campaignData.appointmentSettingImage,
                "Appointment Setting Image"
              )}
            </div>
            <div className="col-md-4 mb-3">
              {renderFileInput("webinarImage", "Webinar Image")}
              {renderImage(campaignData.webinarImage, "Webinar Image")}
            </div>
            <div className="col-md-4 mb-3">
              {renderFileInput("roundTableImage", "Round Table Image")}
              {renderImage(campaignData.roundTableImage, "Round Table Image")}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div style={{ flex: "1" }}></div>
            <button
              type="submit"
              name="submit"
              value="continue"
              style={{
                backgroundColor: "#55B56D",
                borderColor: "#007bff",
                marginBottom: "10px",
                padding: "10px 24px",
                borderRadius: "10px",
                color: "#fff",
                fontWeight: "600",
                border: "none",
              }}
            >
            
                Update Campaign
           
            </button>
          </div>

       
        </div>
      </form>
    </div>
  );
};

export default UpdateCampaign;
