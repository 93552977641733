import http from "./httpService";

const apiEndpoint =  "/team/";

function authUrl(id) {
  return `${apiEndpoint}${id}`;
}

// Get the Teams Data

export async function getTeams() {
  return await http.get(apiEndpoint);
}

export async function getTeam(id) {
  return http.put(authUrl(id));
}

//  Create and Update Team

export function saveTeams(team) {
  return http.post(apiEndpoint, team);
}

// Update the status of the Team Data

export async function statusTeam(id, status) {
  return http.post(authUrl(id), { status: status });
}

// Delete the Team Data

export async function deleteTeam(id) {
  return http.delete(authUrl(id));
}
