import jwtDecode from "jwt-decode";
import http from "./httpService";

const tokenKey = "token";

http.setJwt(getJwt());

export async function login(endpoint, formData) {
  const response = await http.post(endpoint, formData);
  const { data } = response;
  localStorage.setItem(tokenKey, data.token);
  sessionStorage.setItem("user", JSON.stringify(data.user)); // Store user data in sessionStorage
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  sessionStorage.removeItem("user"); // Remove user data from sessionStorage on logout
  sessionStorage.removeItem("otp");
  sessionStorage.removeItem("signupData");
}

export function getCurrentUser() {
  try {
    const jwt = sessionStorage.getItem("user"); // Retrieve user data from sessionStorage
    return JSON.parse(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};
