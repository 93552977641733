import React from 'react'

const Blog = ({ funcNav, funcFooter }) => {
    funcNav(true);
    funcFooter(true);
  return (
    <>
    <section className="blog-banner">
	<div className="container-fluid">
		<div className="col-md-12">
			<div className="blog-banners" data-aos="fade-right">
				<h2>Why lead campaign is important</h2>
				<a className="btn-blg" href="#">Learn More</a>
				
			</div>
		</div>
	</div>
</section>
<section className="universities latest">
	<div className="container">
		<div className="row">
			<div className="col-md-9 pe-4">
				<div className="latest-title">
					<h3>Latest Articles</h3>
				</div>
				<div className="row">
					<div className="col-md-6 col-sm-12 ">
						<div className="latest-article" data-aos="zoom-in-down">
							<img src=""/>
							<h3>The Must-Know Digital</h3>
							<p>The importance of digital transformation continues to be one of the most important priorities in organizations, especially so after the</p>
							<div className="reads-latest">
								<a href="#">Learn More</a>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12">
						<div className="latest-article" data-aos="zoom-in-down">
							<img src="<?php echo base_url();?>images/blog-list-2.png"/>
							<h3>The Must-Know Digital</h3>
							<p>The importance of digital transformation continues to be one of the most important priorities in organizations, especially so after the</p>
							<div className="reads-latest">
								<a href="#">Learn More</a>
							</div>
						</div>
					</div>
					
					<div className="col-md-6 col-sm-12">
						<div className="latest-article" data-aos="zoom-in-down">
							<img src="<?php echo base_url();?>images/blog-list-2.png"/>
							<h3>The Must-Know Digital</h3>
							<p>The importance of digital transformation continues to be one of the most important priorities in organizations, especially so after the</p>
							<div className="reads-latest">
								<a href="#">Learn More</a>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12">
						<div className="latest-article" data-aos="zoom-in-down">
							<img src="<?php echo base_url();?>images/blog-list-2.png"/>
							<h3>The Must-Know Digital</h3>
							<p>The importance of digital transformation continues to be one of the most important priorities in organizations, especially so after the</p>
							<div className="reads-latest">
								<a href="#">Learn More</a>
							</div>
						</div>
					</div>
					
					<div className="col-md-6 col-sm-12">
						<div className="latest-article" data-aos="zoom-in-down">
							<img src="<?php echo base_url();?>images/blog-list-2.png"/>
							<h3>The Must-Know Digital</h3>
							<p>The importance of digital transformation continues to be one of the most important priorities in organizations, especially so after the</p>
							<div className="reads-latest">
								<a href="#">Learn More</a>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12">
						<div className="latest-article" data-aos="zoom-in-down">
							<img src="<?php echo base_url();?>images/blog-list-2.png"/>
							<h3>The Must-Know Digital</h3>
							<p>The importance of digital transformation continues to be one of the most important priorities in organizations, especially so after the</p>
							<div className="reads-latest">
								<a href="#">Learn More</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="col-md-3 ps-3">
				<div className="universities-right universities-right-blg">
						
						<div className="input-group mb-3 serach-btn" data-aos="zoom-in-left">
							<input type="text" className="form-control" placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
							<span className="input-group-text" id="basic-addon2"><i className="fa fa-search" aria-hidden="true"></i></span>
						</div>
						
						<div className="categories" data-aos="zoom-in-left">
							<h3>Categories</h3>
							<ul>
								<li>Fitness<span>22</span></li>
								<li>Gym<span>05</span></li>
								<li>Diet <span>12</span></li>
								<li>Yoga <span>16</span></li>
								
							</ul>
						</div>
						
						<div className="recent-blog" data-aos="zoom-in-left">
							<h3>Recent Post</h3>
							<div className="row">
								<div className="col-md-5">
									<div className="recent-blog-left">
										<img src=""/>
									</div>
									<div className="recent-blog-left">
										<img src=""/>
									</div>
									
									<div className="recent-blog-left">
										<img src=""/>
									</div>
									
									<div className="recent-blog-left">
										<img src=""/>
									</div>
									
								</div>
								
								<div className="col-md-7">
									<div className="recent-blog-right">
										<h5>SEPTEMBER 6, 2021</h5>
										<h4>How to Become an Interior Designer?</h4>
									</div>
									<div className="recent-blog-right">
										<h5 className="sept">SEPTEMBER 6, 2021</h5>
										<h4>How to Become an Interior Designer?</h4>
									</div>
									
									<div className="recent-blog-right">
										<h5 className="sept">SEPTEMBER 6, 2021</h5>
										<h4>How to Become an Interior Designer?</h4>
									</div>
									
									<div className="recent-blog-right">
										<h5 className="sept">SEPTEMBER 6, 2021</h5>
										<h4>How to Become an Interior Designer?</h4>
									</div>
									
								</div>
							</div>
						</div>
						
						<div className="tags" data-aos="zoom-in-left">
							<h3>Tags</h3>
							
							<ul>
								<li><a href="#">Study Abroad</a></li>
								<li><a href="#">Careers</a></li>
								<li><a href="#">Exams</a></li>
								<li><a href="#">How-To Guides</a></li>
								<li><a href="#">Success Stories</a></li>
							</ul>
						</div>

				
				</div>
			</div>
		</div>
	</div>
</section>
    </>
  )
}

export default Blog