// src/components/select.js
import React from 'react'

const Select = ({ name, value, label, options, onChange, error }) => {
  // Determine if there's a specific error for the empty value
  const hasEmptyValueError = value === '' && error

  return (
    <div className='form-group'>
      <label htmlFor={name}>{label}</label>
      <select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className={`form-control ${hasEmptyValueError ? 'is-invalid' : ''}`}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {hasEmptyValueError && (
        <p
          style={{ fontSize: '12px', marginBottom: '0px' }}
          className='text-danger'
        >
          Select an option
        </p>
      )}
      {!hasEmptyValueError && error && (
        <p
          style={{ fontSize: '12px', marginBottom: '0px' }}
          className='text-danger'
        >
          {error}
        </p>
      )}
    </div>
  )
}

export default Select
