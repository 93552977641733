import React from "react";
import homeVideo1 from "../../assets/images/home Video-2.gif";
import intendenicon from "../../assets/images/intenden-icon.png";
import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <>
      <section class="maine-banner">
        <div className="container">
          <div className="row">
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xll-6 pt-5">
              <div className="maine-banner-inner " data-aos="fade-up">
                <h2>
                Boost Your 
                  <span class="yellow-title"> B2B </span> Sales <br/> Pipeline Now
                </h2>
                <h5>
                Our AI-Powered Intent marketing and psychometric analysis help you to unlock Meaningful leads 
                </h5>
                <ul className="d-flex">
                  <li>
                    {" "}
                    <img src={intendenicon} className="img-fluid" alt="" />
                    Lead Generation                               
                  </li>
                  <li>
                    {" "}
                    <img src={intendenicon} className="img-fluid" alt="" />
                    Intent Data
                  </li>
                  <li>
                    {" "}
                    <img src={intendenicon} className="img-fluid" alt="" />
                    ABM Marketing  {" "}
                  </li>
                  <li>
                    {" "}
                    <img src={intendenicon} className="img-fluid" alt="" />
                    Content Marketing
                  </li>
                </ul>
                <h5>
                Take part in a transparent lead generation process aim to supply high quality , human verified Full funnel B2b Leads that results in higher conversions and better ROI 
                </h5>
                
                <Link className="btn-lead" to="/user_dashboard">
                  Join Now For Free
                </Link>
              </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xll-6">
              <div className="maine-banner-left" data-aos="fade-down">
                <img src={homeVideo1} alt="home Video-1.gif" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
