import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);

  return (
    <>
      <nav>
        <ul className="pagination" style={{ overflow: "hidden" }}>
          {currentPage > 1 && (
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => onPageChange(currentPage - 1)}
              >
                Previous
              </a>
            </li>
          )}

          {pages.map((page) => (
            <li
              key={page}
              className={
                currentPage === page ? "page-item active" : "page-item"
              }
              style={{
                display:
                  currentPage === page ||
                  currentPage === page + 1 ||
                  currentPage === page - 1
                    ? "block"
                    : "none",
              }}
            >
              <a className="page-link" onClick={() => onPageChange(page)}>
                {page}
              </a>
            </li>
          ))}

          {currentPage < pagesCount && (
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => onPageChange(currentPage + 1)}
              >
                Next
              </a>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
