import http from "./httpService";

const apiEndpoint =  "/contact/";

function authUrl(id) {
  return `${apiEndpoint}${id}`;
}

// Get the Contacts Data

export async function getContacts() {
  return await http.get(apiEndpoint);
}

export async function getContact(id) {
  return http.put(authUrl(id));
}

//  Create and Update Contact

export function saveContacts(contact) {
  return http.post(apiEndpoint, contact);
}

// Update the status of the Contact Data

export async function statusContact(id, status) {
  return http.post(authUrl(id), { status: status });
}

// Delete the Contact Data

export async function deleteContact(id) {
  return http.delete(authUrl(id));
}
