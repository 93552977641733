import React from "react";

const Error = () => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-column vh-100">
        <h1>404 Error</h1>
        <h1>Page Not Found</h1>
      </div>
    </>
  );
};

export default Error;
