import React from "react";
import Header from "./Header";
import Body from "./Body";
import Project from "./Project";
import Customer from "./Customer";

function Panel() {
  return (
    <>
      <Header />

      {/* <Body />
        <Customer />
        <Project /> */}
    </>
  );
}

export default Panel;
