import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  let data = location.state;
  return (
    <>
      <div className="row pt-3">
        <div className="col-md-12">
          <div className="source-box">
            <div className="source-top d-flex">
              <h4>Active Leeds</h4>
              <Link class="btn-bulk" to="new" state={data}>
                <i class="fa fa-plus" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
