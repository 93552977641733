import React from "react";
import NoImageAvailable from "../assets/images/NoImageAvailable.jpg";
import ImageSuccess from "../assets/images/ImageSuccess.gif";
import Document from "../assets/images/Document.png";
import getImageType from "./../utils/getImageType";
import { Link } from "react-router-dom";

const Input = ({ name, label, error, src, type, base64Image, ...rest }) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor={name} dangerouslySetInnerHTML={{ __html: label }} />
        <div className="input">
          <input
            {...rest}
            id={name}
            name={name}
            type={type}
            className="form-control"
          />
          {type === "file" ? (
            <>
              {getImageType(src) === "No image" || src === "" ? (
                <img src={NoImageAvailable} alt={name} />
              ) : getImageType(src) === "Unknown image type" ? (
                <img src={ImageSuccess} alt={name} />
              ) : getImageType(src) === "Image" ? (
                <img
                  src={`/${src}`}
                  alt={name}
                />
              ) : getImageType(src) === "Document" ? (
                <Link to={src}>
                  <img src={Document} alt={name} />
                </Link>
              ) : getImageType(src) === "Base64 image (jpeg/jpg/png)" ? (
                <img src={src} alt={name} />
              ) : (
                <img src={NoImageAvailable} alt={name} />
              )}
            </>
          ) : null}
        </div>
        {error && <p  style={{font:"12px",marginBottom:"0px"}} className="text-danger">{error}</p>}
      </div>
    </>
  );
};

export default Input;
