import React from "react";
import mentor from "../../assets/images/Mentor.png";
import connection from "../../assets/images/Connections-established.png";
import { Link } from "react-router-dom";
import profile from "../../assets/images/jop-profile.png";

const VirtualCXO = () => {
  return (
    <>
      <div className="active-main d-flex">
        <div className="active-main-left">
          <div className="profile-jobs">
            <img src={profile} className="img-fluid" alt="" />
            <h5>Founder & CEO </h5>
            <h6>Company Name</h6>
          </div>
        </div>
        <div className="active-main-right">
          <h4>Virtual CXOs </h4>
          <p>
            We Connect you with the World's Best Experts . Our team of CXOs are
            highly skilled in their respective business functions and can
            provide invaluable support in a variety of areas. Here are just some
            of the key areas where they excel, but their expertise is not
            limited to:
          </p>
          <ul className="mrkts">
            <li>Marketing Strategy </li>
            <li>Growth Hacking</li>
            <li>Finance & Commercial </li>
            <li>HR Management </li>
            <li>Technology & IT </li>
            <li>Digital Transformation </li>
            <li>General Management & Operations </li>
            <li>C-Suite Influencer Marketing</li>
            <li>B2B & Enterprise Strategies</li>
            <li>Investor Relations</li>
            <li>Product Management </li>
          </ul>
          <div className="target-box">
            <ul className="d-flex">
              <li>
                <img
                  src={mentor}
                  className="img-fluid"
                  alt="images/Mentor.png"
                />
                <h6>
                  <span>15000+ </span>
                  <br />
                  Mentor & Mentees{" "}
                </h6>
              </li>
              <li>
                <img
                  src={connection}
                  className="img-fluid"
                  alt="images/Connections-established.png"
                />
                <h6>
                  <span>195000+ </span>
                  <br />
                  Connections established{" "}
                </h6>
              </li>
            </ul>
          </div>
          <div className="comanies-main d-flex">
            <div className="comanies-main-right">
              <Link className="btn-lead" to="/user_dashboard/cxo">
                Apply for Virtual CXOs{" "}
              </Link>
              <Link className="btn-lead" to="/user_dashboard/cxo">
                Become a Mentor CXO{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VirtualCXO;
