import React from "react";
import img from "../../assets/images/blg-banner.png";
import Introduction from "./Introduction";

const Privacy = ({ funcNav, funcFooter }) => {
  funcNav(true);
  funcFooter(true);
  const image = img;
  const heading = "Privacy Policy";
  return (
    <div>
      <Introduction image={image} heading={heading} />
    </div>
  );
};

export default Privacy;
