import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "./../../common/form";
import { useSelector } from "react-redux";
import {
  getMIntentApply,
  saveMIntentApplys,
} from "../../services/mintentApplyService";

const MIntentActiveForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialData = {
    ...location.state,
    _id: "",
    name: "",
    email: "",
    mobile: "",
    img: "",
    company_name: "",
    designation: "",
    address: "",
    city: "",
    state: "",
    country: "",
    post_code: "",
    industry: "",
    sub_industry: "",
    comments: "",
    status: "",
    created_date: "",
    updated_date: "",
    __v: "",
  };
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [base64Image, setBase64Image] = useState("");
  const params = useParams();

  const PopulateData = async (id) => {
    try {
      const dataId = id;
      if (dataId !== "new") {
        const response = await getMIntentApply(id);
        setData(response.data);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (params._id) {
      PopulateData(params._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params._id]);

  const handlePrev = () => {
    // Programmatically navigate to a different route
    const newPathname = "/admin_panel/intent";

    let redirectUrl = process.env.REACT_APP_liveURL + newPathname;
    if (process.env.NODE_ENV === "development") {
      redirectUrl = process.env.REACT_APP_localURL + newPathname;
    }
    window.location.href = redirectUrl; // No need for type assertion
  };

  const doSubmit = async () => {
    // Call the server
    console.log("Submitted", data);
    try {
      const response = await saveMIntentApplys(data);
      toast.success(response.data.message);
      handlePrev();
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    _id: Joi.optional().allow(""),
    user_id: Joi.optional().allow(""),
    intent_id: Joi.optional().allow(""),
    name: Joi.optional().allow(""),
    email: Joi.optional().allow(""),
    mobile: Joi.optional().allow(""),
    img: Joi.optional().allow(""),
    company_name: Joi.optional().allow(""),
    designation: Joi.optional().allow(""),
    address: Joi.optional().allow(""),
    city: Joi.optional().allow(""),
    state: Joi.optional().allow(""),
    country: Joi.optional().allow(""),
    post_code: Joi.optional().allow(""),
    industry: Joi.optional().allow(""),
    sub_industry: Joi.optional().allow(""),
    comments: Joi.optional().allow(""),
    status: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
    __v: Joi.optional().allow(""),
  };

  const {
    renderInputArea,
    renderFile,
    renderButton,
    renderInput,
    handleSubmit,
  } = Form({
    data,
    setData,
    errors,
    base64Image,
    setBase64Image,
    setErrors,
    schema,
    onSubmit: doSubmit,
  });

  useEffect(() => {
    const buttonElement = document.querySelector(".forms-sample .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-gradient-primary");
    }
  }, []);

  return (
    <>
      <div className="card">
        <form onSubmit={handleSubmit} className="forms-sample">
          <div className="row ">
            <div className="col-12 grid-margin ">
              <p className="card-description"> Add/Edit Active Leeds </p>

              {/* Form Started */}
              <div className="row">
                <div className="col-md-4">
                  {renderInput("name", "Full Name")}
                </div>
                <div className="col-md-4">
                  {renderInput("email", "Email", "email")}
                </div>
                <div className="col-md-4">
                  {renderInput("mobile", "Mobile", "tel")}
                </div>
                <div className="col-md-6">
                  {renderInput("company_name", "Company Name")}
                </div>
                <div className="col-md-6">
                  {renderFile("img", "Profile Image", "file")}
                </div>
                <div className="col-md-12">
                  {renderInputArea("address", "Address")}
                </div>
                <div className="col-md-3">{renderInput("city", "City")}</div>
                <div className="col-md-3">{renderInput("state", "State")}</div>
                <div className="col-md-3">
                  {renderInput("country", "Country")}
                </div>
                <div className="col-md-3">
                  {renderInput("post_code", "Post Code")}
                </div>
                <div className="col-md-6">
                  {renderInput("industry", "Industry")}
                </div>
                <div className="col-md-6">
                  {renderInput("sub_industry", "Ssub Industry")}
                </div>
                <div className="row">
                  <div className="col-md-6">{renderButton("Save")}</div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default MIntentActiveForm;
