import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import './ProfileSummary.css'
import { UserProfileFormUpdate } from '../../forms'
import { useSelector, useDispatch } from 'react-redux'
import { setUser } from '../../actions'
import axios from 'axios'
const ProfileSummaryUpdate = ({ funcNav, funcFooter }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  funcNav(true)
  funcFooter(true)
  const user = useSelector(state => state.userReducer)

  useEffect(() => {
    const getUser = async () => {
      try {
        // Check if Google authentication is in progress
        if (sessionStorage.getItem('googleAuthInProgress') === 'true') {
          const response = await axios.get(`/login/success`, {
            withCredentials: true
          })
          console.log('response', response)
          sessionStorage.setItem('user', JSON.stringify(response.data.user))
          dispatch(setUser(response.data.user))

          // Once user data is retrieved, remove the flag
          sessionStorage.removeItem('googleAuthInProgress')
        }
      } catch (error) {
        // Handle errors
      }
    }

    getUser()
  }, [dispatch])

  if (!user || user.department === 'SuperAdmin')
    return <Navigate to={`/user_dashboard`} replace />

  return (
    <section className='profile'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='profilesummary'>
              <div className='container'>
                <UserProfileFormUpdate />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProfileSummaryUpdate
