import React, { useState, useEffect } from 'react'
import Filter from './Filter'

import Header from './Header'
import { useLocation } from 'react-router-dom'
import { paginate } from '../../utils/paginate'
import Pagination from '../../common/pagination'
import { toast } from 'react-toastify'
import SearchBox from '../../common/searchBox'
import PageSize from '../../common/pageSize'
import UserTable from './UserTable'
import { getUsers, statusUser, deleteUser } from '../../services/usersService'
import axios from 'axios'

function UserList () {
  const [items, setItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchQuery, setSearchQuery] = useState('')
  const location = useLocation()

  const fetchData = async () => {
    try {
      const response = await getUsers()
      setItems(response.data.user_list)
    } catch (error) {
      console.error('Error fetching users list:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSearch = query => {
    setSearchQuery(query)
    setCurrentPage(1)
  }

  const handleDelete = async item => {
    const originalDdata = items
    const data = originalDdata.filter(u => u._id !== item._id)
    setItems(data)
    setCurrentPage(1)
    try {
      const response = await axios.delete(`/users/${item._id}`)
      toast.success(response.data.message)
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message)
      }
    }
  }

  const handleStatus = async item => {
    try {
      const response = await statusUser(item)
      toast.success(response.data.message)
      fetchData()
      setCurrentPage(1)
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message)
      }
    }
  }

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  const getPagedData = () => {
    let filtered = items
    if (searchQuery)
      filtered = items.filter(m =>
        m.name?.toLowerCase().startsWith(searchQuery.toLowerCase())
      )
    const item = paginate(filtered, currentPage, pageSize)
    return { totalCount: filtered.length, data: item }
  }

  const { length: count } = items

  if (count === 0) return <p>There are no data in the database.</p>

  const { totalCount, data: item } = getPagedData()

  return (
    <>
      <Filter searchQuery={searchQuery} onSearch={handleSearch} />
      <div class='card'>
        <Header />
        <UserTable
          items={item}
          onDelete={handleDelete}
          onStatus={handleStatus}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  )
}

export default UserList
