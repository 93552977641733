import React, { useState } from 'react'
import Joi from 'joi-browser'
import './AdminForm.css'
import Form from '../../common/form'
import auth from '../../services/authServices'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useDispatch } from 'react-redux'

function AdminForm () {
  const initialData = { username: '', password: '' }
  const [data, setData] = useState(initialData)
  const [errors, setErrors] = useState({})
  const [isSuperAdmin, setIsSuperAdmin] = useState(false) // State for Superadmin checkbox
  const adminEndpoint = '/auth/admin_auth'
  const location = useLocation()
  const dispatch = useDispatch()

  const doSubmit = async () => {
    try {
      await auth.login(adminEndpoint, data, 'username', 'password')
      let redirectUrl = process.env.REACT_APP_HOMEPAGE
      let path = location.state
        ? `${redirectUrl}${location.state.history}`
        : `${redirectUrl}/admin_panel`

      window.location.href = path
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message)
      }
    }
  }

  const doAdminSubmit = async () => {
    try {
      const response = await axios.get(`/users?email=${data.username}`)
      const user = response.data.user_list[0]
      console.log(user)
      if (!user) {
        throw new Error('User not found')
      }
      if (data.password !== 'user@1234') {
        toast.error('Incorrect Password')
        return
      }

      sessionStorage.setItem('user', JSON.stringify(user))

      // Ensure that the user is authorized
      if (user.department === 'User' || user.department === 'Admin') {
        let redirectUrl = process.env.REACT_APP_HOMEPAGE
        let path = `${redirectUrl}/admin_panel`
        window.location.href = path
      } else {
        toast.error('You do not have access to this page', user)
        console.log('You do not have access to this page', user.department)
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        toast.error('Failed to fetch user data')
      } else {
        toast.error(ex.message || 'An unexpected error occurred')
      }
    }
  }

  const schema = {
    username: Joi.string().required().label('Username'),
    password: Joi.string().required().label('Password')
  }

  const { renderInput, renderButton, handleSubmit } = Form({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: isSuperAdmin ? doSubmit : doAdminSubmit // Use Superadmin state to determine function
  })

  const handleCheckboxChange = () => {
    setIsSuperAdmin(!isSuperAdmin) // Toggle Superadmin state
  }

  return (
    <>
      <form className='logine' onSubmit={handleSubmit}>
        {isSuperAdmin && renderInput('username', 'Username')}
        {!isSuperAdmin && renderInput('username', 'Email')}
        {renderInput('password', 'Password', 'password')}
        {renderButton('Login')}
        <p>&nbsp;</p>
        <label>
          <input
            type='checkbox'
            checked={isSuperAdmin}
            onChange={handleCheckboxChange}
          />
          Superadmin
        </label>
      </form>
    </>
  )
}

export default AdminForm
