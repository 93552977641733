import React from "react";
import main from "../../assets/images/main-bg.png";
import { Link } from "react-router-dom";

const Active = () => {
  return (
    <>
      <section className="intendData">
        <div class="container">
          <div class="row">
            <div class="col-md-7" data-aos="zoom-in-right">
              <div>
                <h3>
                Prospect Level <span class="yellow-title">Intend</span>{" "} Data
                </h3>
                <p>
                LX Intend Engine accelerates the path to closed-won opportunities by 30% through its ability to connect you with most active buyers from your wishlist accounts. Discover how LX Intend Engine can help you achieve your goals. 
                <br />
                <br />
                LeadXchange (LX) brings high Quality Purchase Intent Prospect, LX tracking tool identify & bring the opt-in technology buyers for your products /Solution
                </p>
                <div class="main-counter d-flex">
                  <div class="counter">
                    <span class="counter-value">15000</span>
                    <small>+</small>
                    <h5>Campaign Managed</h5>
                  </div>
                  <div class="counter">
                    <span class="counter-value">15</span>
                    <small>+</small>
                    <h5>Years of Experience</h5>
                  </div>
                  <div class="counter">
                    <span class="counter-value">12</span>
                    <small>+</small>
                    <h5>Awards winning</h5>
                  </div>
                  <div class="counter">
                    <span class="counter-value">100</span>
                    <small>%</small>
                    <h5>Satisfied customers</h5>
                  </div>
                </div>
                <Link class="btn-lead" to="/user_dashboard">
                  Login
                </Link>
              </div>
            </div>
            <div class="col-md-5" data-aos="zoom-in-left">
              <div class="out-right">
                <img src={main} class="img-fluid" alt="images/main-bg.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Active;
