import http from "./httpService";

// Define the base endpoint paths
const authEndpoint =  "/auth/user_auth_signup";
const apiObjectiveEndpoint =
   "/auth/user_auth_setObjective/";
const apiIndustryRolesEndpoint =
   "/auth/user_auth_setIndustryRoles/";
const apiUserProfileEndpoint =
   "/auth/user_auth_setUserProfile/";

// Create a function to generate endpoint URLs based on the provided ID and endpoint path
function createEndpointUrl(endpoint, id) {
  return `${endpoint}${id}`;
}

export function register(user) {
  return http.post(authEndpoint, {
    email: user.email,
    password: user.password,
  });
}

export async function setUserObjective(id, objective) {
  const objUrl = createEndpointUrl(apiObjectiveEndpoint, id);
  return http.post(objUrl, { objective: objective });
}

export async function setUserIndustryRoles(id, industries, roles) {
  const indRoleUrl = createEndpointUrl(apiIndustryRolesEndpoint, id);
  return http.post(indRoleUrl, { industries: industries, roles: roles });
}

export async function setUserProfile(id, data) {
  const prfUrl = createEndpointUrl(apiUserProfileEndpoint, id);
  return http.post(prfUrl, data);
}
