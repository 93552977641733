import React from "react";
import { Link } from "react-router-dom";
import Table from "../../common/table";
import Delete from "../../common/delete";
import Status from "./../../common/status";
import NoImageAvailable from "../../assets/images/NoImageAvailable.jpg";

const RolesTable = ({ items, onDelete, onStatus }) => {
  const columns = [
    {
      key: "sno", // Set a unique key value
      label: "S.No",
      content: (item) => <p>{items.indexOf(item) + 1}</p>,
    },
    {
      path: "persona",
      label: "Objective",
    },
    {
      path: "status",
      label: "Status",
      content: (item) => (
        <>
          <Status status={item.status} onClick={() => onStatus(item)} />
        </>
      ),
    },
    {
      path: "created_date",
      label: "Created Date",
      content: (item) => <>{new Date(item.created_date).toDateString()}</>,
    },
    {
      key: "Action",
      label: "Action",
      content: (item) => (
        <>
          <Link className="btn-veiw" to={`${item._id}`}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </Link>
          <Delete onClick={() => onDelete(item)} />
        </>
      ),
    },
  ];

  return <Table data={items} columns={columns} />;
};

export default RolesTable;
