import http from "./httpService";

const apiEndpoint =  "/intent";
const apiUserIntentsEndpoint =  "/intent/user";

// Get the MIntents Data
export async function getMIntents() {
  return http.get(apiEndpoint);
}

export async function getMIntent(id) {
  const objUrl = createEndpointUrl(apiEndpoint, id);
  return http.put(objUrl);
}

export async function getUserMIntent(id) {
  const objUrl = createEndpointUrl(apiUserIntentsEndpoint, id);
  return http.put(objUrl);
}

// Create and Update MIntent
export function saveMIntents(mintent) {
  return http.post(apiEndpoint, mintent);
}

// Update the status of the MIntent Data
export async function statusMIntent(id, status) {
  const authUrl = createEndpointUrl(apiEndpoint, id);
  return http.post(authUrl, { status: status });
}

// Delete the MIntent Data
export async function deleteMIntent(id) {
  const authUrl = createEndpointUrl(apiEndpoint, id);
  return http.delete(authUrl);
}

function createEndpointUrl(endpoint, id) {
  return `${endpoint}/${id}`;
}
