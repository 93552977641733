import React from "react";
import Header from "./Header";
import { RolesList } from "../../../../tables";

const Roles = () => {
  return (
    <>
      <Header />
      <RolesList />
    </>
  );
};

export default Roles;
