import React from "react";
import about from "../../assets/images/about-banner.png";
import aboutusbanner from "../../assets/images/aboutus-banner.jpg";
import network from "../../assets/images/network-2.png";
import commitment from "../../assets/images/commitment.png";

import vision from "../../assets/images/vision.png";
import mission from "../../assets/images/mission.png";
import newteam1 from "../../assets/images/newteam-1.png";
import newteam3 from "../../assets/images/newteam-3.png";
import newteam2 from "../../assets/images/newteam-2.png";
import newteam4 from "../../assets/images/newteam-4.png";
import Testimonials from "../../components/Testimonials/Testimonials";
import "./AboutUs.css";

const AboutUs = ({ funcNav, funcFooter }) => {
  funcNav(true);
  funcFooter(true);
  return (
    <>
      <section className="maint-abt">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 ps-0 pe-0">
              <div className="maint-abt-inner">
                <img src={aboutusbanner} className="img-fluid" alt="" />
                <h3>About Us</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="maine-banner abt-st">
        <div className="container">
          <div className="row">
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xll-6">
              <div className="maine-banner-inner">
                <h2>
                  Networking is an essential part of building
                  <span className="yellow-title">WEALTH</span>
                </h2>
                <h5>
                  We aim to help you build the RIGHT network and Bring Value
                  through the power of Connections.
                </h5>
                <h5 className="mt-3">
                  LeadXchange.ai is a platform from CXO TV – An AI-Enabled CXO
                  networking, engagement, and recommendation platform. With its
                  intelligent features, it revolutionizes the way CXOs
                  collaborate globally.
                </h5>
              </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xll-6">
              <div className="maine-banner-left">
                <img src={network} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xll-12 ps-0 pe-0">
              <div className="about-banner-inner">
                <img src={about} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="main-vision">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="ultimate-inner text-center">
                <img src={vision} />
                <h5>Vision</h5>
                <p>
                  Lead Xchange ( LX) Create a prestigious community of
                  influential senior leaders, empowering them to thrive, lead,
                  and make a positive impact on the professional and social
                  spheres.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="ultimate-inner text-center">
                <img src={mission} />
                <h5>Mission</h5>
                <p>
                  Lead Xchange (LX) aim is to cultivate a distinguished global
                  community of leaders by offering a value-driven, AI-powered
                  platform that fosters both professional and personal growth
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="ultimate-inner text-center">
                <img src={commitment} />
                <h5>Commitment</h5>
                <p>
                  We deliver smart introductions to relevant professionals to
                  our community of smart, curious & passionate individuals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="new-team">
        <div className="container">
          <h3>Meet Our Amazing Team</h3>
          <div className="row">
            <div className="col-md-6">
              <div className="new-team-inner d-flex">
                <div className="new-team-inner-left">
                  <img src={newteam1} />
                </div>
                <div className="new-team-inner-right">
                  <h4>Anuj Singhal</h4>
                  <h5>Founder & MD</h5>
                  <p>
                    Honored amongst 100 most impactful ICT Healthcare leaders in
                    2017 by CMO Asis & Times Of India Group
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="new-team-inner d-flex">
                <div className="new-team-inner-left">
                  <img src={newteam3} />
                </div>
                <div className="new-team-inner-right">
                  <h4>Kalpana Singhal</h4>
                  <h5>Co Founder & CEO</h5>
                  <p>
                    Kalpana brings 20+ yrs experience from ICT and lifestyle
                    industry, she acted as Domain consultant to FalguniNayar in
                    2011- 2012 while setting up nykaa.com{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="new-team-inner d-flex">
                <div className="new-team-inner-left">
                  <img src={newteam2} />
                </div>
                <div className="new-team-inner-right">
                  <h4>Anand Sharma</h4>
                  <h5>Executive Director </h5>
                  <p>
                    Anand is responsible for driving strategy and global
                    expansion plans at Techplus Media. Previously he has served
                    as VP at Accenture. In his 21+ years of career he has played
                    many roles spanning across sales, business development, and
                    large deals. He has managed large accounts at Accenture and
                    Techplus Media, and growing new business in APAC and Europe
                    region.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="new-team-inner d-flex">
                <div className="new-team-inner-left">
                  <img src={newteam4} />
                </div>
                <div className="new-team-inner-right">
                  <h4>Naveen Singhal</h4>
                  <h5>Customer Experience Officer </h5>
                  <p>
                    20+ yrs experience and passion sales and Customer
                    Experience. An Agile and dynamic leader, believe that
                    repeated sales is outcome of relationship and customer
                    experience
                  </p>
                </div>
              </div>
            </div>
            <p className="join-p">
              Join our innovative network of talented professionals specializing
              in Technology, Growth Hacking, Customer Delight, and Sales. We're
              revolutionizing the way colleagues connect and collaborate. Want
              to be a part of our exciting growth story? Email us at
              careers@leadxchange.ai
            </p>
          </div>
        </div>
      </section>

      <section class="with" data-aos="fade-up">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xll-7">
              <div class="with-left">
                <h5 className="text-black">
                  Networking is not about just connecting People . Its about
                  Connecting people with people, people with ideas and people
                  with opportunities .
                </h5>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xll-5">
              <div class="with-right">
                <h5>Contact Us</h5>
                <p>
                  Techplus Media PTE Ltd.
                  <br />
                  68 Circular Road
                  <br />
                  02-01 Singapore-049422 Email:contact@leadxchange.ai
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
    </>
  );
};

export default AboutUs;
