import React from "react";
import Header from "./Header";
import { ObjectiveList } from "../../../../tables";

const Objective = () => {
  return (
    <>
      <Header />
      <ObjectiveList />
    </>
  );
};

export default Objective;
