import http from "./httpService";

const apiEndpoint =  "/users/";

function authUrl(id) {
  return `${apiEndpoint}${id}`;
}

// Get the Isers Data

export function getUsers() {
  return http.get(apiEndpoint);
}

export async function getUser(id) {
  return http.get(authUrl(id));
}

// Update the status of the User Data

export async function statusUser(item) {
  return http.post(authUrl(item._id), { status: item.status });
}
