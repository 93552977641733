import React from 'react'

const Header = () => {
  return (
    <>
      <>
        <div class='campaign-top d-flex'>
          <div class='camping-hd'>
            <h4>System Users</h4>
            {/* <p>More than 20+ new users</p> */}
          </div>
        </div>
      </>
    </>
  )
}

export default Header
