import React from 'react'
import dark from '../../assets/images/logo.png'
import './Admin.css'
import { AdminForm } from '../../forms'
import { Navigate } from 'react-router-dom'
import transparent from '../../assets/images/transparent.png'
import Footer from './Footer'
import { useSelector } from 'react-redux'

function Admin ({ funcNav, funcFooter }) {
  const user = useSelector(state => state.userReducer)
  funcNav(false)
  funcFooter(false)
  if (user && user?.user_type === 'Superadmin')
    return <Navigate to={`/admin_panel`} replace />

  return (
    <>
      <section className='sales'>
        <div className='container-fluid ps-0 pe-0 salesi'>
          <div className='row'>
            <div className='col-md-6 sales-left1'>
              <div className='sales-left'>
                <img src={dark} alt='dark.png' />
                {/* Login Form */}
                <AdminForm />
              </div>
            </div>
            <div className='col-md-6 sales-right1'>
              <div className='sales-right'>
                <img
                  className='sales-rights'
                  src={transparent}
                  alt='Lead XChange'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Admin
