import React from "react";
import dataAnalystBanner from "../../assets/images/database-and-analytics-banner.jpg";
import dataAnalyst from "../../assets/images/database-and-analysis-img.png";
import dataMaintenance from "../../assets/images/data-maintenance-img.png";
import Testimonials from "../../components/Testimonials/Testimonials";

const DatabaseAnalytics = ({ funcNav, funcFooter }) => {
  funcNav(true);
  funcFooter(true);
  return (
    <>
      <section className="maint-abt">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 ps-0 pe-0">
              <div className="maint-abt-inner">
                <img src={dataAnalystBanner} className="img-fluid" alt="" />
                <h3>Database & Analytics</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="maine-banner abt-st">
        <div className="container">
          <div className="row d-flex justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-12 pb-3">
            <h3 className="text-center">Intend Driven Data/Customer Intelligence</h3>
          </div>
           <div className="col-xs-12 col-sm-12 col-md-9">
            <img src={dataAnalyst} className="img-fluid" alt="" />
           </div>
          <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="maine-banner-inner">
           <p>
           Competition is high, and customers know they have options. B2B companies need to be intuitive and distinguish what their customers need without being told. Techplus LeadXchange arm Marketing & Sale team with insightful account intelligence, revealing your best opportunities and tips on how to target them.We can help you discover specific technologies employed, competitor installations, budgeting, and additional complex risk and objections. Uncover hidden sales opportunities.
           </p>
           </div>
          </div>
          </div>
        </div>
      </section>
      <section className="maine-banner bg-gray">
        <div className="container">
          <div className="row pt-5">
          <div className="col-xs-12 col-sm-12 col-md-5">
            <img src={dataMaintenance} className="img-fluid" alt="" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-7">
            <h3>Data Maintenance & Cleaning</h3>
            <p>
                As data is becoming a core part of every business operation the quality of the data that is gathered, stored and consumed during business processes will determine the success achieved in doing business today and tomorrow.
            </p>
            <p>
                Our Cleanse service ensures that all of your data is accurate and consistent across all sources. We employ data quality tools to implement and carry out a thorough data cleansing process, which then enables us to merge duplicates, fill in the blanks, and standardize your contact information.
            </p>
            <p>
                Techplus(LX) keeps your contact data up-to-date by combining different hygiene processes with real-time email verification to automatically Clean your records.
            </p>
            
           </div>
          </div>
        </div>
      </section>
      <Testimonials />
    </>
  );
};

export default DatabaseAnalytics;