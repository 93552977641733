// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tb-slct select {
  width: 165px;
  margin-top: 14px;
}

.horizontal-menu
  .top-navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-profile-img
  h5 {
  background-color: #f7a600;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 25px;
  text-align: center;
  padding: 8px 14px;
  margin-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/AdminDashboard/AdminDashboard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;;;;;;EAOE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".tb-slct select {\r\n  width: 165px;\r\n  margin-top: 14px;\r\n}\r\n\r\n.horizontal-menu\r\n  .top-navbar\r\n  .navbar-menu-wrapper\r\n  .navbar-nav\r\n  .nav-item.nav-profile\r\n  .nav-profile-img\r\n  h5 {\r\n  background-color: #f7a600;\r\n  width: 45px;\r\n  height: 45px;\r\n  border-radius: 50%;\r\n  font-size: 25px;\r\n  text-align: center;\r\n  padding: 8px 14px;\r\n  margin-bottom: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
