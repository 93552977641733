import React from "react";

const Header = () => {
  return (
    <>
      <>
        <div class="campaign-top d-flex">
          <div class="camping-hd">
            <h4>Objectives List</h4>
            <p>More than 20+ new objectives</p>
          </div>
        </div>
      </>
    </>
  );
};

export default Header;
