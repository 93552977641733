import React from 'react'
import Header from './Header'
import { IntentList } from '../../../../tables'
import IntentBody from '../../../../tables/IntentList/IntentBody'
//intent,intentbody
const Intent = () => {
  return (
    <>
      <Header />
      <IntentBody />
      {/* <IntentList /> */}
    </>
  )
}

export default Intent
