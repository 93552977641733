import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="footer ">
        <div className="d-sm-flex justify-content-center justify-content-sm-between ">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block ">
            Copyright © 2023
            <Link to="/"> LeadXchange.com</Link>. All rights reserved.
          </span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center ">
            Developed by <Link to="http://rbt4tech.com/">RBT</Link>{" "}
          </span>
        </div>
      </footer>{" "}
    </>
  );
}

export default Footer;
