// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background: #1f4572;
  border-top: 1px solid #1f4572;
  padding: 16px 1rem;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  font-size: calc(0.875rem - 0.05rem);
  font-family: "ubuntu-regular", sans-serif;
  color: #fff;
}

.text-muted,
.preview-list .preview-item .preview-item-content p .content-category,
.email-wrapper
  .mail-sidebar
  .menu-bar
  .profile-list-item
  a
  .user
  .u-designation,
.email-wrapper .mail-list-container .mail-list .content .message_text,
.email-wrapper .mail-list-container .mail-list .details .date {
  color: #fff !important;
}
.footer a {
  color: #24baef;
  font-size: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,6BAA6B;EAC7B,kBAAkB;EAClB,0BAA0B;EAC1B,+BAA+B;EAC/B,kCAAkC;EAClC,8BAA8B;EAC9B,mCAAmC;EACnC,yCAAyC;EACzC,WAAW;AACb;;AAEA;;;;;;;;;;;EAWE,sBAAsB;AACxB;AACA;EACE,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".footer {\r\n  background: #1f4572;\r\n  border-top: 1px solid #1f4572;\r\n  padding: 16px 1rem;\r\n  transition: all 0.25s ease;\r\n  -moz-transition: all 0.25s ease;\r\n  -webkit-transition: all 0.25s ease;\r\n  -ms-transition: all 0.25s ease;\r\n  font-size: calc(0.875rem - 0.05rem);\r\n  font-family: \"ubuntu-regular\", sans-serif;\r\n  color: #fff;\r\n}\r\n\r\n.text-muted,\r\n.preview-list .preview-item .preview-item-content p .content-category,\r\n.email-wrapper\r\n  .mail-sidebar\r\n  .menu-bar\r\n  .profile-list-item\r\n  a\r\n  .user\r\n  .u-designation,\r\n.email-wrapper .mail-list-container .mail-list .content .message_text,\r\n.email-wrapper .mail-list-container .mail-list .details .date {\r\n  color: #fff !important;\r\n}\r\n.footer a {\r\n  color: #24baef;\r\n  font-size: inherit;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
