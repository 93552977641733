import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, Navigate } from 'react-router-dom'
import { setUser } from '../../../../actions'
import auth from '../../../../services/authServices'
import funnel from '../../../../assets/images/Dashbord_funnel.png'
import './Panel.css'
import {
  deleteMIntentApply,
  getUserMIntentApply,
  getIntentMIntentApply,
  statusMIntentApply
} from '../../../../services/mintentApplyService'
import FunnelGraph from 'funnel-graph-js'
import { Bar, Doughnut } from 'react-chartjs-2'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'

const Panel = () => {
  const getOrganizationSizeValue = label => {
    switch (label) {
      case '10,000 & Above':
        return 10000
      case '5001-10,000':
        return 10000
      case '1001-5000':
        return 500
      case '501-1000':
        return 1000
      case '201-500':
        return 500
      case '2-10':
        return 10
      default:
        return null // If the label doesn't match any case
    }
  }
  const user = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const location = useLocation()
  const [items, setItems] = useState([])
  const [campaignData, setCampaignData] = useState([])
  const [submitted, setSubmitted] = useState(0)
  const [rejected, setRejected] = useState(0)
  const [total, setTotal] = useState(0)

  const [lastCampaignData, setLastCampaignData] = useState({})
  console.log(getOrganizationSizeValue)
  // Dummy data for the donut chart
  const data = {
    datasets: [
      {
        data: [
          lastCampaignData.campaignTimeline,
          lastCampaignData.quantity,
          lastCampaignData?.organizationSize?.[0] === '10,000 & Above'
            ? 1000
            : lastCampaignData?.organizationSize?.[0] === '5001-10,000'
            ? 500
            : lastCampaignData?.organizationSize?.[0] === '1001-5000'
            ? 100
            : lastCampaignData?.organizationSize?.[0] === '501-1000'
            ? 10
            : lastCampaignData?.organizationSize?.[0] === '201-500'
            ? 1
            : lastCampaignData?.organizationSize?.[0] === '2-10'
            ? 0.1 // Changed from 10 to 0.1 based on previous context
            : null // If the organization size doesn't match any case
        ],
        backgroundColor: ['#0A58CA', '#F7A600', '#55B56D', '#FE0000']
      }
    ]
  }
  const getCampaignData = async () => {
    try {
      const currentUser = await auth.getCurrentUser()
      if (currentUser) {
        const response = await axios.get(
          `campaignSetup/getCampaignSetupByUserId/${currentUser._id}`
        )
        setCampaignData(response.data.campaignSetups)
        console.log(response.data.campaignSetups)
        setLastCampaignData(
          response.data.campaignSetups[response.data.campaignSetups.length - 1]
        )
      }
    } catch (error) {
      console.error('Error fetching campaign data:', error)
    }
  }

  const graphRef = useRef(null)

  const fetchData = async () => {
    try {
      let response
      if (user.department === 'Superadmin') {
        response = await getIntentMIntentApply(location.state.intent_id)
      } else {
        response = location.state
          ? await getIntentMIntentApply(location.state.intent_id)
          : await getUserMIntentApply(user._id)
      }
      setItems(response.data.intent_list)
    } catch (error) {
      console.error('Error fetching intent list:', error)
    }
  }

  useEffect(() => {
    if (location) fetchData()
  }, [location])

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const currentUser = await auth.getCurrentUser()
      dispatch(setUser(currentUser))
      getCampaignData()
    }
    fetchCurrentUser()
  }, [dispatch])
  useEffect(() => {
    if (graphRef.current) {
      // Set dimensions and styling for the container
      graphRef.current.style.width = '60%' // Full width
      graphRef.current.style.height = '500px' // Adjust height for better visibility
      graphRef.current.style.padding = '20px' // Add padding around the graph
      graphRef.current.style.boxSizing = 'border-box' // Ensure padding is included in width/height
      graphRef.current.style.marginBottom = '40px' // Ensure padding is included in width/height

      // Define the data for the graph
      const data = {
        labels: ['Controlled', 'Treated', 'Aware', 'Screened', 'Hypertension'],
        // subLabels: ['Female', 'Male'],
        colors: [
          ['red', 'red'],
          ['green', 'green'],
          ['red', 'red']
        ],
        values: [
          [5000, 5000], // Wide bottom
          [3000, 3000], // Widening stage
          [1000, 1000], // Narrowest point
          [3000, 3000], // Narrowing stage
          [5000, 5000] // Wide top
        ]
      }

      // Initialize the FunnelGraph with the updated data and styling
      const graph = new FunnelGraph({
        container: '.funnel',
        gradientDirection: 'vertical', // For vertical orientation
        data: data,
        displayPercent: true,
        direction: 'vertical', // Set direction to vertical
        width: graphRef.current.offsetWidth, // Use container width
        height: graphRef.current.offsetHeight, // Use container height
        subLabelValue: 'values',
        label: false, // Hide labels for a cleaner look
        padding: 10, // Add padding between funnel stages
        // borderWidth: 1, // Add border width
        borderColor: '#ddd' // Light border color for separation
      })

      // Draw the graph
      graph.draw()
    }
  }, [items])

  if (user && !user.isProfileSet)
    return <Navigate to={`/profilesummary`} replace />

  const SummaryInfo = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 20px',
        color: '#fff'
      }}
    >
      {[
        { title: 'End Date:', value: '31 July 2024', bgColor: '#F7A600' },
        {
          title: 'Lead Delivered/Lead Goal',
          value: '280 / 500',
          bgColor: '#0A58CA'
        },
        {
          title: 'Lead Generated',
          value: `${(submitted / total) * 100}`,
          bgColor: '#55B56D'
        }
      ].map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: item.bgColor,
            borderRadius: '10px',
            padding: '20px',
            flex: 1,
            margin: '0 10px'
          }}
        >
          <div>{item.title}</div>
          <h6>{item.value}</h6>
        </div>
      ))}
    </div>
  )

  const FunnelVisualization = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '85vH',
        backgroundColor: 'white'
        // background: 'linear-gradient(to bottom, #1d5a5d, #0e494d)'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '50px 20px',
          color: '#fff'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#F7A600',
            borderRadius: '10px',
            padding: '20px',
            flex: 1,
            margin: '0 10px'
          }}
        >
          <div>End Date:</div>
          <h6>
            {new Date().toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            })}
          </h6>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#0A58CA',
            borderRadius: '10px',
            padding: '20px',
            flex: 1,
            margin: '0 10px'
          }}
        >
          <div>Lead Delivered/Lead Goal</div>
          <h6>
            {submitted} / {total}
          </h6>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#55B56D',
            borderRadius: '10px',
            padding: '20px',
            flex: 1,
            margin: '0 10px'
          }}
        >
          <div>Lead Generated</div>
          <h6>{((submitted / total) * 100).toFixed(2)} %</h6>
        </div>
      </div>
      {/* Funnel Image with Text and Lines */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '60px',
          position: 'relative'
        }}
      >
        {/* left div */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: '350px'
          }}
        >
          <div style={{ position: 'relative' }}>
            <h6 style={{ color: 'white' }}>Total Lead</h6>
            <div
              style={{
                height: '2px',
                background: '#24F2FF',
                width: '360%',
                position: 'absolute',
                top: '50%',
                zIndex: 1
              }}
            ></div>
            <h6 style={{ paddingTop: '2px', color: 'white' }}>LeadXchange</h6>
          </div>
          <div style={{ position: 'relative' }}>
            <h6 style={{ color: 'white' }}>Current Leads</h6>
            <div
              style={{
                height: '2px',
                background: '#86F000',
                width: '420%',
                position: 'absolute',
                top: '100%',
                zIndex: 1
              }}
            ></div>
          </div>
          <div style={{ position: 'relative' }}>
            <h6 style={{ color: 'white' }}>Targeted Leads</h6>
            <div
              style={{
                height: '2px',
                background: '#F5BB00',
                width: '470%',
                position: 'absolute',
                top: '100%',
                zIndex: 1
              }}
            ></div>
          </div>
        </div>
        {/* center div */}
        <div style={{ position: 'relative', zIndex: 0 }}>
          <img
            src={funnel}
            alt='Funnel'
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>
        {/* right div */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: '360px'
          }}
        >
          <div style={{ position: 'relative' }}>
            <h6 style={{ color: 'white' }}>{total}+ Million</h6>
            <div
              style={{
                height: '2px',
                background: '#24F2FF',
                width: '420%',
                position: 'absolute',
                top: '100%',
                right: '0',
                zIndex: 1
              }}
            ></div>
          </div>
          <div style={{ position: 'relative' }}>
            <h6 style={{ color: 'white' }}>{submitted}+ Million</h6>
            <div
              style={{
                height: '2px',
                background: '#86F000',
                width: '500%',
                position: 'absolute',
                top: '100%',
                right: '0',
                zIndex: 1,
                color: '#86F000'
              }}
            ></div>
          </div>
          <div style={{ position: 'relative' }}>
            <h6 style={{ color: 'white' }}>{total - submitted}+ Million</h6>
            <div
              style={{
                height: '2.5px',
                background: '#F5BB00',
                width: '550%',
                position: 'absolute',
                top: '100%',
                right: '0',
                zIndex: 1
              }}
            ></div>
          </div>
        </div>
      </div>

      <div
        style={{
          background: '#55B56D',
          display: 'flex',
          alignItems: 'center',
          width: '20%',
          marginBottom: '10px',
          marginRight: '10px',
          justifyContent: 'center',
          padding: '10px',
          borderRadius: '10px',
          flexDirection: 'column',
          alignSelf: 'flex-end',
          color: '#fff'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h6>ToFu</h6>
          <div
            style={{
              height: '2px',
              background: '#24F2FF',
              width: '6rem',
              margin: '0 8px'
            }}
          ></div>
          <h6 style={{ color: '#24F2FF' }}>35%</h6>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h6>MoFu</h6>
          <div
            style={{
              height: '2px',
              background: '#86F000',
              width: '6rem',
              margin: '0 8px'
            }}
          ></div>
          <h6 style={{ color: '#86F000' }}>25%</h6>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h6>BoFu</h6>
          <div
            style={{
              height: '2px',
              background: '#F7A600',
              width: '6rem',
              margin: '0 8px'
            }}
          ></div>
          <h6 style={{ color: '#F7A600' }}>30%</h6>
        </div>
      </div>
    </div>
  )

  const FunnelMetrics = () => (
    <div
      style={{
        background: '#55B56D',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        marginBottom: '100px',
        marginRight: '10px',
        justifyContent: 'center',
        borderRadius: '10px',
        flexDirection: 'column',
        alignSelf: 'flex-end',
        color: '#fff'
      }}
    >
      {[
        { label: 'ToFu', value: '35%', color: '#24F2FF' },
        { label: 'MoFu', value: '25%', color: '#86F000' },
        { label: 'BoFu', value: '30%', color: '#F7A600' }
      ].map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h6>{item.label}</h6>
          <div
            style={{
              height: '2px',
              background: item.color,
              width: '6rem',
              margin: '0 8px'
            }}
          ></div>
          <h6 style={{ color: item.color }}>{item.value}</h6>
        </div>
      ))}
    </div>
  )

  const FilterOptions = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '20%',
        background:
          'linear-gradient(to bottom,rgb(0, 57, 89), rgb(5, 88, 135))',
        padding: '20px',
        color: 'white'
      }}
    >
      {[
        { label: 'Assets', defaultChecked: true },
        { label: 'Intent Data' },
        { label: 'Industries', defaultChecked: true },
        { label: 'Revenue' },
        { label: 'Company Size', defaultChecked: true },
        { label: 'Customer Questions' },
        { label: 'Install Base' }
      ].map((option, index) => (
        <label
          key={index}
          style={{
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <input
            type='checkbox'
            defaultChecked={option.defaultChecked}
            style={{
              marginRight: '10px',
              width: '20px',
              height: '20px',
              background: '#F7A600',
              borderRadius: '4px',
              accentColor: 'green'
            }}
          />{' '}
          {option.label}
        </label>
      ))}
      <div style={{ marginTop: '20px' }}>
        {[
          'Targeted Display',
          'Email Newsletter Sponsorship',
          'LinkedIn, Twitter, and Sponsored Ad'
        ].map((text, index) => (
          <div key={index} style={{ display: 'block', marginBottom: '10px' }}>
            {text}
          </div>
        ))}
      </div>
    </div>
  )

  const Invitation = () => {
    const [email, setEmail] = useState('')
    const [checked, setChecked] = useState(false)

    const handleEmailChange = e => {
      setEmail(e.target.value) // Update the email state
    }

    const handleCheckboxChange = e => {
      setChecked(e.target.checked) // Update the checkbox state
    }

    const handleSendInvite = () => {
      // Call your API to send the email and checkbox state
      const payload = {
        email,
        isChecked: checked
      }

      // Example API call (replace with your actual API endpoint)
      fetch('/users/sendInvite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then(response => {
          if (response.ok) {
            alert('Invite sent successfully!')
            setEmail('') // Clear the email input
            setChecked(false) // Reset the checkbox
          } else {
            alert('Failed to send invite.')
          }
        })
        .catch(error => {
          console.error('Error:', error)
          alert('An error occurred while sending the invite.')
        })
    }

    return (
      <div className='styled-card'>
        <div className='card-content'>
          <div className='grid-container'>
            <div className='grid-item image-container'>
              <img src='/enjoy.png' alt='enjoy' className='image' />
            </div>
            <div className='grid-item text-container'>
              <h2>Invite your teammates</h2>
              <p className='description'>
                Let your teammates evaluate our features by adding them for your
                trial.
              </p>
            </div>
            <div className='grid-item input-container'>
              <h4 style={{ color: 'black' }}>Add email address</h4>
              <div className='input-grid'>
                <input
                  type='text'
                  value={email}
                  onChange={handleEmailChange}
                  placeholder='Enter email'
                  className='email-input'
                />
                <button className='btn btn-sign' onClick={handleSendInvite}>
                  Invite user
                </button>
              </div>
              <label className='checkbox-label'>
                <input
                  type='checkbox'
                  checked={checked}
                  onChange={handleCheckboxChange}
                />
                Make Super Admin
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Main = () => {
    const containerStyle = {
      display: 'flex',
      justifyContent: 'space-evenly',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gap: '16px',
      width: '100%',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      padding: '16px',
      marginBottom: '32px', // Gap between the two containers
      height: '200px', // Ensure both containers have the same height
      borderRadius: '24px',
      backgroundColor: '#F6F6F6'
    }
    const itemStyle = {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '150px'
    }
    const itemStyle2 = {
      padding: '16px',
      textAlign: 'center',
      display: 'flex',

      minHeight: '150px'
    }
    const headingStyle = {
      color: '#0A58CA'
    }
    const formatDate = dateString => {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
      return new Date(dateString)
        .toLocaleDateString(undefined, options)
        .replace(/\//g, '-')
    }

    // Function to return CSS class based on campaign status
    const getStatusColorClass = status => {
      switch (status) {
        case 'complete':
          return { color: '#55b56d' }
        case 'live':
          return { color: 'black' }
        case 'pending':
          return { color: '#F7A600' }
        default:
          return {}
      }
    }
    return (
      <>
        <div style={{ color: 'white' }}>
          <h3 style={{ color: 'black' }}>Campaign Details</h3>
          <div className="text-center py-2">
          <img
            src={funnel}
            alt='Funnel'
            style={{ maxWidth: '94%', height: 'auto' }}
          />
          </div>
        </div>
        <div style={containerStyle}>
          <div style={itemStyle}>
            <Doughnut
              data={data}
              options={{
                cutout: '80%'
              }}
            />
          </div>
          <div style={itemStyle}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '20px'
              }}
            >
              <div className=''>
                <h5>{lastCampaignData?.campaignTimeline ?? 'N/A'} days</h5>
              </div>
              <div className=''>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#0A58CA',
                      marginRight: '10px',
                      borderRadius: '4px'
                    }}
                  ></div>
                  Campaign Timeline
                </div>
              </div>
            </div>
          </div>
          <div style={itemStyle}>
            {' '}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '20px'
              }}
            >
              <div className=''>
                <h5>{lastCampaignData?.quantity ?? 'N/A'}</h5>
              </div>
              <div className=''>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#F7A600',
                      marginRight: '10px',
                      borderRadius: '4px'
                    }}
                  ></div>
                  Quantity
                </div>
              </div>
            </div>
          </div>
          <div style={itemStyle}>
            {' '}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '20px'
              }}
            >
              <div className=''>
                <h5>{lastCampaignData?.organizationSize ?? 'N/A'}</h5>
              </div>
              <div className=''>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#55B56D',
                      marginRight: '10px',
                      borderRadius: '4px'
                    }}
                  ></div>
                  Company Size
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ color: 'black' }}>{/* <h3>Latest Campaign</h3> */}</div>
        <div style={containerStyle}>
          <div style={itemStyle2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between',
                color: 'black'
              }}
            >
              {/* Objective */}
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Objective</div>
                  <div>{lastCampaignData?.objective ?? 'N/A'}</div>
                </div>
              </div>

              {/* Location */}
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Location</div>
                  <div>{lastCampaignData?.location ?? 'N/A'}</div>
                </div>
              </div>
            </div>
          </div>

          <div style={itemStyle2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              {/* Region */}
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Region</div>
                  <div>{lastCampaignData?.region ?? 'N/A'}</div>
                </div>
              </div>

              {/* Industry Vertical */}
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Country</div>
                  <div>{lastCampaignData?.country ?? 'N/A'}</div>
                </div>
              </div>
            </div>
          </div>

          <div style={itemStyle2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              {/* Job Level */}
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Job Level</div>
                  <div>{lastCampaignData?.jobLevel ?? 'N/A'}</div>
                </div>
              </div>

              {/* Job Title */}
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Funnel</div>
                  <div>{lastCampaignData?.funnel?.join(', ') ?? 'N/A'}</div>
                </div>
              </div>
            </div>
          </div>

          <div style={itemStyle2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              {/* Organization Size */}
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Company Revenue</div>
                  <div>{lastCampaignData?.companyRevenue ?? 'N/A'}</div>
                </div>
              </div>

              {/*leadCategory */}
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Lead Category</div>
                  <div>{lastCampaignData?.leadCategory ?? 'N/A'}</div>
                </div>
              </div>
            </div>
          </div>

          <div style={itemStyle2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              {/* Tactics */}
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Industry Vertical</div>
                  <div>
                    {lastCampaignData?.industryVertical?.[0] ?? 'N/A'} days
                  </div>
                </div>
              </div>

              {/* Report Frequency */}
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Report Frequency</div>
                  <div>{lastCampaignData?.reportFrequency ?? 'N/A'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        background: 'white',
        color: 'black'
        // background: 'linear-gradient(to bottom, #1d5a5d, #0e494d)'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
          borderTop: '1px solid black',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingTop: '50px'
          // background:
          //   'linear-gradient(to bottom, rgb(0, 128, 0), rgb(85, 181, 109)'
        }}
      >
        {/* <SummaryInfo /> */}
        {/* <FunnelVisualization /> */}
        <Main />
        <Invitation />

        {/* <FunnelMetrics /> */}
      </div>
      <FilterOptions />
    </div>
  )
}

export default Panel
