import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ActiveCXOLeedsList } from "./../../../../tables/";

const MActiveLeeds = () => {
  const location = useLocation();
  return (
    <>
      <div class="campaign-top d-flex">
        <div class="camping-hd">
          <h4>Leeds </h4>
          <p>Comprehensive record of all the leeds.</p>
        </div>

        {location.pathname.includes("admin") && (
          <Link to="new" class="btn btn-prfledt">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </Link>
        )}
      </div>
      <ActiveCXOLeedsList />
    </>
  );
};

export default MActiveLeeds;
