import React from "react";
import abmmarketing from "../../assets/images/abm-marketing.png";
import abmsbanner from "../../assets/images/abm-services-banner.jpg";
import Testimonials from "../../components/Testimonials/Testimonials";
import "./AbmServices.css";

const AbmServices = ({ funcNav, funcFooter }) => {
  funcNav(true);
  funcFooter(true);
  return (
    <>
      <section className="maint-abt">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 ps-0 pe-0">
              <div className="maint-abt-inner">
                <img src={abmsbanner} className="img-fluid" alt="" />
                <h3>Our ABM Services</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="maine-banner abt-st">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xll-12">
              <div className="maine-banner-inner">
                <p>
                ABM continues to generate greater business outcomes from top customers and prospects than other types of B2B marketing, With ABM accounting for improvements in engagement with target accounts (92%), new account relationships (86%) and pipeline growth (83%), Companies that have already implemented an ABM strategy now dedicate almost one third (29%) of their entire marketing budget to ABM. Almost three quarters (73%) of those same companies are planning to spend more on ABM in 2020. Overall, an average 15% increase in ABM spending next year.
                </p>
                <p>
                Techplus ABM customizable approach helps to design, define & deliver ABM that works Our ABM Lead Generation will generate accurate, up-to-date contacts from target accounts that are currently looking for a solution similar like your. 
                </p>
                
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xll-12">
            <img src={abmmarketing} className="img-fluid" alt="" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 pt-3">
            <div class="main-counter d-flex">
                  <div class="counter ctmABMWth">
                    <span class="counter-value">98</span>
                    <small>%</small>
                    <h5>Marketers are working to deploy ABM.</h5>
                  </div>
                  <div class="counter ctmABMWth">
                    <span class="counter-value">97</span>
                    <small>%</small>
                    <h5>Marketers say ABM delivers a higher ROI than other marketing strategies.</h5>
                  </div>
                  <div class="counter ctmABMWth">
                    <span class="counter-value">20</span>
                    <small>%</small>
                    <h5>More budget allocated to ABM programs by sales dominated organisations.</h5>
                  </div>
                  <div class="counter ctmABMWth">
                    <span class="counter-value">75</span>
                    <small>%</small>
                    <h5>Hike in SDR-booked meetings was reported by Snowflake.</h5>
                  </div>
                  <div class="counter ctmABMWth">
                    <span class="counter-value">150</span>
                    <small>%</small>
                    <h5>Increase is expected in ABM efforts amongst B2B companies in 2023.</h5>
                  </div>
            </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
    </>
  );
};

export default AbmServices;