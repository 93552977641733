import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const UserPrivateRoutes = (props) => {
  const { Component, department, user, funcNav, funcFooter } = props;
  const location = useLocation();

  if (user && department === "User") {
    return <Component funcNav={funcNav} funcFooter={funcFooter} />;
  } else {
    return <Navigate to="/signin" state={{ history: location?.pathname }} />;
  }
};

export default UserPrivateRoutes;
