import member1 from "../../assets/images/abhijit-shah.jpg";
import member2 from "../../assets/images/sreemoyee-ghosh.jpg";
import member3 from "../../assets/images/vishal-chandane.jpg";
import member4 from "../../assets/images/suresh-reddy.jpg";
import member5 from "../../assets/images/bhoopendra-solanki.jpg";
import member6 from "../../assets/images/bindu-madhav.jpg";
import member7 from "../../assets/images/anita-sanghi.jpg";
import member8 from "../../assets/images/manish-kishore.jpg";

const MemberData = [
  {
    name: "Abhijit Shah",
    position: "Chief Technology Officer, Nippon India Mutual Fund",
    image: member1,
    linkedin: "#",
  },
  {
    name: "Sreemoyee Ghosh Dastidar",
    position: "Head Of IT - Business Applications, Marico",
    image: member2,
    linkedin: "#",
  },
  {
    name: "Vishal Chandane",
    position: "Founder & CEO, cloudxchange.io",
    image: member3,
    linkedin: "#",
  },
  {
    name: "Suresh Reddy",
    position: "Chief Data & Analytics Officer, GE Vernova, Power Conversion",
    image: member4,
    linkedin: "#",
  },
  {
    name: "Bhoopendra Solanki",
    position: "Chief Information Officer, Sakra World Hospital",
    image: member5,
    linkedin: "#",
  },
  {
    name: "Bindu Madhav",
    position: "Chief Financial Officer, Ajax Engineering Pvt Ltd",
    image: member6,
    linkedin: "#",
  },
  {
    name: "Anita Sanghi",
    position:
      "Senior Vice President and CFO, NTT Data Global Delivery Services",
    image: member7,
    linkedin: "#",
  },
  {
    name: "Manish Kishore",
    position: "Designation, Company Name",
    image: member8,
    linkedin: "#",
  },
];

export default MemberData;
