import React, { useEffect, useState } from "react";
import axios from "axios";

const Faqs = () => {
  const [faq, setfaq] = useState([]);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get("/faqs");
        setfaq(response?.data?.faq_list);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);

  return (
    <>
      <section className="faqs">
        <div className="container">
          <h3>FAQ</h3>

          <div className="row">
            <div className="col-md-12">
              <div className="accordion" id="myAccordion">
                {faq && faq.length > 0 ? (
                  faq.map((e, index) => {
                    const accordionId = `collapse${index + 1}`;

                    return (
                      <div className="accordion-item" key={index}>
                        <h2
                          className="accordion-header"
                          id={`heading${index + 1}`}
                        >
                          <button
                            type="button"
                            className="accordion-button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${accordionId}`}
                          >
                            {e.title}
                          </button>
                        </h2>
                        <div
                          id={accordionId}
                          className="accordion-collapse collapse"
                          data-bs-parent="#myAccordion"
                        >
                          <div className="card-body">
                            <p>{e.description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No FAQs available</p>
                )}
                {/* Hardcoded FAQ */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="hardcodedHeading">
                    <button
                      type="button"
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#hardcodedCollapse"
                    >
                      Why LeadXchange for Lead Generation?
                    </button>
                  </h2>
                  <div
                    id="hardcodedCollapse"
                    className="accordion-collapse collapse"
                    data-bs-parent="#myAccordion"
                  >
                    <div className="card-body">
                      <p>
                        Choosing LeadXchange for your lead generation needs can
                        revolutionize the way you approach potential clients.
                        Here’s why:
                      </p>
                      <div className="flex flex-col gap-3">
                        <p>
                          <b>1. Targeted Reach:</b> LeadXchange’s sophisticated
                          algorithms ensure your marketing efforts reach the
                          right audience, saving you time and resources.
                        </p>
                        <p>
                          <b>2. Quality Leads:</b> With a focus on high-quality
                          data, LeadXchange delivers leads that are more likely
                          to convert, boosting your ROI.
                        </p>
                        <p>
                          <b>3. User-Friendly Interface:</b> The platform is
                          designed with ease of use in mind, allowing you to
                          manage and track your leads efficiently.
                        </p>
                        <p>
                          <b>4. Comprehensive Analytics:</b> Detailed reports
                          and analytics help you understand your campaign
                          performance and make data-driven decisions.
                        </p>
                        <p>
                          <b>5. Scalability:</b> Whether you’re a startup or an
                          established enterprise, LeadXchange scales with your
                          business, providing flexible solutions that grow with
                          you.
                        </p>
                        <p>
                          <b>6. Excellent Support:</b> Their dedicated support
                          team is always ready to assist, ensuring you get the
                          most out of the platform.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End of Hardcoded FAQ */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
