import React, { useState } from "react";
import { FaPlus } from 'react-icons/fa';
import { FaMinus } from "react-icons/fa";


const MultipleValueInput = ({ values, setValues }) => {
  const [inputValue, setInputValue] = useState("");

  const handleAddValue = () => {
    if (inputValue.trim() === "") return;
    const updatedValues = [...values, inputValue.trim()];
    setValues(updatedValues);
    setInputValue("");
  };

  const handleRemoveValue = (index) => {
    const updatedValues = [...values];
    updatedValues.splice(index, 1);
    setValues(updatedValues);
  };

  return (
    <div >
      <ul className="list-group mb-3" >
        {values.map((value, index) => (
          <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
         
            {value} 
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => handleRemoveValue(index)}
            >
            <FaMinus size={20}/>
            </button>
          </li>
        ))}
      </ul>
      <div className="input-group" style={{display:"flex", alignItems:"center"}}>
        <input
          type="text"
          className="form-control"
          value={inputValue} 
          onChange={(e) => setInputValue(e.target.value)}
        />
        <div className="input-group-append">
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleAddValue}
          >
            <FaPlus />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MultipleValueInput;
