import React, { useState } from "react";
import { Link } from "react-router-dom";
import InputArea from "../../common/inputArea";

function RejectLeed({ _id, onStatus }) {
  const [open, setOpen] = useState(false); // Initialize open state
  const [comments, setComments] = useState("");

  const handleOpen = () => {
    setOpen(true); // Open the modal
  };

  const handleClose = () => {
    setOpen(false); // Close the modal
    setComments("");
  };

  const handleSubmit = () => {
    // Call the server
    let data = {
      _id: _id,
      comments,
      status: "Rejected",
    };

    console.log("Submitted", data);
    onStatus(data);
    handleClose();
  };

  const handleSearch = (e) => {
    setComments(e.target.value); // Update comments state on input change
  };

  return (
    <>
      <Link to="#" className="btn btn-sm btn-danger" onClick={handleOpen}>
        Reject
      </Link>

      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 class="modal-title">Reason for Rejection </h4>{" "}
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div class="aply-contact">
                <div class="row">
                  <div className="col-md-12">
                    {" "}
                    <InputArea
                      type="text"
                      name="Comments"
                      value={comments}
                      label="Comments"
                      onChange={handleSearch}
                      error={""}
                    />
                  </div>
                  <div class="col-lg-12 pt-3">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      class="default-btn apply-btn disabled"
                      style={{ pointerEvents: "all", cursor: "pointer" }}
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={open ? "modal-backdrop " : ""}></div>
    </>
  );
}

export default RejectLeed;
