// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logine {
  border-radius: 25px;
  max-width: 340px;
  margin: 0 auto;
  padding: 20px 20px 0;
  border: 1px solid #e1dcdc;
  padding-bottom: 20px;
  background-color: #fff;
  /*box-shadow: 0px 0px 4px 2px #00a1e040;*/
  box-shadow: 0 0 5px 1px #e2e2e2;
  position: relative;
}
.logine label {
  font-size: 14px;
  font-weight: 400;
  color: #212529;
}
label.form-check-label {
  margin-top: 10px;
  margin-bottom: 20px;
}
form.logine .form-control {
  height: 38px;
  border-radius: 14px;
}
.logine button {
  padding: 7px 124px;
  color: #fff;
  background-color: #1f4572;
  border-color: #1f4572;
  margin-top: 15px;
  border-radius: 14px;
}
.logine button:hover {
  color: #fff;
  background-color: #1f4572;
}
`, "",{"version":3,"sources":["webpack://./src/forms/AdminForm/AdminForm.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,oBAAoB;EACpB,yBAAyB;EACzB,oBAAoB;EACpB,sBAAsB;EACtB,yCAAyC;EACzC,+BAA+B;EAC/B,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,yBAAyB;AAC3B","sourcesContent":[".logine {\r\n  border-radius: 25px;\r\n  max-width: 340px;\r\n  margin: 0 auto;\r\n  padding: 20px 20px 0;\r\n  border: 1px solid #e1dcdc;\r\n  padding-bottom: 20px;\r\n  background-color: #fff;\r\n  /*box-shadow: 0px 0px 4px 2px #00a1e040;*/\r\n  box-shadow: 0 0 5px 1px #e2e2e2;\r\n  position: relative;\r\n}\r\n.logine label {\r\n  font-size: 14px;\r\n  font-weight: 400;\r\n  color: #212529;\r\n}\r\nlabel.form-check-label {\r\n  margin-top: 10px;\r\n  margin-bottom: 20px;\r\n}\r\nform.logine .form-control {\r\n  height: 38px;\r\n  border-radius: 14px;\r\n}\r\n.logine button {\r\n  padding: 7px 124px;\r\n  color: #fff;\r\n  background-color: #1f4572;\r\n  border-color: #1f4572;\r\n  margin-top: 15px;\r\n  border-radius: 14px;\r\n}\r\n.logine button:hover {\r\n  color: #fff;\r\n  background-color: #1f4572;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
