import http from "./httpService";

const apiEndpoint =  "/blog/";

function authUrl(id) {
  return `${apiEndpoint}${id}`;
}

// Get the Blogs Data

export async function getBlogs() {
  return await http.get(apiEndpoint);
}

export async function getBlog(id) {
  return http.put(authUrl(id));
}

//  Create and Update Blog

export function saveBlogs(blog) {
  return http.post(apiEndpoint, blog);
}

// Update the status of the Blog Data

export async function statusBlog(id, status) {
  return http.post(authUrl(id), { status: status });
}

// Delete the Blog Data

export async function deleteBlog(id) {
  return http.delete(authUrl(id));
}
