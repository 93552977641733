import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { RiLockPasswordLine } from "react-icons/ri";


const ForgotPassword = ({ funcNav, funcFooter }) => {
  const [resetEmail, setResetEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail) {
      toast.error("Please enter a valid email address", { autoClose: 3000 });
      return;
    }

    const generatingToastId = toast.info("Generating reset token...", {
      autoClose: false,
    }); // Save the ID of the "Generating reset token..." toast

    try {
      // If the user exists, proceed to send the reset email
      const response = await axios.post("users/forgotPassword", {
        email: resetEmail,
      });

      if (response.status === 200) {
        const { token } = response.data;
        toast.update(generatingToastId, {
          render: "Reset token sent. Please check your email.",
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        }); // Update the toast message to indicate successful token generation
      } else {
        toast.error("Failed to generate reset token", { autoClose: 3000 });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred", { autoClose: 3000 });
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setResetEmail(email);
    setIsValidEmail(validateEmail(email));
  };

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  funcNav(true);
  funcFooter(true);

  return (
    <div className="sales-left">
      <form
        className="logine"
        name="form1"
        id="form1"
        onSubmit={handleSubmit}
        style={{
          maxWidth: "400px",
          margin: "0 auto",
          borderRadius: "16px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
          padding: "20px",
        }} // Inline CSS for form
      >

<div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "600",
            fontSize: "1.5rem",
            lineHeight: "2.25rem",
          }}
        >
          <RiLockPasswordLine size={40} color="#0A58CA" />
          <h5>Forgot Password</h5>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
       <p style={{ margin: "0", color: "#A9A9A9" }}>
  A reset token has been sent to you. Please enter the
</p>
<p style={{ margin: "0", marginBottom: "10px", color: "#A9A9A9" }}>email below.</p>

          </div>
        </div>
        
        
        <div className="form-group" style={{ width: "100%" }}>
          <label className="control-label">Enter Your Email</label>
          <input
            type="email"
            className={`form-control ${isValidEmail ? "" : "is-invalid"}`} // Dynamically add class based on email validity
            name="resetEmail"
            value={resetEmail}
            onChange={handleEmailChange}
            required
            style={{
              width: "100%", // Increase input width
              padding: "10px",
              borderRadius: "5px",
            }} // Inline CSS for input
          />
          {!isValidEmail && (
            <div className="invalid-feedback">
              Please enter a valid email address.
            </div>
          )}
        </div>

        <button
          type="submit"
          className="btn btn-primary btre"
          name="submit"
          id="submit"
          style={{ width: "100%", padding: "10px", borderRadius: "5px" }} // Inline CSS for button
        >
          Submit
        </button>
        <h6>
          For New Users ? <Link to="/signup">Signup</Link> |{" "}
          <Link to="/signin">Login</Link>
        </h6>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link
              style={{ paddingRight: "10px", color: "black", font: "10px" }}
              to="/termscondition"
            >
              Terms & Condition
            </Link>

            <Link to="/privacy" style={{ font: "10px" }}>
              Privacy Policy
            </Link>
          </span>
        </div>
      </form>

      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
