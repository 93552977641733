import React, { useState } from 'react'
import TableHeader from './tableHeader'
import TableBody from './tableBody'
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from 'react-icons/io'

const Table12 = ({ columns, data }) => {
  const [pageNumber, setPageNumber] = useState(1) // Initialize to page 1
  const userPerPage = 10 // Adjust as needed based on your design
  const pageCount = Math.ceil(data.length / userPerPage)

  const changePage = page => {
    setPageNumber(page)
  }

  // Calculate the range of items to display for the current page
  const startIndex = (pageNumber - 1) * userPerPage
  const endIndex = Math.min(startIndex + userPerPage, data.length)
  const currentPageData = data.slice(startIndex, endIndex)
  return (
    <div className='table-responsive'>
      <table className='table table-border'>
        <TableHeader columns={columns} />
        <TableBody columns={columns} data={currentPageData} />
      </table>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px'
        }}
      >
        <div>
          <button
            onClick={() => changePage(pageNumber - 1)}
            disabled={pageNumber === 1}
            style={{
              color: 'black',
              padding: '5px 10px',
              borderRadius: '5px',
              border: 'none',
              marginRight: '10px'
            }}
          >
            <span>
              <IoIosArrowRoundBack size={25} />
            </span>
            Previous
          </button>
          <button
            onClick={() => changePage(pageNumber + 1)}
            disabled={pageNumber === pageCount}
            style={{
              color: 'black',
              padding: '5px 10px',
              borderRadius: '5px',
              border: 'none'
            }}
          >
            Next
            <span>
              <IoIosArrowRoundForward size={25} />
            </span>
          </button>
        </div>
        <div>
          <span style={{ color: '#0A58CA' }}>
            Page {pageNumber} of {pageCount}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Table12
