import React from "react";
import { Link } from "react-router-dom";
import Table from "../../common/table";
import Delete from "../../common/delete";
import Status from "./../../common/status";
import NoImageAvailable from "../../assets/images/NoImageAvailable.jpg";

const IntentTable = ({ items, onDelete, onStatus }) => {
  const columns = [
    {
      path: "user_details",
      label: "User Details",
      content: (item) => (
        <>
          <p style={{ whiteSpace: "break-spaces", width: "220px" }}>
            Name : {item.name} <br />
            Email : {item.email} <br />
            Mobile : {item.mobile} <br />
          </p>
        </>
      ),
    },
    {
      path: "address_details",
      label: "Address Details",
      content: (item) => (
        <>
          <p style={{ whiteSpace: "break-spaces", width: "150px" }}>
            City : {item.city} <br />
            State : {item.state} <br />
            Country : {item.country} <br />
          </p>
        </>
      ),
    },
    {
      path: "social_details",
      label: "Social Details",
      content: (item) => (
        <>
          <p style={{ whiteSpace: "break-spaces", width: "210px" }}>
            Website : {item.website} <br />
            Linkedin : {item.linkedin} <br />
            Twitter : {item.twitter} <br />
            Facebook : {item.facebook} <br />
          </p>
        </>
      ),
    },

    {
      path: "status",
      label: "Status",
      content: (item) => (
        <>
          <Status status={item.status} onClick={() => onStatus(item)} />
        </>
      ),
    },
    {
      key: "view",
      label: "View",
      content: (item) => (
        <>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ flexWrap: "wrap", width: "120px", height: "100px" }}
          >
            <Link className="btn-veiw" to={`${item._id}`}>
              <i className="fa fa-eye" aria-hidden="true"></i>
            </Link>
            <Link
              className="btn-veiw"
              to={`active/${item._id}`}
              state={{ intent_id: item._id, user_id: item.user_id }}
            >
              <i className="fa fa-heart-o" aria-hidden="true"></i>
            </Link>
            {/* <Delete onClick={() => onDelete(item)} /> */}
          </div>
        </>
      ),
    },
  ];

  return <Table data={items} columns={columns} />;
};

export default IntentTable;
