import React from "react";

const BlogDetails = ({ funcNav, funcFooter }) => {
    funcNav(true);
    funcFooter(true);       
  return (
    <>
      <section className="blog-banner">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="blog-banners" data-aos="fade-right"></div>
          </div>
        </div>
      </section>
      <section className="universities latest">
	<div className="container">
		<div className="row">
			<div className="col-md-9 pe-4">
				<div className="universities-left aos-init aos-animate" data-aos="fade-down">
   <h2>Universities in Barcelona</h2>
   <ul>
      <li><a href="#">March 12, 2022</a></li>
      <li><a href="#"><i className="fa fa-heart-o" aria-hidden="true"></i>52 Like </a></li>
      <li><a href="#"><i className="fa fa-share-alt" aria-hidden="true"></i> 10 Share</a></li>
      <li><a href="#"> <i className="fa fa-bookmark-o" aria-hidden="true"></i>Bookmark</a></li>
   </ul>
   <img src="<?php echo base_url();?>images/blog-list-1.png"/>
   <p>Nowadays, it is explicitly impossible to imagine your new house only as a bland structure of bricks and wood. Today, hiring a professional interior designer so as to shape the whole outlook of your house, give it some unique features, has become an indispensable part of building yourself a comfortable and attractive home. If you’re planning to refurbish your old house there is no need to put yourself to trouble, thinking about new ideas, you can just book yourself the best interior designer. But, if you’re thinking of pursuing this profession yourself and test your creative imagination and do your research before signing up for a career in this field. Here is a comprehensive blog explaining how to become an interior designer!</p>
   <div className="whatp aos-init" data-aos="fade-right">
      <h3>What does an Interior Designer do?</h3>
      <p>The primary job of Interior designer involves making plans, outlining new designs and overall furnishing of interiors of residential houses, commercial sites and other buildings. They make designs which are aesthetic in nature and align with the ideas and vision of clients while simultaneously helping to improve their comfort and lifestyle. Their job demands them to coordinate comprehensively with other professionals, involving contractors, architects, engineers so as to ensure every task is done coherently in a successful manner. Interior designer also advises a client on different designing elements like spacing, layouts, furniture, decor and colour combinations. Constantly holding parleys with clients to talk about factors that could potentially affect the interior planning of the house is also a significant component of this job.</p>
      <h3>Skills Required</h3>
      <p>Interior designers don’t just need to have the ability to deal with technical details but must always work with their team. Teamwork always brings out the best results. They must have an analytical mind, along with visual sensitivity. Interior designers must work under the prescribed budget and have good communication skills to deal with potential employers. With the apt knowledge of color, texture, and material, they can bring out the best outcomes.</p>
      <h3>Top Interior Designer Recruiters</h3>
      <p>To get the best career opportunities, the students must prepare their portfolio. A portfolio is a collection of work done by the student during their period of study. It must be highly attractive containing a collection of pictures, sketches, drawings, and designs. One of the top interior designing firms in India are:</p>
      <ul>
         <li>1. La Sorogeeka</li>
         <li>2. Aamir &amp; Hameeda</li>
         <li>3. Design Qube</li>
         <li>4. Raja Aederi</li>
         <li>5. Morph Design</li>
         <li>6. Savio &amp; Rupa Interior Concepts</li>
      </ul>
      <p>The average salary offered to freshers after finishing a Bachelor’s degree in Bachelor’s is 93,000 INR to 6,00,000 INR per annum. With the experience, interior designers can earn 10-15 lakhs per annum</p>
      <div className="mb-3 text-msg">
         <textarea className="form-control" placeholder="Leave a comment" id="exampleFormControlTextarea1" rows="6"></textarea>
         <button type="submit" className="btn btn-primary btn-sbmt">SUBMIT</button>
      </div>
   </div>
</div>
			</div>
			
			<div className="col-md-3 ps-3">
				<div className="universities-right universities-right-blg">
						
						<div className="input-group mb-3 serach-btn" data-aos="zoom-in-left">
							<input type="text" className="form-control" placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
							<span className="input-group-text" id="basic-addon2"><i className="fa fa-search" aria-hidden="true"></i></span>
						</div>
						
						<div className="categories" data-aos="zoom-in-left">
							<h3>Categories</h3>
							<ul>
								<li>Fitness<span>22</span></li>
								<li>Gym<span>05</span></li>
								<li>Diet <span>12</span></li>
								<li>Yoga <span>16</span></li>
								
							</ul>
						</div>
						
						<div className="recent-blog" data-aos="zoom-in-left">
							<h3>Recent Post</h3>
							<div className="row">
								<div className="col-md-5">
									<div className="recent-blog-left">
										<img src=""/>
									</div>
									<div className="recent-blog-left">
										<img src=""/>
									</div>
									
									<div className="recent-blog-left">
										<img src=""/>
									</div>
									
									<div className="recent-blog-left">
										<img src=""/>
									</div>
									
								</div>
								
								<div className="col-md-7">
									<div className="recent-blog-right">
										<h5>SEPTEMBER 6, 2021</h5>
										<h4>How to Become an Interior Designer?</h4>
									</div>
									<div className="recent-blog-right">
										<h5 className="sept">SEPTEMBER 6, 2021</h5>
										<h4>How to Become an Interior Designer?</h4>
									</div>
									
									<div className="recent-blog-right">
										<h5 className="sept">SEPTEMBER 6, 2021</h5>
										<h4>How to Become an Interior Designer?</h4>
									</div>
									
									<div className="recent-blog-right">
										<h5 className="sept">SEPTEMBER 6, 2021</h5>
										<h4>How to Become an Interior Designer?</h4>
									</div>
									
								</div>
							</div>
						</div>
						
						<div className="tags" data-aos="zoom-in-left">
							<h3>Tags</h3>
							
							<ul>
								<li><a href="#">Study Abroad</a></li>
								<li><a href="#">Careers</a></li>
								<li><a href="#">Exams</a></li>
								<li><a href="#">How-To Guides</a></li>
								<li><a href="#">Success Stories</a></li>
							</ul>
						</div>

				
				</div>
			</div>
		</div>
	</div>
</section>
    </>
  );
};

export default BlogDetails;
