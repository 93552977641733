import React, { useState } from "react";
import funnel from "../../assets/images/funnel.png";
import demand from "../../assets/images/demand.png";
import account from "../../assets/images/account.png";
import ServicesButton from "../../common/ServicesButton";

const Services = () => {
  // Define the maximum number of characters to display initially for content paragraphs
  const contentMaxLength = 220;

  // Define the maximum number of characters to display initially for Outcome paragraphs
  const outcomeMaxLength = 100;

  const initialShowMoreState = Array(6).fill(false); // Adjust the size based on the number of paragraphs

  const [showMore, setShowMore] = useState(initialShowMoreState);

  const constom = {
    height: "auto", // Set the desired height here
  };
  const h5 = {
    height: "50px",
  };

  const toggleContent = (index) => {
    setShowMore((prevShowMore) => {
      const newShowMore = [...prevShowMore]; // Create a copy of the array
      newShowMore[index] = !newShowMore[index]; // Toggle the state for the clicked paragraph
      return newShowMore;
    });
  };

  const renderParagraph = (content, maxLength, index) => {
    return (
      <>
        {showMore[index] ? content : `${content.slice(0, maxLength)}...`}
        <span
          onClick={() => toggleContent(index)}
          style={{ color: "yellow", cursor: "pointer" }}
        >
          {showMore[index] ? " Show Less" : " Show More"}
        </span>
      </>
    );
  };

  const sampleContentLeadGen =
    "Enhance demand generation by implementing targeted top-of-funnel campaigns and strategic lead nurturing. This comprehensive program is designed to assist B2B teams in leveraging their content to generate opt-in contacts that meet specific industry criteria, such as roles, company affiliation, and company size. Additionally, a crucial nurture solution is integrated to ensure ongoing engagement and readiness.";

  const sampleContentDemandGen =
    "Enhance your demand generation efforts by leveraging qualified leads that demonstrate in-market buying behaviors. This program is designed specifically for B2B teams who want to leverage their content to generate opted-in leads that align with their Ideal Customer Profile (ICP). Additionally, we offer an enhanced nurture solution to ensure continuous engagement and readiness.";

  const sampleContentABX =
    "Secure opt-in permission from key decision makers through targeted company profiling and personalized engagement. Our ABM-centered program is designed to pinpoint, engage, and obtain opt-in permission from the most relevant decision makers involved in the purchasing process for your solution(s). Whether it's from your list of named accounts or through the identification of ideal accounts in collaboration with you, we ensure precision targeting and tailored engagement.";

  const sampleContentFullFunnel =
    "Drive and sustain decision maker engagement throughout the entire buyer's journey. Our comprehensive program targets and connects with prospects who have a keen interest in specific topics that align with their unique requirements. This results in exceptional performance at various stages of your buyers' readiness and journey.";

  const sampleContentContentCreation =
    "Our team of professional writers is dedicated to helping you develop content for various channels including blogs, websites, white papers, and more. With our in-house experts, we ensure that your content meets all your requirements and is tailored to your specific needs. Let us assist you in creating compelling and engaging content that drives results.";

  const sampleContentActiveLeads =
    "With LX Active Leads, you can say goodbye to cold calling and hello to a steady stream of qualified, engaged leads. Our cutting-edge technology and strategic approach ensure that the leads you receive are not only active but also highly motivated to make a purchase. What sets LX Active Leads apart from the competition is our commitment to quality. We meticulously vet each lead to ensure that they meet your specific criteria, saving you the hassle of sifting through irrelevant prospects. Our comprehensive database is constantly updated, guaranteeing that you get access to the most up-to-date and accurate information.";

  const one =
    "Optimize Your Opt-in Contacts to Match Your Specific Criteria Boost Engagement and Achieve Your Marketing and Sales Funnel Goals with Ease Discover Qualified and High-Quality Leads Ready for Further Nurturing";

  const two =
    "Prioritized opt-in contacts who have actively demonstrated interest - Increased engagement driven by buyer and account intelligence - Qualified leads of high quality, prepared for nurturing and follow-up";

  const three =
    "Generate verified leads by targeting specific job titles or roles at your named and desired accounts. - Accelerate early-stage engagement with key decision-makers in the buying committee. - Foster deeper engagement and amplify your brand through nurturing initiatives that extend beyond the email inbox";

  const four =
    "Optimize your marketing and sales funnel with opt-in contacts that perfectly match your specific criteria. Experience higher engagement and achieve your goals with qualified, high-quality leads that are ready to be nurtured further.";

  const five =
    "Our editorial team collaborates with you to refine the theme, topic, title, and focus of your content deliverables, leveraging industry insights to ensure maximum impact. Through our extensive network, we help you enhance reach and engagement with your unique audiences by strategically placing your content in the most targeted publications. Additionally, we offer full campaign integration and provide content assets in shareable formats of your choice, empowering you to connect with your audience effectively.";

  const six =
    "What sets LX Active Leads apart is its ability to provide you with leads that are not only active but also highly engaged. Our platform tracks the behavior and interactions of potential customers, ensuring that you only receive leads who are truly interested in your products or services.";

  return (
    <>
      <section className="ultimate">
        <div className="container">
          <div className="row">
            <div className="col-md-4" style={constom}>
              <div className="ultimate-inner ">
                <div className="text-center">
                  <img src={funnel} className="pb-2" alt="Lead Gen Icon" />
                </div>
                <h5 style={h5}>Lead Gen - Top of Funnel with LX Tofu</h5>
                <p>
                  {renderParagraph(sampleContentLeadGen, contentMaxLength, 0)}
                </p>
                <h5 className="pt-3 pb-1">Outcome</h5>
                <p>{renderParagraph(one, outcomeMaxLength, 6)}</p>
                <ServicesButton
                 
                  text="Click to Drive Campaign"
                />
              </div>
            </div>
            <div className="col-md-4" style={constom}>
              <div className="ultimate-inner ">
                <div className="text-center">
                  <img src={demand} className="pb-2" alt="Demand Generation" />
                </div>
                <h5 style={h5}>Demand Generation with LX Intent</h5>
                <p>
                  {renderParagraph(sampleContentDemandGen, contentMaxLength, 1)}
                </p>
                <h5 className="pt-3 pb-1">Outcome</h5>
                <p>{renderParagraph(two, outcomeMaxLength, 7)}</p>
                <ServicesButton
                 
                  text="Click to Drive Campaign"
                />
              </div>
            </div>
            <div className="col-md-4" style={constom}>
              <div className="ultimate-inner ">
                <div className="text-center">
                  <img
                    src={account}
                    className="pb-2"
                    alt="Account Based Xperience"
                  />
                </div>
                <h5>Account Based Xperience ( ABX) with LX ABM </h5>
                <p>{renderParagraph(sampleContentABX, contentMaxLength, 2)}</p>
                <h5 className="pt-3 pb-1">Outcome</h5>
                <p>{renderParagraph(three, outcomeMaxLength, 8)}</p>
                <ServicesButton
                 
                  text="Click to Drive Campaign"
                />
              </div>
            </div>
            <div className="col-md-4" style={constom}>
              <div className="ultimate-inner ">
                <div className="text-center">
                  <img
                    src={funnel}
                    className="pb-2"
                    alt="Full Funnel with Customer Journey"
                  />
                </div>
                <h5>
                  Full Funnel with Customer Journey with LX Full Funnel Lead
                </h5>
                <p>
                  {renderParagraph(
                    sampleContentFullFunnel,
                    contentMaxLength,
                    3
                  )}
                </p>
                <h5 className="pt-3 pb-1">Outcome</h5>
                <p>{renderParagraph(four, outcomeMaxLength, 9)}</p>
                <ServicesButton
                 
                  text="Click to Drive Campaign"
                />
              </div>
            </div>
            <div className="col-md-4" style={constom}>
              <div className="ultimate-inner ">
                <div className="text-center">
                  <img src={funnel} className="pb-2" alt="Content Creation" />
                </div>
                <h5>Content Creation with CXO TV New network</h5>
                <p>
                  {renderParagraph(
                    sampleContentContentCreation,
                    contentMaxLength,
                    4
                  )}
                </p>
                <h5 className="pt-3 pb-1">Outcome</h5>
                <p>{renderParagraph(five, outcomeMaxLength, 10)}</p>
                <ServicesButton
                 
                  text="Click to Drive Campaign"
                />
              </div>
            </div>
            <div className="col-md-4" style={constom}>
              <div className="ultimate-inner ">
                <div className="text-center">
                  <img src={funnel} className="pb-2" alt="Active Leads" />
                </div>
                <h5 style={h5}>Active Leads with LX Active Leads </h5>
                <p>
                  {renderParagraph(
                    sampleContentActiveLeads,
                    contentMaxLength,
                    5
                  )}
                </p>
                <h5 className="pt-3 pb-1">Outcome</h5>
                <p>{renderParagraph(six, outcomeMaxLength, 11)}</p>
                <ServicesButton
                 
                  text="Click to Drive Campaign"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
