// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-item {
  height: 200px; /* Set a fixed height for each item */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.pending,
.completed,
.rejected,
.total {
  color: white;
  border-radius: 3px;
  padding: 8px 10px;
  margin: 5px;
}

.pending {
  background-color: #ffcc00; /* Yellow for pending */
}

.completed {
  background-color: #00cc00; /* Blue for completed */
}

.total {
  background-color: #666eff; /* Red for total */
}
.rejected {
  background-color: red; /* Red for total */
}

.dashboard-item.item-1 {
  display: flex;
  justify-content: space-around;
  align-items: center; 
  height: 50px;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/UserDashboard/pages/Mylisting/Mylisting.css"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE,qCAAqC;EACpD,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;AACA;;;;EAIE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,yBAAyB,EAAE,uBAAuB;AACpD;;AAEA;EACE,yBAAyB,EAAE,uBAAuB;AACpD;;AAEA;EACE,yBAAyB,EAAE,kBAAkB;AAC/C;AACA;EACE,qBAAqB,EAAE,kBAAkB;AAC3C;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".dashboard-item {\r\n  height: 200px; /* Set a fixed height for each item */\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  font-size: 24px;\r\n}\r\n.pending,\r\n.completed,\r\n.rejected,\r\n.total {\r\n  color: white;\r\n  border-radius: 3px;\r\n  padding: 8px 10px;\r\n  margin: 5px;\r\n}\r\n\r\n.pending {\r\n  background-color: #ffcc00; /* Yellow for pending */\r\n}\r\n\r\n.completed {\r\n  background-color: #00cc00; /* Blue for completed */\r\n}\r\n\r\n.total {\r\n  background-color: #666eff; /* Red for total */\r\n}\r\n.rejected {\r\n  background-color: red; /* Red for total */\r\n}\r\n\r\n.dashboard-item.item-1 {\r\n  display: flex;\r\n  justify-content: space-around;\r\n  align-items: center; \r\n  height: 50px;\r\n  padding: 20px;\r\n  border-radius: 5px;\r\n  margin-bottom: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
