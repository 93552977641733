import React, { useState, useEffect } from 'react'
import { Bar, Doughnut } from 'react-chartjs-2'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

const MyCampigndata = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [campaignData, setCampaignData] = useState(null)
  const location = useLocation()
  const campaignId = location.pathname.split('/').pop() // Extract campaign ID

  console.log('campaignData', campaignData)
  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const response = await axios.get(
          `/compaign/getCampaignById/${campaignId}`
        )
        setCampaignData(response.data)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError('Campaign not found')
        } else {
          setError(error.message)
        }
      } finally {
        setLoading(false)
      }
    }

    fetchCampaignData()
  }, [campaignId])

  // Check if loading is in progress
  if (loading) {
    return <div>Loading...</div>
  }

  // Check if there's an error
  if (error) {
    return <div>{error}</div>
  }

  // If there's no campaign data, display a message
  if (!campaignData) {
    return <div>No data available for this campaign.</div>
  }

  const formatDate = dateString => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    return new Date(dateString)
      .toLocaleDateString(undefined, options)
      .replace(/\//g, '-')
  }

  // Function to return CSS class based on campaign status
  const getStatusColorClass = status => {
    switch (status) {
      case 'complete':
        return { color: '#55b56d' }
      case 'live':
        return { color: 'black' }
      case 'pending':
        return { color: '#F7A600' }
      default:
        return {}
    }
  }

  const rejectedFromCSV =
    campaignData.csv[0]?.data?.filter(item => item.status === 'rejected')
      .length ?? 0
  const approvedFromCSV =
    campaignData.csv[0]?.data?.filter(item => item.status === 'approved')
      .length ?? 0
  const reviewFromCSV =
    campaignData.csv[0]?.data?.filter(item => item.status === 'in review')
      .length ?? 0
  const submmitedFromCSV = campaignData?.csv[0]?.data?.length ?? 0

  // Extract data for Doughnut chart
  const { accepted, submitted, rejected } = campaignData

  // Dummy data for the donut chart
  const data = {
    datasets: [
      {
        data: [
          submmitedFromCSV,
          approvedFromCSV,
          rejectedFromCSV,
          reviewFromCSV
        ],
        backgroundColor: ['#0A58CA', '#55B56D', '#F7A600', '#FE0000']
      }
    ]
  }
  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: '16px',
    width: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '16px',
    marginBottom: '32px', // Gap between the two containers
    height: '200px', // Ensure both containers have the same height
    borderRadius: '24px',
    backgroundColor: '#F6F6F6'
  }

  const itemStyle = {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '150px'
  }
  const itemStyle2 = {
    padding: '16px',
    textAlign: 'center',
    display: 'flex',

    minHeight: '150px'
  }
  const headingStyle = {
    color: '#0A58CA'
  }

  return (
    <>
      <div>
        <div style={containerStyle}>
          <div style={itemStyle}>
            <Doughnut data={data} />
          </div>
          <div style={itemStyle}>
            {' '}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '20px'
              }}
            >
              <div className=''>
                <h3>{submmitedFromCSV}</h3>
              </div>
              <div className=''>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#22CFCF',
                      marginRight: '10px',
                      borderRadius: '4px'
                    }}
                  ></div>
                  Total
                </div>
              </div>
            </div>
          </div>
          <div style={itemStyle}>
            {' '}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '20px'
              }}
            >
              <div className=''>
                <h3>{approvedFromCSV}</h3>
              </div>
              <div className=''>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#0A58CA',
                      marginRight: '10px',
                      borderRadius: '4px'
                    }}
                  ></div>
                  Accepted
                </div>
              </div>
            </div>
          </div>
          <div style={itemStyle}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '20px'
              }}
            >
              <div className=''>
                <h3>{rejectedFromCSV}</h3>
              </div>
              <div className=''>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#F7A600',
                      marginRight: '10px',
                      borderRadius: '4px'
                    }}
                  ></div>
                  Rejected
                </div>
              </div>
            </div>
          </div>
          <div style={itemStyle}>
            {' '}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '20px'
              }}
            >
              <div className=''>
                <h3>{reviewFromCSV}</h3>
              </div>
              <div className=''>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#55B56D',
                      marginRight: '10px',
                      borderRadius: '4px'
                    }}
                  ></div>
                  Pending
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={containerStyle}>
          <div style={itemStyle2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Campaign Name</div>
                  <div>
                    {campaignData.campaignName && (
                      <span>{campaignData.campaignName}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Lead Generation:</div>
                  <div>{campaignData.leadGeneration}</div>
                </div>
              </div>
            </div>
          </div>
          <div style={itemStyle2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Campaign ID</div>
                  <div>{campaignData._id}</div>
                </div>
              </div>
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Start Date:</div>
                  <div>{formatDate(campaignData.startDate)}</div>
                </div>
              </div>
            </div>
          </div>
          <div style={itemStyle2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Marketer:</div>
                  <div>{campaignData.marketer}</div>
                </div>
              </div>
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>End Date:</div>
                  <div>{formatDate(campaignData.endDate)}</div>
                </div>
              </div>
            </div>
          </div>
          <div style={itemStyle2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Status</div>
                  <div
                    style={getStatusColorClass(
                      campaignData?.status ?? 'Unknown'
                    )}
                  >
                    {campaignData?.status ?? 'Unknown'}
                  </div>
                </div>
              </div>
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Manually Approved</div>
                  <div>{campaignData?.approvedItemsCount}</div>
                </div>
              </div>
            </div>
          </div>
          <div style={itemStyle2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Media Partner:</div>
                  <div>{campaignData?.mediaPartner ?? '-'}</div>
                </div>
              </div>
              <div className=''>
                <div className=''>
                  <div style={headingStyle}>Auto-Approved Leads</div>
                  <div>{campaignData?.autoApprovedItemsCount ?? '-'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyCampigndata
