import React from "react";
import main from "../../assets/images/activeimg2.png";
import Satisfied from "../../assets/images/Satisfied-Professionals.png";
import lead from "../../assets/images/total-leads.png";
import { Link } from "react-router-dom";

const ActiveLeads = () => {
  return (
    <>
      <section className="activeLeads">
        <div class="container">
          <div class="row">
          <div class="col-md-6" data-aos="zoom-in-left">
              <div>
                <img src={main} className="aLeadImg" alt="images/activeimg.png" />
              </div>
            </div>
            <div class="col-md-6" data-aos="zoom-in-right">
              <div>
                <h3>
                Buy the Active <span class="yellow-title">B2B</span>{" "} Leads
                </h3>
                <p>
                Are you looking to boost your business with high-quality leads? Look no further! Our B2B Active Leads are the answer to all your sales needs. 
                <br /><br />
                Why waste time and resources on outdated and ineffective lead generation methods when you can buy our B2B Active Leads? Our leads are carefully curated to ensure they are active and ready for engagement. We understand that time is money, and our goal is to provide you with leads that are primed for conversion.
                </p>
                <div className="target-box">
            <ul className="d-flex">
              <li>
                <img
                  src={lead}
                  className="img-fluid"
                  alt="images/total-leads.png"
                />
                <h6>
                  <span>239180100+ </span>
                  <br />
                  Total Leads serviced{" "}
                </h6>
              </li>
              <li>
                <img src={Satisfied} className="img-fluid" alt="" />
                <h6>
                  <span>78000+ </span>
                  <br />
                  Satisfied Professionals{" "}
                </h6>
              </li>
            </ul>
          </div>
                <Link class="btn-lead" to="/user_dashboard">
                  Login
                </Link>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};
export default ActiveLeads;