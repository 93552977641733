import http from "./httpService";

const apiEndpoint =  "/roles/";

function authUrl(id) {
  return `${apiEndpoint}${id}`;
}

// Get the Roles Data

export function getRoles() {
  return http.get(apiEndpoint);
}

// Get the Role Data

export async function getRole(id) {
  return http.put(authUrl(id));
}

//  Create and Update Role

export function saveRoles(role) {
  return http.post(apiEndpoint, role);
}

// Update the status of the Role Data

export async function statusRole(id, status) {
  return http.post(authUrl(id), { status: status });
}

// Delete the Role Data

export function deleteRole(id) {
  return http.delete(authUrl(id));
}
