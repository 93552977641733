import React from "react";
import Multiselect from "multiselect-react-dropdown";

function MultipleSelect({
  name,
  label,
  value,
  options,
  error,
  selectedOptions,
  setSelectedOptions,
  onChange,
  ...rest
}) {
  const handleSelect = (selectedList, selectedItem) => {
    if (setSelectedOptions) {
      setSelectedOptions(selectedList);
      onChange(name, selectedList);
    }
  };

  return (
    <>
      <div className="form-group">
        <label
          id={name}
          htmlFor={name}
          dangerouslySetInnerHTML={{ __html: label }}
        />

        <Multiselect
          options={options}
          selectedValues={value}
          onSelect={handleSelect}
          onRemove={handleSelect}
          displayValue="name"
        />

        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </>
  );
}

export default MultipleSelect;
