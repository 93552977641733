import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Joi from 'joi-browser'
import Form from './../../common/form' // Assuming this is a custom form handling component
import { getUserRole, saveUserRole } from '../../services/userRoleService' // Assuming these functions exist

const UserRolesForm = () => {
  const initialData = {
    _id: '',
    email: '',
    username: '',
    department: '', // Default empty string for dropdown
    password: '' // Added password field
  }

  const [data, setData] = useState(initialData)
  const [errors, setErrors] = useState({})
  const params = useParams()
  const navigate = useNavigate()

  const populateData = async id => {
    try {
      if (id === 'new') {
        setData(initialData)
      } else {
        const response = await getUserRole(id) // Fetch data by ID
        setData(response.data)
      }
    } catch (error) {
      toast.error('Failed to fetch data')
    }
  }

  useEffect(() => {
    if (params._id) {
      populateData(params._id)
    }
  }, [params._id])

  const schema = {
    _id: Joi.optional().allow(''),
    email: Joi.string().email().required().label('Email'),
    username: Joi.string().required().label('Username'),
    department: Joi.string()
      .valid('SuperAdmin', 'Admin')
      .required()
      .label('Department'),
    password: Joi.string().min(6).required().label('Password') // Added validation for password
  }

  const doSubmit = async () => {
    try {
      await saveUserRole(data) // Save the form data
      toast.success('User role saved successfully')
      navigate(-1) // Navigate to user roles list page
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message)
      }
    }
  }

  const renderButton = label => (
    <button
      type='submit'
      style={{
        backgroundColor: '#55B56D',
        borderColor: '#007bff',
        borderRadius: '10px',
        color: '#fff',
        fontWeight: '600',
        border: 'none'
      }}
    >
      {label}
    </button>
  )

  const renderSelect = (name, label, options) => (
    <div className='form-group'>
      <label>{label}</label>
      <select
        name={name}
        value={data[name]}
        onChange={e => setData({ ...data, [name]: e.target.value })}
        className='form-control'
      >
        <option value=''>Select {label}</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      {errors[name] && <div className='alert alert-danger'>{errors[name]}</div>}
    </div>
  )

  const { renderInput, handleSubmit } = Form({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit
  })

  return (
    <div className='card'>
      <form onSubmit={handleSubmit} className='forms-sample'>
        <div className='row'>
          <div className='col-md-6'>{renderInput('username', 'Username')}</div>
          <div className='col-md-6'>{renderInput('email', 'Email')}</div>
          <div className='col-md-6'>
            {renderSelect('department', 'Department', ['SuperAdmin', 'Admin'])}
          </div>
          <div className='col-md-6'>
            {renderInput('password', 'Password', 'password')}
          </div>{' '}
          {/* Password Field */}
          <div className='d-flex mt-3'>
            <button
              type='submit'
              name='submit'
              value='continue'
              style={{
                backgroundColor: '#55B56D',
                borderColor: '#007bff',
                marginBottom: '10px',
                padding: '10px 24px',
                borderRadius: '10px',
                color: '#fff',
                fontWeight: '600',
                border: 'none'
              }}
            >
              {renderButton('Save User Role')}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UserRolesForm
