import React from "react";
import active from "../../assets/images/activeimg.png";
import contact from "../../assets/images/Contact-level-intent-data.png";
import advance from "../../assets/images/Advance-Intent-Data.png";
import { Link } from "react-router-dom";

const IntentData = () => {
  return (
    <>
      <div className="active-main d-flex">
        <div className="active-main-left">
          <img src={active} className="img-fluid" alt="" />
        </div>
        <div className="active-main-right">
          <h4>Intent Data </h4>
          <p>
            Maximize your chances of reaching your ideal business prospects at
            the perfect moment - when they are most likely to make a purchase
            that aligns with your service or product category.
          </p>
          <div className="target-box">
            <ul className="d-flex">
              <li>
                <img
                  src={contact}
                  className="img-fluid"
                  alt="images/Contact-level-intent-data.png"
                />
                <h6>
                  <span>1509889 </span>
                  <br />
                  Contact-level intent data delivers{" "}
                </h6>
              </li>
              <li>
                <img
                  src={advance}
                  className="img-fluid"
                  alt="images/Advance-Intent-Data.png"
                />
                <h6>
                  <span>195000+ </span>
                  <br />
                  Advance Intent Data delivered{" "}
                </h6>
              </li>
            </ul>
          </div>
          <div className="comanies-main d-flex">
            <div className="comanies-main-right">
              <Link to="/user_dashboard/intent" className="btn-lead">
                Start for free
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntentData;
