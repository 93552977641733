import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const UpdateCampaignSetupForm = ({ funcNav, funcFooter }) => {
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState({
    CampaignSetupId: "",
    funnel: [], // Changed from funnelType to funnel
    objective: [], // Changed from objective to objectives
    industries: [],
    reportFrequency: "",
    jobTitle: "",
    tal: "",
    location: "",
    organizationSize: "",
    tacticsType: "",
    suggestTactics: "",
    additionalFilterEnabled: false, // Updated field name
    additionalFilters: [],
    campaignTimeline: "",
    abm: "",
    assetsFile: null,
    talListFile: null,
    filterimage: null,
    purchaseOrderFile: null,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [previewUrls, setPreviewUrls] = useState({
    assetsFile: `${process.env.REACT_APP_API_URL}/`,
    talListFile: `${process.env.REACT_APP_API_URL}/`,
    filterimage: `${process.env.REACT_APP_API_URL}/`,
    purchaseOrderFile: `${process.env.REACT_APP_API_URL}/`,
  });

  const [initialCampaignData, setInitialCampaignData] = useState({});
  const enumValues = {
    reportFrequency: [
      "Daily",
      "Twice a week",
      "Weekly",
      "Fortnightly",
      "Monthly",
      "Others",
    ],
    industries: [
      "Cross Industry",
      "BFSI",
      "Manufacturing",
      "Retail",
      "Entertainment",
      "Health & Pharma",
      "ITES",
      "Education",
      "Media and news",
      "Construction",
      "Hospitality",
      "Other",
    ],
    funnel: ["Tofu", "Mofu", "Bofu", "All"],
    objectives: [
      "Brand Awareness",
      "Generate Sales",
      "Lead Capture and Nurturing",
      "Increase Website Traffic",
      // Add more objectives as needed
    ],
    tactics: {
      default: [
        "Content Syndication",
        "Email marketing",
        "Newsletter",
        "Webinar",
        "1-on-1",
        "Digital Chat series",
        "Digital Board room",
        "Inner Board room",
        "Social Media",
      ],
      auto: ["Auto"],
    },
  };

  useEffect(() => {
    // Check if user data exists in sessionStorage
    const userDataString = sessionStorage.getItem("user");

    if (!userDataString) {
      // Navigate user to sign-in page if no user data is found
      navigate("/signin");
      return; // Exit early if no user data is found
    }

    const userData = JSON.parse(userDataString);
    const userId = userData._id;

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/campaignSetup/getCampaignSetupByUserId/${userId}`
        );

        // Check if the response data and campaignSetups array exist
        if (
          !response ||
          !response.data ||
          !response.data.campaignSetups ||
          response.data.campaignSetups.length === 0
        ) {
          console.error(
            "Error: Response data is undefined, empty, or does not contain campaignSetups array"
          );
          return;
        }

        const campaignSetup = response.data.campaignSetups[0]; // Extract the first item from the array

        // Update the state with the campaign setup data
        setCampaignData({
          funnel: campaignSetup.funnel || [], // Ensure funnel is properly handled
          jobTitle: {
            title: campaignSetup.jobTitle?.title || "", // Use optional chaining to handle potential null or undefined
            otherTitle: campaignSetup.jobTitle?.otherTitle || "",
          },
          tacticsType: campaignSetup.tactics ? campaignSetup.tactics.type : "", // Ensure tactics is properly handled
          CampaignSetupId: campaignSetup._id || "", // Ensure tactics is properly handled
          campaignTimeline: campaignSetup.campaignTimeline || "", // Ensure tactics is properly handled
          industries: campaignSetup.industries
            ? campaignSetup.industries[0]
            : [], // Ensure industries is properly handled
          abm: campaignSetup.abm || false,
          additionalFilter: campaignSetup.additionalFilter || {
            enabled: false,
            filters: [],
          }, // Ensure additionalFilter is properly handled
          location: campaignSetup.location || "",
          objective: campaignSetup.objective || "",
          organizationSize: campaignSetup.organizationSize
            ? campaignSetup.organizationSize[0]
            : "", // Ensure organizationSize is properly handled
          reportFrequency: campaignSetup.reportFrequency || "",
          tal: campaignSetup.tal || false,
          assetsFile: campaignSetup.assetsFile
            ? `${campaignSetup.assetsFile}`
            : "",
          filterimage: campaignSetup.filterimage
            ? `${campaignSetup.filterimage}`
            : "",
          talListFile: campaignSetup.talListFile
            ? `${campaignSetup.talListFile}`
            : "",
          purchaseOrderFile: campaignSetup.purchaseOrderFile
            ? `${campaignSetup.purchaseOrderFile}`
            : "",
        });

        // Set the preview URLs for images if they exist in the API response
        // Set the preview URLs for images if they exist in the API response
        setPreviewUrls({
          assetsFile: campaignSetup.assetsFile
            ? `${process.env.REACT_APP_API_URL}/${campaignSetup.assetsFile}`
            : "",
          filterimage: campaignSetup.additionalFilter.filterimage
            ? `${process.env.REACT_APP_API_URL}/${campaignSetup.additionalFilter.filterimage}`
            : "",
          talListFile: campaignSetup.talListFile
            ? `${process.env.REACT_APP_API_URL}/${campaignSetup.talListFile}`
            : "",
          purchaseOrderFile: campaignSetup.purchaseOrderFile
            ? `${process.env.REACT_APP_API_URL}/${campaignSetup.purchaseOrderFile}`
            : "",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (type === "file") {
      // File handling logic
    } else {
      if (name === "jobTitle") {
        // Parse the JSON string into an object
        const jobTitleObject = JSON.parse(value);
        setCampaignData({
          ...campaignData,
          jobTitle: {
            title: jobTitleObject.title || "",
            otherTitle: jobTitleObject.otherTitle || "",
          },
        });
      } else if (name === "funnel" || name === "industries") {
        // Handle array values
        const selectedValues = campaignData[name] || [];
        const updatedValues = selectedValues.includes(value)
          ? selectedValues.filter((val) => val !== value)
          : [...selectedValues, value];
        setCampaignData({ ...campaignData, [name]: updatedValues });
      } else {
        setCampaignData({ ...campaignData, [name]: value });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if user data exists in sessionStorage
    const userDataString = sessionStorage.getItem("user");

    if (!userDataString) {
      // Navigate user to sign-in page if no user data is found
      navigate("/signin");
      return; // Exit early if no user data is found
    }

    const userData = JSON.parse(userDataString);
    const userId = userData._id;

    try {
      const formData = new FormData();
      Object.entries(campaignData).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else {
          formData.append(key, JSON.stringify(value));
        }
      });

      await axios.put(
        `/campaignSetup/updateCampaignSetup/${userId}/campaign-setups/${campaignData.CampaignSetupId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Campaign setup updated successfully!");

      setErrorMessage("");
      // Redirect to profile summary or show success message
    } catch (error) {
      console.error("Error updating campaign setup:", error);
      setErrorMessage("Failed to update campaign setup");
    }
  };

  const handleFileChange = (name, e) => {
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log("File selected:", file); // Log the selected file
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreviewUrls({ ...previewUrls, [name]: reader.result });
      };
      setCampaignData({ ...campaignData, [name]: file });
    } else {
      console.error("File input event is invalid:", e);
    }
  };

  const handleAdditionalFiltersChange = (index, value) => {
    const updatedFilters = [...campaignData.additionalFilters];
    updatedFilters[index] = value;
    setCampaignData({ ...campaignData, additionalFilters: updatedFilters });
  };

  const addAdditionalFilterInput = () => {
    setCampaignData({
      ...campaignData,
      additionalFilters: [...campaignData.additionalFilters, ""],
    });
  };

  const removeAdditionalFilterInput = (index) => {
    const updatedFilters = [...campaignData.additionalFilters];
    updatedFilters.splice(index, 1);
    setCampaignData({ ...campaignData, additionalFilters: updatedFilters });
  };

  // Render file input and display preview
  const renderFileInput = (name, label) => {
    const imageUrl = previewUrls[name];

    return (
      <div className="form-group">
      {imageUrl && (
        <img
          src={imageUrl}
          alt={label}
          className="mt-2 mb-2"
          style={{
            width: "400px", // Set the desired width
            height: "200px", // Set the desired height
            objectFit: "fit",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      )}
    </div>
    
    );
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4" style={{ color: "#007bff" }}>
        Campaign Setup Form
      </h2>
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        {/* Form content */}
        <div className="row">
          {/* Funnel */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#28a745" }}>Funnel:</label>
            {enumValues.funnel.map((funnel) => (
              <div key={funnel} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="funnel"
                  value={funnel}
                  checked={
                    campaignData.funnel && campaignData.funnel.includes(funnel)
                  }
                  onChange={handleChange}
                />
                <label className="form-check-label">{funnel}</label>
              </div>
            ))}
          </div>
          {/* Objective */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#dc3545" }}>Objective:</label>
            {enumValues.objectives.map((objective) => (
              <div key={objective} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="objective"
                  value={objective}
                  checked={
                    campaignData.objective &&
                    campaignData.objective.includes(objective)
                  }
                  onChange={handleChange}
                />
                <label className="form-check-label">{objective}</label>
              </div>
            ))}
          </div>
          {/* Report Frequency */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#ffc107" }}>Report Frequency:</label>
            <select
              className="form-control"
              name="reportFrequency"
              value={campaignData.reportFrequency || ""}
              onChange={handleChange}
            >
              <option value="">Select Report Frequency</option>
              {enumValues.reportFrequency.map((frequency) => (
                <option key={frequency} value={frequency}>
                  {frequency}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row">
          {/* Job Title */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#17a2b8" }}>Job Title:</label>
            <select
              className="form-control"
              name="jobTitle"
              value={campaignData.jobTitle.title || ""}
              onChange={handleChange}
            >
              <option value="">Select Job Title</option>
              <option value="ITDM">ITDM</option>
              <option value="BDM">BDM</option>
              <option value="Both">Both</option>
              <option value="Other">Other</option>
            </select>
          </div>
          {/* Other Job Title */}
          <div className="col-md-4 mb-3">
            {campaignData.jobTitle.title === "Other" && (
              <div>
                <label style={{ color: "#6610f2" }}>Other Job Title:</label>
                <input
                  type="text"
                  className="form-control"
                  name="otherTitle"
                  value={campaignData.jobTitle.otherTitle || ""}
                  onChange={handleChange}
                />
              </div>
            )}
          </div>

          {/* TAL */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#6c757d" }}>TAL (Wish List):</label>
            <select
              className="form-control"
              name="tal"
              value={campaignData.tal || ""}
              onChange={handleChange}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
        </div>

        <div className="row">
          {/* Location */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#007bff" }}>Location:</label>
            <input
              type="text"
              className="form-control"
              name="location"
              value={campaignData.location || ""}
              onChange={handleChange}
            />
          </div>
          {/* Organization Size */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#ffc107" }}>Organization Size:</label>
            <select
              className="form-control"
              name="organizationSize"
              value={campaignData.organizationSize || ""}
              onChange={handleChange}
            >
              <option value="">Select Organization Size</option>
              <option value="Startup">Startup</option>
              <option value="Mid Size">Mid Size</option>
              <option value="Large Enterprise">Large Enterprise</option>
            </select>
          </div>
        </div>

        <div className="row">
          {/* Tactics Type */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#17a2b8" }}>Tactics Type:</label>
            <select
              className="form-control"
              name="tacticsType"
              value={campaignData.tacticsType || ""}
              onChange={handleChange}
            >
              <option value="">Select Tactics Type</option>
              {enumValues.tactics.default.map((tactic) => (
                <option key={tactic} value={tactic}>
                  {tactic}
                </option>
              ))}
            </select>
          </div>
          {/* Suggest Tactics */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#6c757d" }}>Suggest Tactics:</label>
            <select
              className="form-control"
              name="suggestTactics"
              value={campaignData.suggestTactics || ""}
              onChange={handleChange}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          {/* Additional Filter Enabled */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#dc3545" }}>
              Additional Filter Enabled:
            </label>
            <select
              className="form-control"
              name="additionalFilterEnabled"
              value={campaignData.additionalFilterEnabled || ""}
              onChange={handleChange}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
        </div>

        <div className="row">
        
          {/* Campaign Timeline */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#28a745" }}>Campaign Timeline:</label>
            <input
              type="text"
              className="form-control"
              name="campaignTimeline"
              value={campaignData.campaignTimeline || ""}
              onChange={handleChange}
            />
          </div>
          {/* ABM */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#ffc107" }}>ABM:</label>
            <select
              className="form-control"
              name="abm"
              value={campaignData.abm || ""}
              onChange={handleChange}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
        </div>

        <div
          className="col-md-12 mb-3 d-flex flex-wrap align-items-center"
          style={{ padding: "10px" }}
        >
          <label
            style={{ color: "#28a745", marginRight: "10px", width: "100%" }}
          >
            Industries:
          </label>
          {enumValues.industries.map((industry) => (
            <div
              key={industry}
              className="form-check d-flex align-items-center"
              style={{
                marginRight: "20px",
                marginBottom: "10px",
                width: "auto",
              }}
            >
              <input
                className="form-check-input"
                type="checkbox"
                name="industries"
                value={industry}
                checked={
                  campaignData.industries &&
                  campaignData.industries.includes(industry)
                }
                onChange={handleChange}
                style={{ marginRight: "5px" }}
              />
              <label className="form-check-label" style={{ margin: "0" }}>
                {industry}
              </label>
            </div>
          ))}
        </div>

        <div className="row">
          {/* Assets File (Image Only) */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#17a2b8" }}>Assets File:</label>
            <input
              type="file"
              accept="image/*"
              className="form-control-file"
              name="assetsFile"
              onChange={(e) => handleFileChange("assetsFile", e)}
            />
            {renderFileInput("assetsFile", "Assets File")}
          </div>
          {/* TAL List File (Image Only) */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#6c757d" }}>TAL List File:</label>
            <input
              type="file"
              accept="image/*"
              className="form-control-file"
              name="talListFile"
              onChange={(e) => handleFileChange("talListFile", e)}
            />
            {renderFileInput("talListFile", "TAL List File")}
          </div>
          {/* Filter Image (Image Only) */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#ffc107" }}>Filter Image:</label>
            <input
              type="file"
              accept="image/*"
              className="form-control-file"
              name="filterimage"
              onChange={(e) => handleFileChange("filterimage", e)}
            />
            {renderFileInput("filterimage", "Filter Image")}
          </div>
          {/* Purchase Order File (Image Only) */}
        </div>

        <div className="row">
          {/* Purchase Order File (Image Only) */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#007bff" }}>Purchase Order File:</label>
            <input
              type="file"
              accept="image/*"
              className="form-control-file"
              name="purchaseOrderFile"
              onChange={(e) => handleFileChange("purchaseOrderFile", e)}
            />
            {renderFileInput("purchaseOrderFile", "Purchase Order File")}
          </div>
        </div>
        <div className="row">
          {/* Additional Filter Enabled */}
          <div className="col-md-4 mb-3">
            <label style={{ color: "#dc3545" }}>
              Additional Filter Enabled:
            </label>
            <select
              className="form-control"
              name="additionalFilterEnabled"
              value={campaignData.additionalFilterEnabled}
              onChange={handleChange}
            >
              <option value="">Select Option</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          {/* Additional Filters */}
          {campaignData.additionalFilterEnabled && (
            <div className="col-md-4 mb-3">
              <label style={{ color: "#007bff" }}>Additional Filters:</label>
              {campaignData.additionalFilters.map((filter, index) => (
                <div key={index} className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter filter"
                    value={filter}
                    onChange={(e) =>
                      handleAdditionalFiltersChange(index, e.target.value)
                    }
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-danger"
                      type="button"
                      onClick={() => removeAdditionalFilterInput(index)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
              <button
                className="btn btn-primary"
                type="button"
                onClick={addAdditionalFilterInput}
              >
                Add Filter
              </button>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="btn btn-primary"
          style={{ marginTop: "20px" }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default UpdateCampaignSetupForm;
