import http from "./httpService";

const apiEndpoint =  "/mintentApply";
const apiUserIntentsEndpoint =
   "/mintentApply/user";
const apiIntentIntentsEndpoint =
   "/mintentApply/intent";

// Get the MIntents Apply Data
export async function getMIntentApplys() {
  return http.get(apiEndpoint);
}

export async function getMIntentApply(id) {
  const objUrl = createEndpointUrl(apiEndpoint, id);
  return http.put(objUrl);
}

export async function getUserMIntentApply(id) {
  const objUrl = createEndpointUrl(apiUserIntentsEndpoint, id);
  return http.put(objUrl);
}
export async function getIntentMIntentApply(id) {
  const objUrl = createEndpointUrl(apiIntentIntentsEndpoint, id);
  return http.put(objUrl);
}

// Create and Update MIntent
export function saveMIntentApplys(mintent) {
  return http.post(apiEndpoint, mintent);
}

// Update the status of the MIntent Data
export async function statusMIntentApply(id, mintent) {
  const authUrl = createEndpointUrl(apiEndpoint, id);
  return http.post(authUrl, mintent);
}

// Delete the MIntent Data
export async function deleteMIntentApply(id) {
  const authUrl = createEndpointUrl(apiEndpoint, id);
  return http.delete(authUrl);
}

function createEndpointUrl(endpoint, id) {
  return `${endpoint}/${id}`;
}
