import React from "react";
import Satisfied from "../../assets/images/Satisfied-Professionals.png";
import lead from "../../assets/images/total-leads.png";
import active from "../../assets/images/activeimg.png";
import { Link } from "react-router-dom";

const ActiveLead = () => {
  return (
    <>
      <div className="active-main d-flex">
        <div className="active-main-left">
          <img src={active} className="img-fluid" alt="images/activeimg.png" />
        </div>
        <div className="active-main-right">
          <h4>Active Leads</h4>
          <p>
            Supercharge your lead generation with our cutting-edge platform that
            not only connects you with an abundance of high-quality leads but
            also actively amplifies your network.
          </p>
          <div className="target-box">
            <ul className="d-flex">
              <li>
                <img
                  src={lead}
                  className="img-fluid"
                  alt="images/total-leads.png"
                />
                <h6>
                  <span>239180100+ </span>
                  <br />
                  Total Leads serviced{" "}
                </h6>
              </li>
              <li>
                <img src={Satisfied} className="img-fluid" alt="" />
                <h6>
                  <span>78000+ </span>
                  <br />
                  Satisfied Professionals{" "}
                </h6>
              </li>
            </ul>
          </div>
          <div className="comanies-main d-flex">
            <div className="comanies-main-right">
              <Link to="/user_dashboard/activeLeads" className="btn-lead">
                Start for free
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveLead;
