import React, { useState, useRef } from "react";
import Slider from "react-slick";
import maine from "../../assets/images/maine-bannerss-1.jpg";
import CategoryBody from "./CategoryBody";

const Category = () => {
  const [index, setIndex] = useState(0);

  const nextArrow = useRef();
  const prevArrow = useRef();

  const handlePrevButton = () => {
    prevArrow.current.click();
  };

  const handleNextButton = () => {
    nextArrow.current.click();
  };

  const handleSlideClick = (idx) => {
    setIndex(idx);
  };

  const ArrowButton = ({ onClick, direction }) => {
    const buttonStyle = {
      backgroundColor: "#55b56d",
      border: "2px solid white",
      // borderRadius: "50%",
      width: "30px",
      height: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    };

    const iconStyle = {
      color: "white",
    };

    return (
      <button style={buttonStyle} onClick={onClick}>
        <i className={`fa fa-caret-${direction}`} style={iconStyle} />
      </button>
    );
  };

  const ArrowLeft = () => (
    <ArrowButton onClick={handlePrevButton} direction="left" />
  );
  const ArrowRight = () => (
    <ArrowButton onClick={handleNextButton} direction="right" />
  );

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    setIndex(props.currentSlide);
    return (
      <div
        className={className}
        style={{ ...style, display: "none", marginRight: "8px" }}
        onClick={onClick}
        ref={nextArrow}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    setIndex(props.currentSlide);
    return (
      <div
        className={className}
        style={{ ...style, display: "none", marginLeft: "8px" }}
        onClick={onClick}
        ref={prevArrow}
      />
    );
  };

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 4000,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  return (
    <div>
      <section id="lead" className="our-leads">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xll-12 ps-0 pe-0">
              <div className="our-leads-inner">
                <img
                  src={maine}
                  className="img-fluid"
                  alt="images/maine-bannerss-1.jpg"
                />
                <div className="our-leads-caption" data-aos="fade-right">
                  <div
                    style={{ position: "absolute", width: "100%", top: "50%" }}
                  >
                    <div className="d-flex justify-content-between">
                      <ArrowLeft />
                      <ArrowRight />
                    </div>
                  </div>

                  <div className="total-tb">
                    <div thumbsSlider="" className="swiper mySwiper">
                      <div className="swiper-wrapper">
                        <Slider {...settings1}>
                          {CategoryBody.map((item, idx) => (
                            <div
                              key={idx}
                              className={`swiper-slide ${
                                index === idx ? "swiper-slide-thumb-active" : ""
                              }`}
                              onClick={() => handleSlideClick(idx)}
                            >
                              <h6>{item.heading}</h6>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>

                    <div
                      style={{
                        swiperNavigationColor: "#fff",
                        swiperPaginationColor: "#fff",
                      }}
                      className="swiper mySwiper2"
                    >
                      <div className="swiper-wrapper">
                        <Slider {...settings}>
                          {CategoryBody.map((item, idx) => (
                            <div
                              key={idx}
                              className="swiper-slide"
                              onClick={() => handleSlideClick(idx)}
                            >
                              {item.content}
                            </div>
                          ))}
                        </Slider>
                      </div>
                      <div className="swiper-button-next"></div>
                      <div className="swiper-button-prev"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Category;
