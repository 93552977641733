import React from "react";
import profile from "../../assets/images/jop-profile.png";
import blue from "../../assets/images/blue-icon-1.png";
import { Link } from "react-router-dom";

const Jobs = () => {
  return (
    <>
      <div className="active-main d-flex">
        <div className="active-main-left">
          <div className="profile-jobs">
            <img
              src={profile}
              className="img-fluid"
              alt="images/jop-profile.png"
            />
            <h5>Founder & CEO </h5>
            <h6>Company Name</h6>
          </div>
        </div>
        <div className="active-main-right">
          <h4>Jobs</h4>
          <p>
            We provide top-tier career opportunities for C-suite professionals .
          </p>
          <div className="target-box">
            <ul className="d-flex">
              <li>
                <img src={blue} className="img-fluid" alt="" />
                <h6>
                  <span>1million+ </span>
                  <br />
                  Professionals{" "}
                </h6>
              </li>
              <li>
                <img src={blue} className="img-fluid" alt="" />
                <h6>
                  <span>500+ </span>
                  <br />
                  CXO’s placed{" "}
                </h6>
              </li>
            </ul>
          </div>
          <div className="comanies-main d-flex">
            <div className="comanies-main-right">
              <Link to="/user_dashboard/job" className="btn-lead">
                Apply for Job{" "}
              </Link>
              <Link to="/user_dashboard/job" className="btn-lead">
                Post a Job{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jobs;
