// import http from "./httpService";

// const apiEndpoint =  "/objectives/";

// function authUrl(id) {
//   return `${apiEndpoint}${id}`;
// }

// // Get the Objectives Data

// export async function getObjectives() {
//   return http.get(apiEndpoint);
// }

// // Get the Objective Data

// export async function getObjective(id) {
//   return http.put(authUrl(id));
// }

// //  Create and Update Objective

// export function saveObjectives(objective) {
//   return http.post(apiEndpoint, objective);
// }

// // Update the status of the Objective Data

// export async function statusObjective(id, status) {
//   return http.post(authUrl(id), { status: status });
// }

// // Delete the Objective Data

// export async function deleteObjective(id) {
//   return http.delete(authUrl(id));
// }
