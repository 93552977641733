import http from "./httpService";

const apiEndpoint =  "/subscription/";

function authUrl(id) {
  return `${apiEndpoint}${id}`;
}

// Get the Subscriptions Data

export async function getSubscriptions() {
  return await http.get(apiEndpoint);
}

export async function getSubscription(id) {
  return http.put(authUrl(id));
}

//  Create and Update Subscription

export function saveSubscriptions(subscription) {
  return http.post(apiEndpoint, subscription);
}

// Update the status of the Subscription Data

export async function statusSubscription(id, status) {
  return http.post(authUrl(id), { status: status });
}

// Delete the Subscription Data

export async function deleteSubscription(id) {
  return http.delete(authUrl(id));
}
