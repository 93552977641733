import React, { useState } from 'react'
import { styled } from '@mui/material/styles'

import { Stack, Button, Typography, Divider, Paper } from '@mui/material' // Make sure to import these if you're using MUI
const DemoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  ...theme.typography.subtitle1,
  textAlign: 'center',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center'
  }
}))
const Table = ({ columns, data }) => {
  const [pageNumber, setPageNumber] = useState(1) // Initialize to page 1
  const userPerPage = 10 // Adjust as needed based on your design
  const pageCount = Math.ceil(data.length / userPerPage)

  const changePage = page => {
    setPageNumber(page)
  }

  // Calculate the range of items to display for the current page
  const startIndex = (pageNumber - 1) * userPerPage
  const endIndex = Math.min(startIndex + userPerPage, data.length)
  const currentPageData = data.slice(startIndex, endIndex)
  const handleBuyLead = () => {
    console.log('object')
  }
  const handleAddToCart = () => {
    console.log('object')
  }
  return (
    <Stack
      direction='column'
      spacing={3}
      sx={{ boxSizing: 'border-box', px: 2 }}
    >
      {currentPageData.map(item => (
        <DemoPaper key={item?.id}>
          <Stack
            direction='row'
            spacing={2}
            style={{ width: '100%', alignItems: 'center' }}
          >
            <Stack
              spacing={1}
              alignItems='center'
              style={{ flexShrink: 0, width: '15%' }}
            >
              <Typography variant='h6'>
                {item?.companyName || 'No Company Name'}
              </Typography>
            </Stack>

            <Divider sx={{ borderWidth: 2 }} orientation='vertical' flexItem />

            <Stack
              spacing={1}
              textAlign='left'
              justifyContent='center'
              style={{ flexShrink: 0, width: '25%', paddingLeft: 10 }}
            >
              <Typography variant='subtitle1'>
                Name: {item?.customerName || 'No Customer Name'}
              </Typography>
              <Typography variant='subtitle1'>
                Designation: {item?.designation || 'No Designation'}
              </Typography>
              <Typography variant='subtitle1'>
                Mobile: {item?.mobileNumber || 'No Mobile'}
              </Typography>
            </Stack>

            <Divider sx={{ borderWidth: 2 }} orientation='vertical' flexItem />

            <Stack
              spacing={1}
              alignItems='flex-start'
              justifyContent='center'
              style={{ flexShrink: 0, width: '25%', paddingLeft: 10 }}
            >
              <Typography variant='subtitle1'>
                City: {item?.city || 'No City'}
              </Typography>
              <Typography variant='subtitle1'>
                State: {item?.state || 'No State'}
              </Typography>
              <Typography variant='subtitle1'>
                Email: {item?.email || 'No Email'}
              </Typography>
            </Stack>

            <Divider sx={{ borderWidth: 2 }} orientation='vertical' flexItem />

            <Stack
              direction='column'
              spacing={1}
              alignItems='center'
              width='100%'
            >
              <Button
                sx={{
                  width: '30%',
                  fontSize: '13px',
                  backgroundColor: '#55B56D',
                  borderRadius: '8px',
                  textTransform: 'capitalize'
                }}
                variant='contained'
                onClick={() => handleBuyLead(item.id)}
              >
                Buy Lead
              </Button>
              <Button
                sx={{
                  width: '30%',
                  fontSize: '13px',
                  borderRadius: '8px',
                  backgroundColor: '#0A58CA',
                  textTransform: 'capitalize'
                }}
                variant='contained'
                onClick={() => handleAddToCart(item.id)}
              >
                Add to cart
              </Button>
              <Button
                sx={{
                  width: '30%',
                  textTransform: 'capitalize',
                  fontSize: '13px',
                  borderRadius: '8px',
                  color: 'black',
                  backgroundColor: '#CAE8D2'
                }}
                variant='contained'
              >
                More
              </Button>
            </Stack>
          </Stack>
        </DemoPaper>
      ))}
    </Stack>
  )
}

export default Table
