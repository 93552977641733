import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getUserRole, saveUserRole } from '../../services/userRoleService'

const UpdateUserRoleForm = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    department: ''
  })

  const location = useLocation()
  const navigate = useNavigate()
  const userId = location.pathname.split('/').pop() // Extract user ID

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getUserRole(userId)
        const userDataFromApi = response.data

        setUserData(userDataFromApi)
      } catch (error) {
        console.error('Error fetching user data:', error.message)
        setError('User not found')
      } finally {
        setLoading(false)
      }
    }

    fetchUserData()
  }, [userId])

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      const formData = new FormData()

      // Append all fields from userData to formData
      for (const key in userData) {
        if (userData[key] !== null) {
          formData.append(key, userData[key])
        }
      }

      // Log FormData entries

      // Your API request logic
      // await axios.put(`/userroles/${userId}`, formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data'
      //   }
      // })

      // Return if you're only testing logging

      let res = await axios.patch(`/userroles/${userId}`, userData)
      toast.success('User role updated successfully')
      navigate(-1) // Navigate back to the previous page
    } catch (error) {
      console.error('Error updating user role:', error)
      toast.error('Failed to update user role')
    }
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setUserData({ ...userData, [name]: value })
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <div className='card'>
      <form
        onSubmit={handleSubmit}
        encType='multipart/form-data'
        className='forms-sample'
      >
        <div className='card-body'>
          <h4 className='card-title'>Update User Role</h4>

          <div className='row'>
            {/* Username */}
            <div className='col-md-4 mb-3'>
              <label htmlFor='username'>Username</label>
              <input
                type='text'
                id='username'
                name='username'
                value={userData.username}
                onChange={handleInputChange}
                className='form-control'
              />
            </div>

            {/* Email */}
            <div className='col-md-4 mb-3'>
              <label htmlFor='email'>Email</label>
              <input
                type='email'
                id='email'
                name='email'
                value={userData.email}
                onChange={handleInputChange}
                className='form-control'
              />
            </div>

            {/* Department */}
            <div className='col-md-4 mb-3'>
              <label htmlFor='department'>Department</label>
              <select
                id='department'
                name='department'
                value={userData.department}
                onChange={handleInputChange}
                className='form-control'
              >
                <option value='SuperAdmin'>SuperAdmin</option>
                <option value='Admin'>Admin</option>
              </select>
            </div>
          </div>

          <div className='d-flex justify-content-between'>
            <div style={{ flex: '1' }}></div>
            <button
              type='submit'
              name='submit'
              value='continue'
              style={{
                backgroundColor: '#55B56D',
                borderColor: '#007bff',
                marginBottom: '10px',
                padding: '10px 24px',
                borderRadius: '10px',
                color: '#fff',
                fontWeight: '600',
                border: 'none'
              }}
            >
              Update User Role
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UpdateUserRoleForm
