import React from "react";
import Header from "./Header";
import { ActiveCXOLeedsList } from "../../../../tables";

const AxtiveLeed = () => {
  return (
    <>
      <Header />
      <ActiveCXOLeedsList />
    </>
  );
};

export default AxtiveLeed;
