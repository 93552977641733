import React, { useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import Joi from 'joi-browser'

const ListingForm = () => {
  const location = useLocation()
  const userId = location.pathname.split('/').slice(-2, -1)[0]
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    campaignId: userId,
    companyName: '',
    customerName: '',
    designation: '',
    contactNumber: '',
    email: '',
    city: '',
    state: '',
    leadCommentsStatus: 'pending',
    leadScoring: ''
  })

  const [errors, setErrors] = useState({})

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const validationErrors = validate()
    if (validationErrors) {
      setErrors(validationErrors)
      return
    }

    try {
      // Send the form data to create a listing
      const response = await axios.post(`/listing/createListing`, formData)

      // Redirect to the specified URL after successful save
      navigate(`/admin_panel/listing/${userId}`)
    } catch (error) {
      console.error(error)
      // Handle error, show error message or handle as needed
    }
  }

  const schema = {
    campaignId: Joi.string(),
    companyName: Joi.string().required(),
    customerName: Joi.string().required(),
    designation: Joi.string().required(),
    contactNumber: Joi.number(),
    email: Joi.string().email().required(),
    city: Joi.string(),
    state: Joi.string(),
    leadCommentsStatus: Joi.string().valid('pending', 'complete').required(),
    leadScoring: Joi.string().valid('high', 'medium', 'low')
  }

  const validate = () => {
    const { error } = Joi.validate(formData, schema, { abortEarly: false })
    if (!error) return null

    const validationErrors = {}
    for (let item of error.details) {
      validationErrors[item.path[0]] = item.message
    }
    return validationErrors
  }

  const renderInput = (name, label, type = 'text') => (
    <div className='form-group'>
      <label>{label}</label>
      <input
        type={type}
        name={name}
        value={formData[name]}
        onChange={handleChange}
        className='form-control'
      />
      {errors[name] && <div className='alert alert-danger'>{errors[name]}</div>}
    </div>
  )
  const renderSelect = (name, label, options) => (
    <div className='form-group'>
      <label>{label}</label>
      <select
        name={name}
        value={formData[name]}
        onChange={handleChange}
        className='form-control'
      >
        <option value=''>Select {label}</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      {errors[name] && <div className='alert alert-danger'>{errors[name]}</div>}
    </div>
  )
  return (
    <div className='card'>
      <form onSubmit={handleSubmit} className='forms-sample'>
        <div className='row'>
          <div className='col-12 grid-margin'>
            <h3>Add Listing</h3>
            <div className='row'>
              <div className='col-md-4 mb-3'>
                {renderInput('companyName', 'Company Name')}
              </div>
              <div className='col-md-4 mb-3'>
                {renderInput('customerName', 'Customer Name')}
              </div>
              <div className='col-md-4 mb-3'>
                {renderInput('designation', 'Designation')}
              </div>
              <div className='col-md-4 mb-3'>
                {renderInput('contactNumber', 'Contact Number')}
              </div>
              <div className='col-md-4 mb-3'>
                {renderInput('email', 'Email')}
              </div>
              <div className='col-md-4 mb-3'>{renderInput('city', 'City')}</div>
              <div className='col-md-4 mb-3'>
                {renderInput('state', 'Country')}
              </div>
              <div className='col-md-4 mb-3'>
                {renderSelect('leadScoring', 'Lead Scoring', [
                  'high',
                  'medium',
                  'low'
                ])}
              </div>

              <div className='col-md-4 mb-3'>
                {renderSelect('leadCommentsStatus', 'Lead Comments Status', [
                  'pending',
                  'complete'
                ])}
              </div>

              <div className='d-flex justify-content-between'>
                <div style={{ flex: '1' }}></div>
                <button
                  type='submit'
                  name='submit'
                  value='continue'
                  style={{
                    backgroundColor: '#55B56D',
                    borderColor: '#007bff',
                    marginBottom: '10px',
                    padding: '10px 24px',
                    borderRadius: '10px',
                    color: '#fff',
                    fontWeight: '600',
                    border: 'none'
                  }}
                >
                  Add Listing
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ListingForm
