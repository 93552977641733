import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const ResetPassword = ({ funcNav, funcFooter }) => {
  const [email, setEmail] = useState('');
  const [newPassword, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true); // Add isValidEmail state
  const navigate = useNavigate();

  useEffect(() => {
    // Extract token from the URL when the component mounts
    const urlToken = new URLSearchParams(window.location.search).get('token');
    if (urlToken) {
      setToken(urlToken);
    } else {
      // Redirect or handle the case where the token is not present
      navigate('/login'); // Redirect to login or another page
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Validate email
      if (!validateEmail(email)) {
        setIsValidEmail(false); // Set isValidEmail state to false if email is invalid
        toast.error('Please enter a valid email address', { autoClose: 3000 });
        return;
      } else {
        setIsValidEmail(true); // Set isValidEmail state to true if email is valid
      }

      // Send a request to your backend API to update the password
      const response = await axios.post(`auth/resetPassword`, {
        email,
        token,
        newPassword,
      });

      if (response.status === 200) {
        console.log('Password reset successfully');
        toast.success('Password reset successfully', { autoClose: 3000 });
        // Use navigate to navigate to the user dashboard upon successful reset
        navigate('/user_dashboard');
      } else {
        console.error('Failed to reset password');
        toast.error('Failed to reset password', { autoClose: 3000 });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error('An error occurred', { autoClose: 3000 });
    }
  };

  // Function to validate email
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  funcNav(true);
  funcFooter(true);

  return (
    <div className="sales-left">
      <form className="logine" onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <h2>Reset Password</h2>
        <div className="form-group" style={{ marginBottom: '10px', width: "80%" }}>
          <label className="control-label">Email:</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={`form-control ${!isValidEmail ? 'is-invalid' : ''}`}
            style={{ borderRadius: '5px' }}
          />
          {!isValidEmail && (
            <div className="invalid-feedback">Please enter a valid email address.</div>
          )}
        </div>
        <div className="form-group" style={{ marginBottom: '10px', width: "80%" }}>
          <label className="control-label">New Password:</label>
          <input
            type="password"
            name="password"
            value={newPassword}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="form-control"
            style={{ borderRadius: '5px' }}
          />
        </div>

        <button type="submit" style={{ width: "100%", padding: "10px", borderRadius: "5px" }} className="btn btn-primary btre">Reset Password</button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;


