// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-inner ul {
    margin: 0px;
    padding-left: 20px;
}

.privacy-inner ul li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    list-style-type: disc;
}

.privacy-inner {
    text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/pages/TermsCondition/Introduction.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".privacy-inner ul {\r\n    margin: 0px;\r\n    padding-left: 20px;\r\n}\r\n\r\n.privacy-inner ul li {\r\n    font-family: 'Poppins';\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    font-size: 16px;\r\n    line-height: 24px;\r\n    list-style-type: disc;\r\n}\r\n\r\n.privacy-inner {\r\n    text-align: justify;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
