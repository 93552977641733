import { Link } from "react-router-dom";
import techmbanner from "../../assets/images/tech-marketing-banner.jpg";
import awarness from "../../assets/images/awarness-img.jpg";
import circleDisc from "../../assets/images/intenden-icon.png";
import leadGeneration from "../../assets/images/lead-generation-img.jpg";
import contentSyndication from "../../assets/images/content-syndication-img.jpg";
import eventPromotion from "../../assets/images/event-promotion-img.jpg";
import emailMarketing from "../../assets/images/email-marketing-img.jpg";
import websiteResponse from "../../assets/images/website-response-management-img.jpg";
import unifiedChannel from "../../assets/images/unified-channel-marketing-img.jpg";
import Testimonials from "../../components/Testimonials/Testimonials";
import "./DemandProgram.css";

const DemandProgram = ({ funcNav, funcFooter }) => {
  funcNav(true);
  funcFooter(true);
  return (
    <>
      <section className="maint-abt">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 ps-0 pe-0">
              <div className="maint-abt-inner">
                <img src={techmbanner} className="img-fluid" alt="" />
                <h3>Demand Program</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="maine-banner abt-st">
        <div className="container">
          <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-5">
            <img src={awarness} className="img-fluid" alt="" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-7">
            <h3>Demand Program</h3>
            <p>
            A successful digital marketing plan takes a lot of planning and expertise to get the desired results when doing it on one’s own, Conversion Rate is the primary metrics for any successful campaign.
            </p>
            <ul className="contLS pt-2">
                <li><img src={circleDisc} className="cirDisc" alt="" /> Lead Generation (BANT Qualified)</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Content Syndication</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Event Promotion & Delegate Acquisition</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Email Marketing</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Website Response Management</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Unified Channel Marketing</li>
            </ul>
           </div>
          </div>
        </div>
      </section>
      <section className="maine-banner bg-gray">
        <div className="container">
          <div className="row pt-5">
          <div className="col-xs-12 col-sm-12 col-md-7">
            <h3>Lead Generation</h3>
            <p>
            Stop generating leads manually, and start automating the process instead. You do not need to wait 6-9 months for your search engine marketing or optimization plan to kick in Techplus AI based Lead Engine “LeadXchange“ has already built out the sales funnel for numerous Tech Marketers and can simply starts sending Exclusive business leads to convert into customers.
            </p>
            <p className="pt-3">
            Our Platform is built on Cutting –edge Technology and has the most advance Analytical engine, Demand Mapping Algorithms and Validation Processes which enables our clients to fire up their performances and increase their revenue with higher lead conversions Each lead is verified on over Multiple data points, scored with proprietary algorithms and matched with filters determined by you to ensure the highest quality and the right intent guaranteeing your ROI.
            </p>
            <p>
            Sterling track record with 300+ Satisfied Tech Customers and more than 8000 Campaign managed & 150000 leads generated.
            </p>
            <div className="col-xs-12 col-sm-12 col-md-8">
                <Link class="btn-lead mr-4" to="/download-case-study">
                        Download Citrix Case Study
                </Link>
            </div>
           </div>
           <div className="col-xs-12 col-sm-12 col-md-5">
            <img src={leadGeneration} className="img-fluid" alt="" />
          </div>
          
          </div>
        </div>
      </section>
      <section className="maine-banner abt-st">
        <div className="container">
          <div className="row pt-5">
          <div className="col-xs-12 col-sm-12 col-md-5">
            <img src={contentSyndication} className="img-fluid" alt="" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-7">
            <h3>Content Syndication</h3>
            <p>
            The quality of B2B contacts is assessed only through conversion rate metrics. Imagine a newer model where quality comes first. Would it make a difference if you invest your efforts from the start on the Quality b2b contact or email list?
            </p>
            <p>
            Techplus Content Syndication model empower your marketing team to communicate on Intend based leads that have already expressed interest in learning more about what you have to offer, be it through whitepaper or webinar.
            </p>
            <p>
            What if you could directly target this Intend based Community composed exclusively with your best leads? Let Techplus Content Syndication be your elevator.
            </p>
            <div className="col-xs-12 col-sm-12 col-md-6">
          <Link class="btn-lead mr-4" to="/download-case-study">
                  Download Intel Case Study
          </Link>
          </div>
           </div>
          </div>
        </div>
      </section>
      <section className="maine-banner bg-gray">
        <div className="container">
          <div className="row pt-5">
          <div className="col-xs-12 col-sm-12 col-md-7">
            <h3>Event Promotion & Delegate Acquisition</h3>
            <p>
            Getting Right Audience comes from Data Intelligence and it helps you to convert event leads into business.
            </p>
            <p className="pt-3">
            Intend based contact will help you in promotion and attracting Delegates for Online Webinars or Onsite Conference...
            </p>
            <p>
            LeadXchange(LX) improves the quality of your audience by giving you direct access to opt-in prospects with interest in your solutions.
            </p>
            <div className="col-xs-12 col-sm-12 col-md-8">
                <Link class="btn-lead mr-4" to="/download-case-study">
                        Event Coverage & Case Study
                </Link>
           </div>
           </div>
           <div className="col-xs-12 col-sm-12 col-md-5">
            <img src={eventPromotion} className="img-fluid" alt="" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5">
          </div>
          </div>
        </div>
      </section>
      <section className="maine-banner abt-st">
        <div className="container">
          <div className="row pt-5">
          <div className="col-xs-12 col-sm-12 col-md-5">
            <img src={emailMarketing} className="img-fluid" alt="" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-7">
            <h3>Email Marketing</h3>
            <p>
            It is one of the most effective marketing tools for Every $1 spent in email marketing $38 in revenue is generated (Campaign Monitor)
            </p>
            <p>
            Personalization in all of its forms will continue to grow, but the personalization of send time will be one of the fastest growing forms. Techplus Media Email services from itVARnews & CXOtv offer Send-time Optimization this functionality, which optimizes the send times of emails to individual subscribers based on their historical open patterns.
            </p>
            <div className="col-xs-12 col-sm-12 col-md-6">
                <Link class="btn-lead mr-4" to="/download-case-study">
                        Download Intel Case Study
                </Link>
            </div>
           </div>
          </div>
        </div>
      </section>
      <section className="maine-banner bg-gray">
        <div className="container">
          <div className="row pt-5">
          <div className="col-xs-12 col-sm-12 col-md-7">
            <h3>Website Response Management</h3>
            <p>
            Getting It’s important to know your website Visitor and CXOtv Tools help in understanding website visitor through their Search behavior and help you in converting them into a customer.
            </p>
            <div className="col-xs-12 col-sm-12 col-md-8">
                <Link class="btn-lead mr-4" to="/download-case-study">
                        Event Coverage & Case Study
                </Link>
            </div>
           </div>
           <div className="col-xs-12 col-sm-12 col-md-5">
            <img src={websiteResponse} className="img-fluid" alt="" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5">
          </div>
          </div>
        </div>
      </section>
      <section className="maine-banner abt-st">
        <div className="container">
          <div className="row pt-5">
          <div className="col-xs-12 col-sm-12 col-md-5">
            <img src={unifiedChannel} className="img-fluid" alt="" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-7">
            <h3>Unified Channel Marketing</h3>
            <p>
            itVARnews UCM (Unified Channel Management) is designed to help Channels leverage marketing support without making fixed investments.
            </p>
            <p>
            itVARnews UCM (Unified Channel Management) provides full-fledged services with a set up that supports brands in the areas of:
            </p>
            <ul className="contLS pt-2">
                <li><img src={circleDisc} className="cirDisc" alt="" /> Partner MDF Management</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Partner acquisition & Enablement Program</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Loyalty & Club programs</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Membership support programs</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Partner Events and promotions (End to End services Including Partner ABM activity)</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Data management, profiling</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Telemarketing – Inside Sales, Website response Management</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> Digital Marketing including Social Media engagement</li>
                <li><img src={circleDisc} className="cirDisc" alt="" /> ABM Marketing & Webinar Services</li>
            </ul>
            <p className="pt-3">
            ItVARnews has under its belt, over 10 years sterling track record with 600+ Satisfied Customers more than 3500 campaigns, 7000+ Partner acquisition & 21000 qualified sales leads.
            </p>
            <div className="col-xs-12 col-sm-12 col-md-8">
                <Link class="btn-lead mr-4" to="/download-case-study">
                    Watch Now
                </Link>
            </div>
           </div>
          </div>
        </div>
      </section>
      <Testimonials />
    </>
  );
};

export default DemandProgram;