import React from "react";
import "./Introduction.css";
const Introduction = ({ heading, image }) => {
  return (
    <>
      <section class="blog-banner" style={{ backgroundImage: image }}>
        <div class="container-fluid">
          <div class="col-md-12"></div>
        </div>
      </section>
      <section class="privacy-main">
        <div class="container">
          <h3>{heading}</h3>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xll-12 ">
              <div class="privacy-inner">
                <h4>Introduction</h4>
                <p>
                  Thank you for your interest in LeadXchange (LX) Platform.
                  These Terms and Conditions ("Terms"), together with the chosen
                  plan and/or document incorporating such Terms ("Plan"),
                  establish the relationship and constitute a legally binding
                  agreement between Techplus Media Pte Ltd. ("the Company,"
                  "we," or "us") and your company ("Customer") (collectively
                  referred to as the "parties"). By accessing, using,
                  downloading, or installing the Platform, Customer acknowledges
                  that they have read, will be bound by, and will comply with
                  these Terms and the Privacy Notice (which is incorporated
                  herein by reference), both subject to amendment at the sole
                  discretion of LeadXchange (LX).
                </p>

                <p>
                  1.Certain features available on the Platform may be subject to
                  additional terms and restrictions ("Supplementary Terms").
                  Customer agrees to abide by the applicable Supplementary Terms
                  when adding or using the corresponding Feature. Unless
                  otherwise specified, these Supplementary Terms shall be
                  considered an integral part of these Terms.
                </p>

                <h4>Platform & Purpose </h4>
                <p>
                  Subject to the Terms, LeadXchange grants the Customer a
                  license to access its proprietary online platform, referred to
                  as the "Platform." The Platform allows the Customer to utilize
                  LX service(s) specified in the relevant Plan, granting access
                  to valuable business intelligence and insights on businesses
                  and business professionals (referred to as "Business
                  Contacts"). The Data, compiled from various sources, is
                  retained in LeadXchange's B2B database ("Database") and can be
                  accessed through the Platform on a 'Platform as a Service'
                  basis. This access can be achieved through an API, integrated
                  application, browser extension, downloadable software, or LX's
                  website (www.leadxchange.ai) and all its features,
                  functionalities, data, and content.The Customer intends to use
                  and access the Platform to obtain Data for their internal
                  business operations. The objectives may include but are not
                  limited to B2B sales and marketing to prospective and current
                  customers, recruitment, and business intelligence. or fraud
                  prevention purposes) (“Purpose”). Therefore, the parties agree
                  as follows.
                </p>
                <p>
                  If you are using LeadXchange as a member of an organization or
                  using your organization's email domain, you represent and
                  warrant that you are authorized to enter this agreement on
                  behalf of the business organization. The business organization
                  will be bound by the terms of this agreement and will be
                  considered the Customer. LeadXchange may share your email
                  address and plan information with an authorized agent of the
                  company to administer the account. Please note that the
                  Platform is only intended for individuals who are 18 years old
                  or older. If you or your employer have a separate written
                  agreement with LeadXchange that conflicts with these Terms,
                  the terms of that agreement will take precedence."
                </p>
                <p>
                  Once Customer creates an account, each End User, will
                  automatically join our mailing list. Each End User can choose
                  to remove their email addresses from that mailing list by
                  choosing the “unsubscribe” link at the bottom of any email
                  communication Leadxchange sends.{" "}
                </p>
                <p>
                  2. Any individual who uses the Platform under these Terms must
                  be registered as an End User. In these Terms, "Credit" refers
                  to a limited right to access one (1) Business Contact
                  throughout the Term. The Credit is non-exclusive,
                  non-sublicensable, non-transferable, and revocable. It is
                  valid worldwide and has a limited duration.Please note that
                  any Credits purchased by the Customer and not used by the end
                  of the applicable Term will expire and will not carry over to
                  any renewal term. If you require additional Credits, they can
                  be purchased separately for an extra fee. The content of each
                  Business Contact, including the available data points and the
                  accuracy of any specific data point, is provided as-is. Once a
                  Credit is used, it cannot be revoked, and no replacement
                  Credits or refunds will be provided based on the quantity or
                  quality of a particular record.
                </p>

                <h4>SaaS Platform </h4>
                <p>
                  1. Under these Terms, LeadXchange grants Customer a limited
                  right to use the Platform for the intended Purpose during the
                  subscription period ("Term"). This right is non-exclusive,
                  non-sublicensable, non-transferable, and revocable. It is also
                  limited to the number of End Users and Credits specified,
                  which allows the Customer to receive Data through the Platform
                  on a worldwide scale.
                </p>
                <p>
                  2. Upon termination of these Terms, Customer may only continue
                  using Data that was obtained legally from LeadXchange and
                  stored on their systems during the Term. Certain restrictions
                  outlined in these Terms apply.{" "}
                </p>
                <p>
                  3. The usage of the Platform is subject to specific limits as
                  described in the Supplementary Terms and Acceptable Use Policy
                  incorporated therein.
                </p>
                <h4>Support. </h4>
                <p>
                  1. LeadXchange guarantees that the Platform will be available
                  at least 99% of the time on a weekly basis (“Platform
                  Availability”). Platform Availability excludes planned outages
                  for system maintenance, which are, to the extent possible,
                  performed outside of usual business hours (GMT) or on
                  Saturdays or Sundays (“Planned Outages”) and excludes
                  unavailability caused by factors outside of LeadXchange
                  reasonable control, such as unpredictable and unforeseeable
                  events that could not have been avoided even if reasonable
                  care had been exercised. Aside from emergencies, Planned
                  Outages will generally be performed in a way that minimizes
                  impact to the Platform as a whole and will be resolved within
                  12 hours. In the event that system availability falls below
                  the Platform Availability, Customer may provide written notice
                  to leadxchange, and if availability again falls below the
                  Platform Availability within 3 months of such notice, Customer
                  may terminate these Terms upon written notice to Leadxchange
                  and shall be entitled to a prorated refund of the total
                  prepaid fees for any complete months remaining of the
                  applicable Term, as at the date of such termination.{" "}
                </p>
                <p>
                  2. LeadXchange has created different tools to help users
                  address frequently asked questions and additional technical
                  and general support issues. In addition, LeadXchange tests
                  frequent updates, maintenance, error shooting and additional
                  means in order to improve the Platform. LeadXchange does not
                  undertake to keep operating any of the above, and reserves the
                  right to change, reduce, limit or terminate its maintenance
                  and support efforts.
                </p>
                <p>
                  3. 3. LeadXchange may access, collect, and use any information
                  from or relating to Customer and Customer’s use of the
                  Platform (“Related Information”) for customer and technical
                  support, for regulatory and third-party compliance purposes,
                  to protect and enforce LeadXchange’s rights, to monitor
                  compliance with and investigate potential breaches of these
                  Terms, and to recommend additional products or services.
                  LeadXchange may share this information with its partners or
                  affiliates for the same purposes. Customer grants LeadXchange
                  and LeadXchange affiliates the perpetual right to use Related
                  Information and any feedback provided by Customer for purposes
                  such as to test, develop, improve, and enhance LeadXchange’s
                  products and services, and to create and own derivative works
                  based on Related Information and feedback, so long as neither
                  Customer, any End User nor any other individual is
                  identifiable as the source of such information.
                </p>
                <h4>4. Changes to the Terms. </h4>
                <p>
                  5. We reserve the right to modify, change to, suspend or
                  discontinue, temporarily or permanently the Platform or any
                  Features (the “Changes”) with or without notice with no
                  liability, at any time and for any reason, including without
                  limitation any Changes which may be done automatically for the
                  purpose of improving, enhancing or de-bugging versions of the
                  Platform or other aspects thereof. We will notify Customer of
                  any adverse material changes via the Site or Platform
                  (including without limitation by sending an email notification
                  or by way of on-screen pop-ups) by any other form prior to
                  those Changes becoming effective. Otherwise, any other,
                  non-material change, will be effective upon the “last updated”
                  date stated at the top of these Terms.
                </p>
                <h4>Intellectual Property Rights. </h4>
                <p>
                  1. As between LeadXchange and Customer, all intellectual
                  property rights in the Data, the Database, the Platform, and
                  any part thereof, and any and all derivatives, changes, and
                  improvements thereof lie exclusively with LeadXchange. L
                  LeadXchange owns the layout, user interfaces, appearance,
                  trademarks, and other intellectual property comprising the
                  Platform.
                </p>
                <p>
                  2. If Customer provides LeadXchange with suggestions or
                  feedback, which may concern, but are not limited to, the Data,
                  the Database or Platform and any customizations, features,
                  improvements, modifications, corrections, enhancements,
                  derivatives or extensions thereto (“Customer Suggestions”),
                  such Customer Suggestions shall be deemed the sole property of
                  LeadXchange (except to the extent that such Customer
                  Suggestions include Customer Data, which shall remain
                  Customer’s property), and Customer hereby irrevocably
                  transfers and assigns to LeadXchange all intellectual property
                  or proprietary rights in and to such Customer Suggestions and,
                  to the maximum extent permitted by law, waives any and all
                  derivative rights and copyrights to Customer suggestions and
                  hereby irrevocably waives the right to claim or bring
                  proceedings in connection with such rights.{" "}
                </p>
                <p>
                  Customer shall not use any trade name, trademark, service
                  mark, brand, or logo of LeadXchange, or any link to a
                  LeadXchange website, for any purpose other than in connection
                  herewith, including, without limitation, in any communications
                  using Data from LeadXchange without LeadXchange prior written
                  consent
                </p>
                <h5>Confidentiality.</h5>
                <p>
                  1. Each party (each a “receiving party”) may have access to
                  certain non-public proprietary, confidential information or
                  data of the other party (each a “disclosing party”),
                  regardless of how it is furnished, which a reasonable person
                  or entity should reasonably believe is proprietary,
                  confidential, or competitively sensitive (together, the
                  “Confidential Information“). The fact that the parties entered
                  into these Terms, and the contents of these Terms, shall be
                  deemed Confidential Information.{" "}
                </p>
                <p>
                  2. Confidential Information shall exclude any information that
                  (i) is now or subsequently becomes generally available in the
                  public domain through no fault or breach on the part of
                  receiving party; (ii) the receiving party can demonstrate in
                  its records to have had rightfully in its possession before
                  disclosure of the Confidential Information by the disclosing
                  party; (iii) receiving party rightfully obtains from a third
                  party who has the right to transfer or disclose it, without
                  default or breach of these Terms; or (iv) the receiving party
                  can demonstrate in its records to have independently
                  developed, without breach of these Terms or any use of or
                  reference to the Confidential Information.{" "}
                </p>
                <p>
                  3. The receiving party agrees: 1. not to disclose the
                  disclosing party’s Confidential Information to any third
                  parties other than to its directors, officers, employees,
                  advisors, or consultants (collectively, the “Representatives”)
                  on a strict “need to know” basis provided that such
                  Representatives are bound by written agreements to comply with
                  confidentiality obligations as protective as those herein; 2.
                  inform the disclosing party immediately on becoming aware or
                  suspecting that an unauthorized party has become aware of or
                  gained access to the Confidential Information; 3. not to use
                  or reproduce, or knowingly allow anyone else to reproduce,
                  directly or indirectly, any of the disclosing party’s
                  Confidential Information for any purposes except to carry out
                  its rights and responsibilities under these Terms, unless
                  otherwise provided for in these Terms; and 4. to keep the
                  disclosing party’s Confidential Information confidential using
                  at least the same degree of care it uses to protect its own
                  confidential information, which shall in any event not be less
                  than a reasonable degree of care.
                </p>
                <p>
                  4. Notwithstanding the foregoing, if the receiving party is
                  required by any applicable law, rule, or regulation, to
                  disclose the disclosing party’s Confidential Information, then
                  before such disclosure, the receiving party will give written
                  notice (to the extent permissible by applicable law) to the
                  disclosing party so that it may seek appropriate relief and
                  will disclose Confidential Information to the minimum extent
                  required.
                </p>
                <p>
                  5. The Customer shall not ridicule, defame, mock, disparage,
                  stalk, intimidate, threaten, harass, harm, advocate, incite
                  harassment, or abuse another person, group, LeadXchange
                  employees, including LeadXchange’s customer service
                  representatives, hatefully, racially, religiously, ethnically
                  or in any other manner.
                </p>
                <p>
                  6. Customer acknowledges that the Data obtained using Credits
                  are for its own use only and that the disclosure to a third
                  party of Business Contacts, permitting a third party to access
                  any Business Contact records through use of Login Credentials
                  or the use of the Business Contacts for the benefit of or on
                  behalf of any third party (“Covered Breach”), will cause
                  damage to LeadXchange in an amount that is difficult to
                  quantify. In order to avoid the time and expense of
                  quantifying the direct damages caused by a Covered Breach, if
                  Customer, negligently or intentionally, commits a Covered
                  Breach, then LeadXchange shall be entitled to liquidated
                  damages from Customer in the amount equal to $2.00 per
                  Business Contact record that is so disclosed, used, or made
                  available per third party recipient or beneficiary, as
                  applicable. For the avoidance of doubt, such liquidated
                  damages are cumulative with any other damages that may be
                  caused by such Covered Breach.
                </p>
                <h4>Warranties. </h4>
                <p>
                  1. Each party represents and warrants that (i) these Terms,
                  constitute a legal, valid and binding obligation, enforceable
                  against it in accordance with these Terms; and (ii) it is
                  validly existing and in good standing, and is qualified to do
                  business; (iii) the performance of these Terms, the purchase
                  of the Plan, and the use of the Platform have been properly
                  authorized.
                </p>
                <p>
                  2. Customer represents and warrants to LeadXchange that: 1. it
                  is not a data broker and is not entering into these Terms in
                  order to resell the Data to third parties unless data subject
                  actively allowed it; 2. it shall not engage in any conduct
                  that brings or is likely to bring the reputation of
                  LeadXchange into disrepute; 3. it shall ensure that all
                  information to be provided by it to LeadXchange in connection
                  with these Terms and the performance of Customer’s obligations
                  hereunder is and shall remain true and correct in all
                  respects; 4. its execution of these Terms and its use of
                  LeadXchange Platform and/or Data will not violate any
                  applicable law, rule or regulation.
                </p>
                <h4> Disclaimer of Warranties.</h4>
                <p>
                  LEADXCHANGE PROVIDES ACCESS TO THE PLATFORM, AND DATA TO
                  CUSTOMER ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT
                  WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, other than
                  the warranties explicitly specified herein and then only to
                  the extent so specified, INCLUDING THE WARRANTY OF TITLE,
                  MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A
                  PARTICULAR PURPOSE OR ACCURACY. CUSTOMER DOES NOT HAVE THE
                  RIGHT TO MAKE OR PASS ON ANY REPRESENTATION OR WARRANTY ON
                  BEHALF OF THE OTHER PARTY TO ANY THIRD PARTY.
                </p>
                <h4>Limitation of Liability. </h4>
                <p>
                  IN NO EVENT WILL LEADXCHANGE BE LIABLE FOR ANY PUNITIVE,
                  MULTIPLE LOST PROFITS, LOST BUSINESS, LOSS OF USE, LOSS OF
                  DATA, LOST OR CORRUPTED DATA DAMAGES, COST OF PROCUREMENT OF
                  SUBSTITUTE GOODS OR SERVICES, OR FOR SPECIAL, INCIDENTAL,
                  INDIRECT, OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, AND ON ANY
                  THEORY OF LIABILITY, WHETHER FOR BREACH OF THESE TERMS, THE
                  DPA, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), OR
                  OTHERWISE, WHETHER OR NOT LEADXCHANGE HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES.{" "}
                </p>
                <h4>Fees; Tax. </h4>
                <p>
                  1. Fees 1. Certain Plans and Features may be subject to
                  payment of particular fees (“Fee(s)”), as determined by
                  LeadXchange in its sole discretion (“Paid Plan”). LeadXchange
                  will provide notice of such Fees then in effect in relation to
                  such Plan on sign-up therefore. All Fees are due and payable
                  in advance and shall be paid by credit card in accordance with
                  LeadXchange’s provided instructions. All Fees shall be deemed
                  to be in U.S. Dollars.
                </p>
                <p>
                  2. LeadXchange reserves the right to change its Fees at any
                  time, upon notice to Customer if such change may affect
                  Customer’s existing Plan. If Customer received a discount or
                  other promotional offer, LeadXchange shall have the right to
                  automatically and without notice renew Customer’s Plan at the
                  full, and then-applicable Fee.{" "}
                </p>
                <p>
                  3. For the avoidance of doubt, all payments under these Terms
                  are non-refundable.
                </p>
                <p>
                  2. Late Payment 1. If Customer fails to pay any Fees due to
                  LeadXchange by their due date (according to the chosen Plan)
                  LeadXchange has the right, without prejudice to any other
                  rights that it has in law, to implement, at its sole
                  discretion and on notice to Customer, a Suspension or
                  Limitation process as set below. Any amounts payable to
                  LeadXchange by Customer that remain unpaid after the due date
                  shall be subject to a late charge equal to 1.5% of the invoice
                  amount per month from the due date until such amount is paid.
                  Customer shall pay all collection fees, including legal
                  expenses, with respect to the collection of late payments.
                </p>
                <p>
                  3. Chargebacks 1. LeadXchange reserves its right to dispute
                  any chargeback received from Customer, including by providing
                  the relevant credit card company or financial institution with
                  any information and documentation proving that Customer is
                  responsible for authorizing such chargeback and continued to
                  make use of and access the Platform thereafter. Customer’s use
                  of the Platform will not resume until Customer re-subscribes
                  for a new Plan, and pay any applicable Fees in full, including
                  any fees and expenses incurred by LeadXchange and/or any Third
                  Party Services for each chargeback , if applicable (including
                  Fees for the Platform provided prior to the chargeback,
                  handling and processing charges and fees incurred by the
                  payment processor).
                </p>
                <h4>Term; Termination. </h4>
                <p>
                  1. Term 1. These Terms commence on the date of receipt by
                  LeadXchange of the full Fee by Customer, unless otherwise
                  agreed in writing between the parties, and shall continue in
                  effect for the subscription period applicable to the specific
                  purchased Plan (the “Initial Term”) or any Renewal Term
                  thereof (collectively, the “Term”).
                </p>
                <p>
                  2. Auto-renewal 1. In order to ensure that Customer does not
                  experience any interruption or loss of services, at the end of
                  the Initial Term (or each Renewal Term, as applicable), the
                  Plan will automatically renew (“Renewal Date”) by default for
                  a renewal period equal in time to the original subscription
                  period (excluding extended periods) (“Renewal Term”) at the
                  then-current pricing structure for the current Plan (subject
                  to applicable Taxes changes and excluding any discount or
                  other promotional offer provided for the Initial Term)
                  (“Renewing Paid Plan”).
                </p>
                <p>
                  2. Accordingly, on the Renewal Date, LeadXchange will
                  automatically charge Customer the applicable Fees for the
                  Renewing Paid Plan, using the same means of payment (“Renewal
                  Charge”). If the Renewal Charge is unsuccessful, LeadXchange
                  may, in its sole discretion (but shall not be obligated to),
                  retry to collect such Renewal Charge for a period of up to two
                  (2) weeks, during which time, Customer’s LeadXchange account
                  might be suspended. In the event of failure to collect the
                  Renewal Charge, LeadXchange may, in its sole discretion,
                  suspend or cancel Customer’s Plan, without further notice.
                </p>
                <p>
                  3. Termination 1. Customer may request to terminate these
                  Terms (and thereby the Plan) at any time, in accordance with
                  the instructions available on the Platform. The effective date
                  for cancellation of the Plan shall be at the end of the
                  current Term. 2. Notwithstanding anything to the contrary in
                  the foregoing, with respect to subscriptions to Renewing Paid
                  Plans, such subscriptions will be discontinued only upon the
                  expiration of the respective period for which payment have
                  already made payment. Please note that as the cancellation
                  process may take a few days, in order to avoid the next
                  automatic renewal and respective charge the cancellation
                  request should be made at least fourteen (14) days prior to
                  the expiration of the then-current service period. 3.
                  LeadXchange may terminate these Terms with immediate effect by
                  giving written notice to Customer if: (i) the Customer
                  materially breaches these Terms and fails to cure the breach
                  within seven (7) days after being given written notice
                  thereof; or (ii) the Customer is declared bankrupt or
                  insolvent, makes a general assignment for the benefit of its
                  creditors, a trustee or receiver is appointed for such party
                  or any petition by or on behalf of such party is filed under
                  any bankruptcy or similar laws; or (iii) the Plan is deemed to
                  be a deprecated Plan by LeadXchange, in which case LeadXchange
                  shall provide 30 days prior written notice.
                </p>
                <h4> Arbitration. </h4>
                <p>
                  1. Both parties agree to resolve any dispute (other than
                  Litigation Claims (as defined below)) through final and
                  binding arbitration as detailed herein. Before filing a claim,
                  both parties agree to try to resolve the dispute informally
                  and undertake reasonable efforts to contact each other to
                  resolve any claim before taking any formal action. If a
                  dispute is not resolved within 15 days after the first
                  notification of the dispute is sent, either party may initiate
                  an arbitration proceeding as described below. The parties
                  hereby agree to pursue an arbitration proceeding to resolve
                  the dispute quickly and efficiently and to reduce the costs
                  imposed on the parties. 2. If Customer’s jurisdiction of
                  incorporation is anywhere other than India the parties agree
                  that any dispute arising out of or in connection with these
                  Terms, including any question regarding its existence,
                  validity, or termination, shall be referred to and finally
                  resolved by arbitration under the LCIA Rules, which Rules are
                  deemed to be incorporated by reference into this Section. The
                  number of arbitrators shall be one. The seat or legal place of
                  arbitration shall be New Delhi India . The language to be used
                  in the arbitral proceedings shall be English. The governing
                  law of the contract shall be the substantive law of India.
                </p>
                <h4>Contact Us</h4>
                <p>
                  If you have any additional questions about our privacy
                  practices, please feel free to contact us
                  privacy@leadxchange.ai or legal@leadxchange.ai. In addition,
                  if you have questions or concerns regarding our Terms, you
                  should contact us by email at support@leadxchange.ai or at:
                  Techplus Media Pte Ltd Singapore
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Introduction;
