import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'

const MCQsForm = ({ funcNav, funcFooter }) => {
  const navigate = useNavigate()
  const [selectedMCQ, setSelectedMCQ] = useState({})
  const [formSubmitted, setFormSubmitted] = useState(false)

  useEffect(() => {
    // Invoke function props to update navigation and footer
    funcNav(true)
    funcFooter(true)

    // Check if user data exists in sessionStorage
    const userDataString = sessionStorage.getItem('user')
    if (!userDataString) {
      // Navigate user to sign-in page if no user data is found
      navigate('/signin')
    }
  }, [navigate, funcNav, funcFooter])

  const handleSelectMCQ = (questionIndex, selectedOptionText) => {
    setSelectedMCQ(prevSelectedMCQ => ({
      ...prevSelectedMCQ,
      [questionIndex]: selectedOptionText
    }))
  }

  const handleSaveMCQ = async () => {
    // Check if any question has not been answered
    const unansweredQuestions = questions.filter(
      (q, index) => !selectedMCQ[index]
    )
    if (unansweredQuestions.length > 0) {
      toast.info('Please answer all questions before submitting.')

      return // Exit the function early if there are unanswered questions
    }

    const selectedOptionTexts = questions.map((q, index) =>
      selectedMCQ[index] !== undefined ? selectedMCQ[index] : null
    )

    console.log(selectedOptionTexts) // Log the selected option texts array
    // Retrieve user data from local storage
    const userDataString = sessionStorage.getItem('user')
    if (!userDataString) {
      throw new Error('User data not found in Local Storage')
    }

    const userData = JSON.parse(userDataString)
    const userId = userData._id
    try {
      const response = await axios.post(
        `/campaignSetup/addMCQToCampaignSetup`,
        { userId, selectedMCQs: selectedOptionTexts }
      )

      console.log('MCQ added to campaign setup:', response.data)

      setSelectedMCQ({}) // Clear selected options after submission
      setFormSubmitted(true) // Set formSubmitted to true after submission

      navigate('/profilesummary') // Navigate to desired route after submission
    } catch (error) {
      console.error('Error adding MCQ to campaign setup:', error)
      // Handle error logic here if needed
    }
  }

  const questions = [
    {
      question:
        'What is the primary objective of demand generation programs in the context of lead generation?',
      options: [
        'To create general brand awareness',
        'To directly generate sales pipeline',
        'To capture and nurture leads until they are sales-ready',
        'To increase traffic to the company website'
      ]
    },
    {
      question:
        'Which of the following tactics is most effective for capturing quality leads in a demand generation program?',
      options: [
        'Target Display and Newsletter',
        'Content Syndication',
        'Broad social media campaigns',
        'One-on-One meeting'
      ]
    },
    {
      question:
        'Which KPI (Key Performance Indicator) is crucial for assessing the effectiveness of lead generation in demand generation programs?',
      options: [
        'Number of page views',
        'Social media likes and shares',
        'Lead conversion rate',
        'Cost per lead'
      ]
    },
    {
      question:
        'In lead generation, why is it important to align content strategy with the buyer’s journey?',
      options: [
        'To ensure content is relevant and measure the buyer interest level',
        'To provide the right content at the right stage to move leads through the sales funnel',
        'To focus content solely on top-funnel awareness',
        'To use the same content across all stages without customization'
      ]
    },
    {
      question:
        'Which strategy ensures effective follow-up with leads generated from demand generation activities?',
      options: [
        'Ignoring leads who do not respond initially',
        'Sending the same follow-up message to all leads',
        'Using detailed analytics to tailor follow-up communications based on lead responses',
        'Waiting for leads to initiate follow-up'
      ]
    },
    {
      question:
        'What according to you is the advantage of integrating email marketing with other digital marketing tools in a demand generation strategy?',
      options: [
        'It isolates email from other marketing channels for clarity',
        'It enhances the ability to communicate consistently across multiple touchpoints',
        'It focuses only on sending frequent email blasts',
        'It reduces the need for analyzing email performance'
      ]
    }
  ]

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: '1rem',
      maxWidth: '90%',
      margin: '0 auto'
    },
    leftSide: {
      width: '45%',
      paddingTop: '10rem',
      paddingLeft: '60px'
    },
    rightSide: {
      width: '45%',
      padding: '24px',
      borderRadius: '16px',
      backgroundColor: '#EEF8F0',
      overflowY: 'auto', // Add scrollbar for y-axis overflow
      maxHeight: 'calc(100vh - 100px)', // Limit height to prevent page overflow
      // Customize scrollbar appearance
      '&::-webkit-scrollbar': {
        width: '8px' // width of the scrollbar
      },
      '&::-webkit-scrollbar-track': {
        background: '#EEF8F0' // background of the track
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#55B56D', // color of the thumb
        borderRadius: '10px' // rounded corners of the thumb
      },
      scrollbarWidth: 'thin', // Firefox
      scrollbarColor: '#55B56D #EEF8F0', // color of the thumb and track in Firefox
      '-ms-overflow-style': 'none' // IE and Edge
    },
    heading: {
      color: '#007bff',
      marginBottom: '20px',
      textAlign: 'center'
    },
    questionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px', // Spacing between items
      width: '100%', // Full width
      marginBottom: '20px'
    },
    questionContainer: {
      backgroundColor: '#ffffff',
      padding: '20px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    question: {
      color: '#333333',
      fontWeight: '500',
      marginBottom: '10px',
      fontSize: '16px'
    },
    option: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      padding: '10px'
    },
    label: {
      marginLeft: '10px',
      color: '#555555',
      fontWeight: '400',

      fontSize: '14px'
    },

    highlightedOption: {
      border: '1px solid green',
      borderRadius: '10px',
      backgroundColor: '#e6f9e6'
    },
    greenCheckbox: {
      cursor: 'pointer',
      accentColor: 'green',
      transform: 'scale(1.5)'
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',
        padding: '2rem 0'
      }}
    >
      <div className='mcqs-form-container' style={styles.container}>
        <div style={styles.leftSide}>
          <h2
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            We need some of
          </h2>
          <h2>your Information</h2>
          <div className=''>
            <div>
              This data is needed so that we can easily provide solutions
            </div>
            <div>according to your needs</div>
          </div>

          <div
            style={{
              display: 'flex',
              gap: '10px',
              fontWeight: '600',
              marginTop: '2rem'
            }}
          >
            <button
              onClick={() => navigate(-1)}
              style={{
                padding: '10px 20px',
                borderRadius: '10px',
                border: 'none',
                background: '#E4E4E4'
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleSaveMCQ}
              disabled={formSubmitted}
              style={{
                background: '#55B56D',
                padding: '10px 20px',
                borderRadius: '10px',
                color: 'white',
                border: 'none'
              }}
            >
              Continue
            </button>
          </div>
        </div>

        <div className='questions-container' style={styles.rightSide}>
          {questions.map((q, index) => (
            <div
              key={index}
              className='mcq-question'
              style={styles.questionContainer}
            >
              <p style={styles.question}>{q.question}</p>
              <div className='options'>
                {q.options.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    className='option'
                    style={{
                      ...styles.option,
                      ...(selectedMCQ[index] === option &&
                        styles.highlightedOption)
                    }}
                  >
                    <input
                      type='radio'
                      name={`question${index}`}
                      value={option}
                      style={styles.greenCheckbox}
                      onChange={() => handleSelectMCQ(index, option)}
                      checked={selectedMCQ[index] === option}
                      disabled={formSubmitted}
                      required
                    />
                    <label style={styles.label}>{option}</label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MCQsForm
