import ActiveLead from "./ActiveLead";
import IntentData from "./IntentData";
import Investment from "./Investment";
import Jobs from "./Jobs";
import VirtualCXO from "./VirtualCXO";

const CategoryBody = [
  { heading: "Active Leads", content: <ActiveLead /> },
  { heading: "Intent Data", content: <IntentData /> },
  { heading: "Virtual CXOs", content: <VirtualCXO /> },
  { heading: "Investment", content: <Investment /> },
  { heading: "Jobs", content: <Jobs /> },
];

export default CategoryBody;
