import React, { useState, useEffect } from "react";
import axios from "axios";

const UserDetails = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Extract user ID from the URL
    const userId = window.location.pathname.split("/").pop(); // Extracts '662b83b83bafd765b4974c03'

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/users/userdetails/${userId}`); // Add localhost 5000 prefix
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const IMAGE_BASE_URL = `${process.env.REACT_APP_API_URL}`;
  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      {userData ? (
        <div>
          <h2>User Details</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#333" }}>Email: {userData.email ?? "?"}</p>
            <p style={{ color: "#333" }}>
              Full Name: {userData.full_name ?? "?"}
            </p>
            <p style={{ color: "#333" }}>
              Designation: {userData.designation ?? "?"}
            </p>
            <p style={{ color: "#333" }}>
              Company Name: {userData.company_name ?? "?"}
            </p>
            <p style={{ color: "#333" }}>Mobile: {userData.mobile ?? "?"}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#333" }}>City: {userData.city ?? "?"}</p>
            <p style={{ color: "#333" }}>State: {userData.state ?? "?"}</p>
            <p style={{ color: "#333" }}>Country: {userData.country ?? "?"}</p>
            <p style={{ color: "#333" }}>About: {userData.about ?? "?"}</p>
            <p style={{ color: "#333" }}>Skills: {userData.skills ?? "?"}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#333" }}>
              Department: {userData.department ?? "?"}
            </p>
            <p style={{ color: "#333" }}>Status: {userData.status ?? "?"}</p>
            <p style={{ color: "#333" }}>
              Created Date:{" "}
              {userData.created_date
                ? new Date(userData.created_date).toLocaleString()
                : "?"}
            </p>
            <p style={{ color: "#333" }}>
              Updated Date:{" "}
              {userData.updated_date
                ? new Date(userData.updated_date).toLocaleString()
                : "?"}
            </p>
            <p style={{ color: "#333" }}>
              Is Profile Set: {userData.isProfileSet ? "Yes" : "No"}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#333" }}>
              LinkedIn Profile: {userData.linkedin_profile ?? "?"}
            </p>
            <p style={{ color: "#333" }}>
              Is Objective Set: {userData.isObjectiveSet ? "Yes" : "No"}
            </p>
            <p style={{ color: "#333" }}>
              Is Industries Roles Set:{" "}
              {userData.isIndustriesRolesSet ? "Yes" : "No"}
            </p>
            <p style={{ color: "#333" }}>
              Apply For Campaigns: {userData.appyForcampaigs ? "Yes" : "No"}
            </p>
          </div>

          {/* Campaign Setup */}
          <h3 style={{ color: "#333" }}>Campaign Setup</h3>
          {userData.CampaignSetup.map((campaign, index) => (
            <div
              key={index}
              style={{
                backgroundColor: "#fff",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                marginBottom: "20px",
              }}
            >
              <h4>Campaign {index + 1}</h4>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                {" "}
                <p>Job Title: {campaign.jobTitle.title ?? "?"}</p>
                <p>Objective: {campaign.objective ?? "?"}</p>
                <p>Location: {campaign.location ?? "?"}</p>
                <p>Campaign Timeline: {campaign.campaignTimeline ?? "?"}</p>
                <p>Report Frequency: {campaign.reportFrequency ?? "?"}</p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                {" "}
                <p>ABM: {campaign.abm ? "Yes" : "No"}</p>
                <p>TAL: {campaign.tal ? "Yes" : "No"}</p>
                <p>
                  Organization Size:{" "}
                  {campaign.organizationSize.join(", ") ?? "?"}
                </p>{" "}
                {/* Add Organization Size */}
                <p>Funnels: {campaign.funnel.join(", ") ?? "?"}</p>{" "}
                {/* Add Funnel */}
                <p>
                  Additional Filters:{" "}
                  {campaign.additionalFilter.enabled ? "Enabled" : "Disabled"}
                </p>{" "}
                {/* Add Additional Filters */}
              </div>

              {/* Display images if available */}
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                {campaign.purchaseOrderFile && (
                  <div>
                    <h5>Purchase Order Image</h5>
                    <img
                      src={`${IMAGE_BASE_URL}/${campaign.purchaseOrderFile}`}
                      alt="Purchase Order"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                  </div>
                )}
                {campaign.talListFile && (
                  <div>
                    <h5>TAL List Image</h5>
                    <img
                      src={`${IMAGE_BASE_URL}/${campaign.talListFile}`}
                      alt="TAL List"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                  </div>
                )}
                {campaign.assetsFile && (
                  <div>
                    <h5>Assets Image</h5>
                    <img
                      src={`${IMAGE_BASE_URL}/${campaign.assetsFile}`}
                      alt="Assets"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                  </div>
                )}
                {campaign.purchaseOrderFile && (
                  <div>
                    <h5>Assets Image</h5>
                    <img
                      src={`${IMAGE_BASE_URL}/${campaign.purchaseOrderFile}`}
                      alt="Assets"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                  </div>
                )}
              </div>

              {/* Render other images as needed */}
            </div>
          ))}
          {/* MCQs */}
          <h3 style={{ color: "#333" }}>MCQs</h3>
          {userData.mcqs.map((mcq, index) => (
            <div
              key={index}
              style={{
                backgroundColor: "#fff",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                marginBottom: "10px",
              }}
            >
              <p style={{ color: "#333" }}>Selected MCQs:</p>
              <ul>
                {mcq.selectedMCQs.map((answer, idx) => (
                  <li key={idx} style={{ color: "#333" }}>
                    {answer}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : (
        <p>Loading user data...</p>
      )}
    </div>
  );
};

export default UserDetails;
