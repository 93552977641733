import React from "react";
import startup from "../../assets/images/startup.png";
import investor from "../../assets/images/Investors.png";
import { Link } from "react-router-dom";
import profile from "../../assets/images/jop-profile.png";

const Investment = () => {
  return (
    <>
      <div className="active-main d-flex">
        <div className="active-main-left">
          <div className="profile-jobs">
            <img
              src={profile}
              className="img-fluid"
              alt="images/jop-profile.png"
            />
            <h5>Founder & CEO </h5>
            <h6>Company Name</h6>
          </div>
        </div>
        <div className="active-main-right">
          <h4>Investment</h4>
          <p>
            We facilitate the connection between founders and investors,
            fostering strong and trustworthy relationships.
          </p>
          <div className="target-box">
            <ul className="d-flex">
              <li>
                <img
                  src={startup}
                  className="img-fluid"
                  alt="images/startup.png"
                />
                <h6>
                  <span>40000+ </span>
                  <br />
                  Startup registered{" "}
                </h6>
              </li>
              <li>
                <img
                  src={investor}
                  className="img-fluid"
                  alt="images/Investors.png"
                />
                <h6>
                  <span>19500+ </span>
                  <br />
                  Investors on Platform{" "}
                </h6>
              </li>
            </ul>
          </div>
          <div className="comanies-main d-flex">
            <div className="comanies-main-right">
              <Link to="/user_dashboard/investments" className="btn-lead">
                Apply for Investment
              </Link>
              <Link to="/user_dashboard/investments" className="btn-lead">
                Become an Investor
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investment;
